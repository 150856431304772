import React, { useState} from 'react';
import {signInWithEmailAndPassword} from 'firebase/auth';
import { useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {errorsTypes} from '../../utils/errorsTypes';
import {setUser} from '../../store/app.slice';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {auth} from '../../firebase';
import {AppLoader} from '../../components/AppLoader';
import {value} from '../../fields/Input';
import {AuthLayout} from '../../layouts';
import {userDefault} from '../../models/User';
import styles from './Login.module.scss';
import {Form} from './components';
import {loginUser} from './api';

export const Login = () => {
    const {loginHistoryUrl} = useAppSelector(state => state.front.app);
    const {i18n, t} = useTranslation();
    const isRu = i18n.language === 'ru';
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location: any = useLocation();

    const [loading, setLoading] = useState<boolean>(false);
    const [userNotFound, setUserNotFound] = useState<string | null>(null);

    // useEffect(() => {
    //     const email = 'admin@mail.ru';
    //     const password = '34Q&j8&d9!Jj';
    //     submit(email, password);
    //     // eslint-disable-next-line
    // }, []);

    const submit = async (email: value, password: value) => {

        setLoading(true);
        try {
            const {user: FUser} = await signInWithEmailAndPassword(auth, email.toString(), password.toString());

            const user = await loginUser(FUser);

            if(FUser?.photoURL === 'disable') {
                setLoading(false);
                alert(t('app.verify-post'));
                return;
            }
            user
                ? dispatch(setUser(user))
                : dispatch(setUser({
                    ...userDefault,
                    email: FUser.email,
                    displayName: FUser.displayName,
                    uid: FUser.uid
                }));


            user?.role === 'ADMIN'
                ? navigate('/panel')
                : navigate(loginHistoryUrl !== '' ? loginHistoryUrl : location.state?.redirect ?? -1);

        } catch (error: any) {
            const errorCode = error?.code;
            const message = errorsTypes[errorCode];

            setUserNotFound(message);
            setLoading(false);
        }
    };

    return (
        <AuthLayout>
            <div className={styles.login}>
                <AppLoader loading={loading}/>
                {userNotFound ? <p style={{color: 'red'}}>{isRu ? 'Пользователь не найден' : 'User is not found'}</p> : null}
                <Form handleSubmit={submit}/>

            </div>
        </AuthLayout>
    );
};

