import React from 'react';
import {NavLink, useNavigate, useLocation} from 'react-router-dom';
import homeIcon from '../../assets/icons/home.svg';
import returnIcon from '../../assets/icons/return.svg';
import {ITemplateNaneProps} from './types';
import styles from './BreadCrumbs.module.scss';

export const BreadCrumbs = ({isBack}:ITemplateNaneProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleGoBack = () => {
        navigate(-1);
    };

    const isDotwPage = location.pathname === '/content/day-of-the-week';

    return (
        <div className={`${styles.breadCrumbs} ${isDotwPage ? styles['breadCrumbs-dotw'] : ''}`}>
            {isBack ? <button onClick={handleGoBack}>
                <img src={returnIcon} className={styles.breadCrumbs__icon} alt={'home'} />
            </button> : null}
            <NavLink to={'/'}>
                <img src={homeIcon} className={styles.breadCrumbs__icon} alt={'home'} />
            </NavLink>
        </div>
    );
};

