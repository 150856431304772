import {doc, getDoc} from 'firebase/firestore';
import {firestore} from '../../../firebase';
import {ILessonsIn} from '../../../models/lesson';
import {defaultStage, IStage} from '../../../models/Stage';

export const getLessonInStage = async (stageUid: string) => {

    let stage: IStage = defaultStage;
    const washingtonRef = doc(firestore, `stages/${stageUid}`);
    const docSnap = await getDoc(washingtonRef);
    if (docSnap.exists()) {
        const data = docSnap.data() as IStage;
        stage = {...data, uid: docSnap.id};
    }

    const lessons: ILessonsIn[] = [];
    for (let i = 0; i < stage.lessonsIn.length; i++) {
        const washingtonRef = doc(firestore, `lessons/${stage.lessonsIn[i]}`);
        const docSnap = await getDoc(washingtonRef);
        if (docSnap.exists()) {
            lessons.push({...docSnap.data(), uid: docSnap.id} as ILessonsIn);
        }
    }

    return {lessons, stage};
};
