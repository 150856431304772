import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import {BrowserRouter} from 'react-router-dom';
import {store} from './store';
import App from './App';
import './firebase';
import './index.scss';
import './i18next';



const persist = persistStore(store);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <Suspense fallback={<></>}>
        <Provider store={store}>
            <PersistGate loading={<></>} persistor={persist}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </Suspense>
)
;
