import {onAuthStateChanged, updatePassword, updateProfile, User} from 'firebase/auth';
import React, {useEffect, useState} from 'react';
import { useNavigate} from 'react-router-dom';
import {InputAdornment} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {auth} from '../../firebase';
import {useAppDispatch} from '../../hooks';
import {setUser} from '../../store/app.slice';
import {IUser, userDefault} from '../../models/User';
import {AuthLayout} from '../../layouts';
import {CustomButton, Input} from '../../fields';
import keyIcon from '../../assets/icons/key.svg';
import {ButtonVariables} from '../../fields/CustomButton';
import {value} from '../../fields/Input';
import styles from './Registration.module.scss';

export const EmailVerify = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [password, setPassword] = useState<value>('');
    const [repeatPassword, setRepeatPassword] = useState<value>('');
    const [passwordSwitchType, setSwitchType] = useState<boolean>(true);
    const [isDisabled, setDisabled] = useState<boolean>(false);
    const [pageType, setPageType] = useState<'resetPassword' | 'emailVerify'>('emailVerify');
    const [currentUser, setCurrentUser] = useState<User | null>(null);

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            setCurrentUser(user);
            const createUser: IUser = {
                ...userDefault,
                email: user?.email as string,
                displayName: user?.displayName as string,
                account: 'enable',
                uid: user?.uid as string,
            };
            if (user) {
                dispatch(setUser(createUser));

                const service = localStorage.getItem('service');
                if (service) {
                    const storage = JSON.parse(service);
                    if (storage.state === 'resetPassword') {
                        setPageType('resetPassword');
                    } else {
                        setPageType('emailVerify');
                        if (auth.currentUser) {
                            await updateProfile(auth.currentUser, {
                                displayName: user.displayName,
                                photoURL: 'enable',
                            });
                        }
                        localStorage.removeItem('service');
                        navigate('/');
                    }
                }
            } else {
                alert('Произошла ошибка');
            }
        });


    }, []);

    const handleSubmit = async (password: value) => {
        if(currentUser !== null) {
            await updatePassword(currentUser, password as string);
            localStorage.removeItem('service');
            navigate('/');
        }
    };

    if(pageType === 'emailVerify') {
        return <></>;
    }

    return (
        <AuthLayout>
            <div className={styles.register}>
                <div className={styles.form}>
                    <p className={styles.form__title}>
                        <span>{t('auth.title')}</span> <br/>{t('auth.subtitle')}</p>
                    <p className={styles.form__text}>{t('auth.text')}</p>
                    <div className={styles.form__box}>
                        <Input
                            type={passwordSwitchType ? 'password' : 'text'}
                            placeholder={`${t('auth.password')}:`}
                            defaultValue={password}
                            handleChangeValue={value => setPassword(value)}
                            onBlur={() => {
                                if(password !== repeatPassword) {
                                    setDisabled(true);
                                } else {
                                    setDisabled(false);
                                }
                            }}
                            icon={
                                <InputAdornment
                                    position="start"
                                    sx={{cursor : 'pointer'}}
                                    onClick={() => setSwitchType(!passwordSwitchType)}>
                                    <img style={{width: '20px'}} src={keyIcon} alt={'key'} />
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className={styles.form__box}>
                        <Input
                            type={passwordSwitchType ? 'password' : 'text'}
                            placeholder={`${t('auth.repeat')}:`}
                            defaultValue={repeatPassword}
                            handleChangeValue={value => setRepeatPassword(value)}
                            error={isDisabled}
                            onBlur={() => {
                                if(password !== repeatPassword) {
                                    setDisabled(true);
                                } else {
                                    setDisabled(false);
                                }
                            }}
                            icon={
                                <InputAdornment
                                    position="start"
                                    sx={{cursor : 'pointer'}}
                                    onClick={() => setSwitchType(!passwordSwitchType)}>
                                    <img style={{width: '20px'}} src={keyIcon} alt={'key'} />
                                </InputAdornment>
                            }
                        />
                    </div>

                    <div className={styles.form__box}>
                        <CustomButton
                            disabled={isDisabled}
                            handleClick={() => handleSubmit(password)}
                            variant={ButtonVariables.contained}
                            sx={{
                                padding: '7px 25px',
                                margin: 'auto',
                                display: 'inline-block'
                            }}
                        >
                            {t('auth.registrationConfirm')}
                        </CustomButton>
                    </div>

                </div>
            </div>
        </AuthLayout>
    );
};
