import {locale, typesCourse} from './Course';

export interface ILessonsIn {
    uid?: string | null;
    name: locale;
    description: {
        ru: string,
        en: string
    };
    belonging: string;
    serialNumber: number;
    source: locale;
    homework: {
        ru: string,
        en: string
    },
    create_as?: number;
    type: typesCourse.free | typesCourse.temporary | typesCourse.constant;
    bannerCourse: boolean;
}

export const lessonIn: ILessonsIn = {
    uid: null,
    name: {ru: '', en: ''},
    belonging: '',
    serialNumber: 0,
    description: {ru: '', en: ''},
    homework: {ru: '', en: ''},
    source: {ru: '', en: ''},
    create_as: Date.now(),
    type: typesCourse.free,
    bannerCourse: false,
};
