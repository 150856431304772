import React, {useCallback, useState} from 'react';
import {Button} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {PanelLayout} from '../../layouts';
import {AppLoader} from '../../components/AppLoader';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {IPost} from '../../models/Post';
import {ConfirmationModal} from '../../components/ConfirmationModal';
import styles from './Post.module.scss';
import {Form, List} from './components';
import {clearCurrentPost, setPost, setPosts} from './Post.slice';
import {deletePost} from './api';

export const Post = () => {
    const dispatch = useAppDispatch();
    const {posts} = useAppSelector(state => state.admin.posts);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [currentUid, setCurrentUid] = useState<string>('');


    const handleUpdate = useCallback(async (uid: string) => {
        setLoading(true);
        const current = posts.find(i => i.uid === uid) as IPost;
        dispatch(setPost(current));
        setShowForm(true);
        setLoading(false);
    }, [dispatch, posts]);

    const handleDelete = useCallback(async (uid: string) => {
        setLoading(true);
        const posts = await deletePost(uid);
        dispatch(setPosts(posts));
        setLoading(false);
    }, [dispatch]);

    const handleBack = useCallback(() => {
        dispatch(clearCurrentPost());
        setShowForm(false);
    }, [dispatch]);

    const handleCreated = useCallback(async () => {
        handleBack();
        setLoading(false);
    }, [dispatch, handleBack]);
    return (
        <PanelLayout>
            <div className={styles.post}>
                <AppLoader loading={loading}/>
                <div className={styles.post__header}>
                    <h1 className={styles.post__title}>Запись</h1>
                    {!showForm && <Button
                        variant={'contained'}
                        className={styles.course__createBtn}
                        onClick={() => setShowForm(true)}
                    >Создать запись</Button>}
                </div>
                {
                    showForm
                        ? (<div>
                            <Button variant={'text'} startIcon={<ChevronLeftIcon />} onClick={handleBack}>Назад</Button>
                            <Form handleBack={handleCreated} setLoader={() => setLoading(true)}/>
                        </div>)
                        : (
                            <List items={posts} handleUpdate={handleUpdate} handleDelete={(uid) => {
                                setOpenConfirmation(true);
                                setCurrentUid(uid);
                            }} />
                        )
                }
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    text={'При удалении категории статей, удалятся все принадлежавшие к ней статьи'}
                    handleDelete={() => handleDelete(currentUid)} />
            </div>
        </PanelLayout>
    );
};

