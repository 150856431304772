import React, {useEffect, useState} from 'react';
import {getDownloadURL, ref} from 'firebase/storage';
import {CheckboxField, CustomButton, CustomSelect, Input} from '../../../fields';
import {RichText} from '../../../components/RichText';
import {value} from '../../../fields/Input';
import {IOption} from '../../../fields/CustomSelect';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import styles from '../Lesson.module.scss';
import {createLesson, updateLesson} from '../api';
import {setLessons} from '../Lesson.slice';
import {ILessonsIn} from '../../../models/lesson';
import {ICourse, typesCourse} from '../../../models/Course';
import {ButtonVariables} from '../../../fields/CustomButton';
import {Reader} from '../../../components/Reader';
import {storage} from '../../../firebase';

interface IFormProps {
    handleBack: () => void;
    setLoader: () => void;
}

export const Form = ({handleBack, setLoader}: IFormProps) => {
    const dispatch = useAppDispatch();
    const {all} = useAppSelector(state => state.admin.stage);
    const {all: allCourse} = useAppSelector(state => state.admin.course);
    const {current} = useAppSelector(state => state.admin.lessons);

    const [nameRU, setNameRU] = useState<value>(current.name.ru);
    const [nameEN, setNameEN] = useState<value>(current.name.en);

    const [sourceRU, setSourceRU] = useState<value>(current.source.ru);
    const [sourceEN, setSourceEN] = useState<value>(current.source.en);
    const [homeWorkRU, setHomeWorkRU] = useState<string>(current.homework.ru);
    const [homeWorkEN, setHomeWorkEN] = useState<string>(current.homework.en);

    const [changedCourse, setChangedCourse] = useState<boolean>(false);

    const [descriptionRU, setDescriptionRU] = useState<string>(current.description.ru);
    const [descriptionEN, setDescriptionEN] = useState<string>(current.description.en);

    const [courses, setCourses] = useState<IOption[]>([]);
    const [currentCourse, setCurrentCourse] = useState<string>('');
    const [stageType, setStageType] = useState<IOption[]>([]);

    const [serialNumber, setSerialNumber] = useState<value>(current.serialNumber);
    const [belonging, setBelonging] = useState<string>(current.belonging);

    const [defaultCourse, setDefaultCourse] = useState<ICourse>();

    const [bannerReader, showBannerReader] = useState<boolean>(current.bannerCourse);
    const [base64, setBase64] = useState<string>('');
    const [base64EN, setBase64EN] = useState<string>('');

    useEffect(() => {
        const courses = allCourse.map(i => {
            return {
                value: i.uid ? i.uid : '',
                label: i.name.ru.toString()
            };
        });
        getUrl(current.uid);
        setCourses(courses);
        getDefaultCourse();
    }, [allCourse]);

    const getUrl = async (uid:any) => {
        const urlImageRu = await getDownloadURL(ref(storage, uid)).then(url => url.toString()).catch(e => {
            return '';
        });
        setBase64(urlImageRu);
        const urlImageEn = await getDownloadURL(ref(storage, `en-${uid}`)).then(url => url.toString()).catch(e => {
            return '';
        });
        setBase64EN(urlImageEn);
    };

    const getDefaultCourse = () => {

        const stages = all.filter(i => i.uid === current.belonging).map(i => {
            return {
                value: i.uid ? i.uid : '',
                label: i.name.ru.toString()
            };
        });
        const s = all.find(i => i.uid === current.belonging);
        const defaultCourse = allCourse.filter(i => i.uid === s?.belonging).pop();
        setDefaultCourse(defaultCourse);
        setStageType(stages);
    };

    const handleCourseType = (option: IOption) => {
        setCurrentCourse(option.label);
        const stages = all.filter(i => i.belonging === option.value).map(i => {
            return {
                value: i.uid ? i.uid : '',
                label: i.name.ru.toString()
            };
        });
        setStageType(stages);
        setChangedCourse(true);
    };

    const handleSelectType = (option: IOption) => {
        setBelonging(option.value);
        setChangedCourse(true);
    };

    const submit = async () => {
        setLoader();
        const type = all.find(s => s.uid === belonging)?.type as typesCourse;
        try {
            const lesson: ILessonsIn = {
                name: {
                    ru: nameRU,
                    en: nameEN
                },
                description: {
                    ru: descriptionRU,
                    en: descriptionEN
                },
                belonging: belonging,
                serialNumber: Number(serialNumber),
                source: {
                    ru: sourceRU,
                    en: sourceEN
                },
                homework: {
                    ru: homeWorkRU,
                    en: homeWorkEN
                },
                type: type ?? '',
                bannerCourse: base64 !== '',
            };
            const lessons = current.uid !== null
                    ? await updateLesson(lesson, current.uid as string, base64, base64EN, changedCourse)
                    : await createLesson(lesson, base64, base64EN);

            dispatch(setLessons(lessons));
            handleBack();
            setChangedCourse(false);
        } catch (e) {
        }
    };



    return (
        <>
            <div className={styles.lesson__row}>
                <div className={styles.lesson__colM}>
                    <div className={styles.lesson__box}>
                        <Input
                            label={'Название ru'}
                            defaultValue={nameRU}
                            handleChangeValue={setNameRU}
                            type={'text'}/>
                    </div>
                    <div className={styles.lesson__box}>
                        <Input
                            label={'Название en'}
                            defaultValue={nameEN}
                            handleChangeValue={setNameEN}
                            type={'text'}/>
                    </div>
                </div>
                <div className={styles.lesson__colM}>
                    <div className={styles.lesson__box}>
                        <label className={styles.lesson__label}>Курс</label>
                        <CustomSelect
                            defaultValue={defaultCourse?.uid ?? ''}
                            options={courses}
                            handler={handleCourseType}
                            className={''}/>
                    </div>
                    <div className={styles.lesson__box}>
                        <label
                            className={styles.lesson__label}>{`Принадлежность к уровню курса ${currentCourse}`}</label>
                        <CustomSelect
                            defaultValue={current.belonging}
                            options={stageType}
                            handler={handleSelectType}
                            className={''}/>
                    </div>
                </div>
            </div>
            <div className={styles.lesson__row}>
                <CheckboxField isChecked={bannerReader} handleChecked={() => showBannerReader(!bannerReader)}
                    label={'загрузить баннер'}/>
                        {
                            bannerReader ? (
                                <>
                                    <div className={styles.course__box}>
                                        <Reader handleReader={setBase64} buttonText={'Загрузите фото RU'} defaultBase64={base64}/>
                                    </div>
                                    <div className={styles.course__box}>
                                        <Reader handleReader={setBase64EN} buttonText={'Загрузите фото EN'} defaultBase64={base64EN}/>
                                    </div>
                                </>
                            ) : null
                        }
            </div>
            <div className={styles.lesson__row}>
                <div className={styles.lesson__colS}>
                    <div className={styles.lesson__box}>
                        <Input
                            label={'Номер ступени'}
                            defaultValue={serialNumber}
                            handleChangeValue={setSerialNumber}
                            type={'number'}/>
                    </div>
                </div>
                <div className={styles.lesson__colS}>
                    <div className={styles.lesson__box}>
                        <Input
                            label={'ID видео ru'}
                            defaultValue={sourceRU}
                            handleChangeValue={setSourceRU}
                            type={'text'}/>
                    </div>
                </div>
                <div className={styles.lesson__colS}>
                    <div className={styles.lesson__box}>
                        <Input
                            label={'ID видео en'}
                            defaultValue={sourceEN}
                            handleChangeValue={setSourceEN}
                            type={'text'}/>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.lesson__box}>
                    <label>Описание ru</label>
                    <RichText handleChange={setDescriptionRU} data={descriptionRU.toString()} height={150}/>
                </div>
                <div className={styles.lesson__box}>
                    <label>Описание en</label>
                    <RichText handleChange={setDescriptionEN} data={descriptionEN.toString()} height={150}/>
                </div>
                <div className={styles.lesson__box}>
                    <label>Домашнее задание ru</label>
                    <RichText handleChange={setHomeWorkRU} data={homeWorkRU.toString()} height={150}/>
                </div>
                <div className={styles.lesson__box}>
                    <label>Домашнее задание en</label>
                    <RichText handleChange={setHomeWorkEN} data={homeWorkEN.toString()} height={150}/>
                </div>
                <CustomButton
                    variant={ButtonVariables.outlined}
                    handleClick={submit}>{
                    current.uid !== null
                        ? 'Сохранить изменения'
                        : 'Сохранить'}
                </CustomButton>
            </div>
        </>
    );
};
