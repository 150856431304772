import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import {defaultStage, IStage} from '../../../models/Stage';
import {firestore} from '../../../firebase';
import {defaultLocale, ICourse} from '../../../models/Course';
import {ITitleStates} from '../../../pages/CoursePage/types';
import {actionTypes} from '../../../types';

export const getStages = async () => {
    const stages: IStage[] = [];
    const docRef = collection(firestore, 'stages');
    const docs = await getDocs(docRef);
    docs.forEach((doc) => {
        const data = doc.data() as IStage;
        const uid = doc.id;
        stages.push({...data, uid});
    });
    return stages;
};


export const createStage = async (stage: IStage) => {
    const stageRef = await addDoc(collection(firestore, 'stages'), stage);

    if (stage.belonging !== '') {
        await listenUpdateIsMain(stage.belonging, {
            name: stage.name,
            serialNumber: stage.serialNumber,
            uid: stageRef.id as string
        });
    }
    //@todo здесь добавляются уровни в курс
    await clearStageFromCourses(stageRef, {...stage, uid: stageRef.id as string});

    return await getStages();
};

export const updateStage = async (stage: IStage, uid: string) => {
    const washingtonRef = doc(firestore, `stages/${uid}`);
    const titleStage: ITitleStates = {
        uid: uid,
        name: stage.name,
        serialNumber: stage.serialNumber
    };

    await listenUpdateIsMain(stage.belonging, titleStage );

    await clearStageFromCourses(washingtonRef, {...stage, uid});

    await updateFreeStagesSerialNumber({uid, stage});

    await updateDoc(washingtonRef, {
        ...stage
    });
    return await getStages();
};

export const deleteStage = async (uid: string, belonging: string) => {
    const washingtonRef = doc(firestore, `stages/${uid}`);
    await deleteDoc(doc(firestore, 'stages', uid));
    // удаляем этот уровень из курса
    await listenUpdateIsMain(null, {name: defaultLocale, serialNumber: defaultStage.serialNumber, uid});
    await clearStageFromCourses(washingtonRef, null);
    // удаляем все уроки относящиеся к уровню
    await deleteBelongingLesson(uid);
    return await getStages();
};


const deleteBelongingLesson = async (uid: string) => {
    const lessonRef = await collection(firestore, 'lessons');
    const q = await query(lessonRef, where('belonging', '==', uid));
    const docs = await getDocs(q);
    docs.forEach((d) => {
        deleteDoc(doc(firestore, 'lessons', d.id));
    });
};


export const listenUpdateIsMain = async (belonging: string | null, titleStage: ITitleStates ) => {
    let mainUid: string = '';

    const q = query(collection(firestore, 'courses'), where('isMain', '==', true));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
        mainUid = doc.id;
    });

    try {
        mainUid === belonging
            ? await writeStageToDependencies(mainUid, titleStage, actionTypes.add)
            : await writeStageToDependencies(mainUid, titleStage, actionTypes.remove);
    } catch (e) {
    }
};

const writeStageToDependencies =
    async (
        mainUid: string,
        titleStage: ITitleStates,
        actionType: actionTypes.add | actionTypes.remove,
    ) => {
        if (mainUid !== '') {
            const washingtonRef = doc(firestore, `freeStages/${mainUid}`);
            const docSnap = await getDoc(washingtonRef);
            if (docSnap.exists()) {
                const prevStage = docSnap.data();
                const arr = prevStage.titleStages as ITitleStates[];
                const update = actionType === actionTypes.add
                    ? arr.some(a => a.uid === titleStage.uid) ? arr.map(item => (
                        item.uid === titleStage.uid
                            ? {...item, name: titleStage.name}
                            : item
                    )) : [...arr, {...titleStage}]
                    : arr.filter(a => a.uid !== titleStage.uid);

                await updateDoc(washingtonRef, {
                    'titleStages': update
                });
            }
            const mainCourseRef = await doc(firestore, `courses/${mainUid}`);
            const courseDoc = await getDoc(mainCourseRef);
            if (courseDoc.exists()) {
                const course = courseDoc.data() as ICourse;
                const stages = course.stages;
                const update = actionType === actionTypes.add
                    ? [...stages, titleStage.uid]
                    : stages.filter(s => s !== titleStage.uid);
                await updateDoc(mainCourseRef, {
                    'stages': update
                });
            }
        }
    };

const clearStageFromCourses = async (ref: any, stage: IStage | null) => {
    const prevStage = await getDoc(ref);
    if (prevStage.exists()) {

        const prev = prevStage.data() as IStage;
        const prevRef = await doc(firestore, `courses/${prev.belonging}`);
        const prevDoc = await getDoc(prevRef);
        if (prevDoc.exists()) {
            const course = prevDoc.data() as ICourse;
            const update = course.stages.filter(s => s !== stage?.uid);
            await updateDoc(prevRef, {
                'stages': update
            });
        }

        if (stage !== null) {
            const courseRef = await doc(firestore, `courses/${stage?.belonging}`);
            const courseDoc = await getDoc(courseRef);
            if (courseDoc.exists()) {
                const course = courseDoc.data() as ICourse;
                await updateDoc(courseRef, {
                    'stages': [...course.stages, stage.uid]
                });
            }
        }
    }
};

const updateFreeStagesSerialNumber = async ({uid, stage}:{uid: string, stage: IStage}) => {
    const freeStagesRef = await collection(firestore, 'freeStages');
    const querySnapshot = await getDocs(freeStagesRef);
    querySnapshot.forEach((doc) => {
        const {titleStages} = doc.data() as {titleStages: ITitleStates[] };
        const updateTitleStages = titleStages.map(i => {
            if(i.uid === uid) {
                return {...i, serialNumber: stage.serialNumber, name: stage.name};
            }
            return i;
        });
        updateDoc(doc.ref, {
            'titleStages': updateTitleStages,
        });
    });
};

