export interface ITemporary {
    id: string;
    timeStump: number | null;
}

export interface IUser {
    email: string | null;
    displayName: string | null;
    uid: string;
    role?: string;
    subscribe: boolean;
    isPaid: boolean;
    temporary: ITemporary[];
    mainStages: string[];
    courses: string[];
    create_as?: number;
    update_as?: number | null;
    account: 'disable' | 'enable';
}

export const userDefault:IUser = {
    email: null,
    displayName: null,
    uid: '',
    role: '',
    subscribe: false,
    isPaid: false,
    temporary: [],
    mainStages: [],
    courses: [],
    create_as: Date.now(),
    update_as: null,
    account: 'disable'
};
