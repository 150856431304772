import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import styles from '../AllCourses.module.scss';
import {useAppSelector} from '../../../hooks';
import {IStage} from '../../../models/Stage';

export const FreeLessonsList = () => {
    const {i18n} = useTranslation();
    const {freeStages} = useAppSelector(state => state.front.allCourses);
    const navigate = useNavigate();

    const handleJump = (course: IStage) => {
       navigate(`stages/${course.uid}`);
    };

    return (
        <ul className={styles.list}>
            {freeStages.map((item) => <li key={item.uid} className={styles.list__item}>
                <button className={styles.list__link}
                        onClick={() => handleJump(item)}
                >{i18n.language === 'ru' ? item.name.ru : item.name.en}</button>
            </li>)}
        </ul>
    );
};
