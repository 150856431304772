import {IConsultation} from '../../models/Consultation';

export interface IConsultationProps {

}

export interface IConsultationState {
    item:IConsultation;
    loading: boolean;
}

export const defaultConsultation:IConsultation = {
    uid: undefined,
    name: {
        ru: '', en: ''
    },
    description: {
        ru: '', en: ''
    }
};
