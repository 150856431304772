import {value} from '../fields/Input';

export const enum typesCourse {
    free = 'free',
    constant = 'constant',
    temporary = 'temporary'
}

export const typeValuesCourse = {
    [typesCourse.free]: 'Бесплатный',
    [typesCourse.temporary]: 'Временный',
    [typesCourse.constant]: 'Постоянный'
};

export interface locale {
    ru: value;
    en: value;
}


export const defaultLocale:locale = {
    ru: '',
    en: ''
};

export interface TemporaryNames {
    uid: string;
    name: locale,
    bannerCourse: boolean;
    create_as: number
}

export interface ICourse {
    uid?: string | null;
    name: locale,
    description: {
        ru: string,
        en: string
    },
    type: typesCourse.free | typesCourse.temporary | typesCourse.constant;
    stages: string[],
    isMain: boolean;
    isPurchase: boolean;
    create_as?: number;
    bannerCourse: boolean;
    duration: number;
    price: number;
}

export const defaultCourse: ICourse = {
    uid: null,
    name: defaultLocale,
    description: {ru: '', en: ''},
    type: typesCourse.free,
    stages: [],
    isMain: false,
    isPurchase: true,
    create_as: Date.now(),
    bannerCourse: false,
    duration: 0,
    price: 0
};



