import {combineReducers} from '@reduxjs/toolkit';
import course from '../Course/Course.slice';
import stage from '../Stage/Stage.slice';
import users from '../Users/Users.slice';
import lessons from '../Lesson/Lesson.slice';
import postsCategory from '../PostCategory/PostCategory.slice';
import posts from '../Post/Post.slice';
import shastrs from '../Shastr/Shastr.slice';
import shastrsCategory from '../CategoryShastr/ShastrCategory.slice';
import consultationAdmin from '../ConsultationAdmin/ConsultationAdmin.slice';

const adminReducer = combineReducers({
    course,
    stage,
    users,
    lessons,
    posts,
    postsCategory,
    shastrs,
    shastrsCategory,
    consultationAdmin
});

export default adminReducer;
