import React from 'react';
import {ICourse} from '../../../models/Course';
import styles from '../Course.module.scss';
import {ListItem} from './ListItem';

interface IListName {
    items: ICourse[];
    handleUpdate: (uid:string) => void;
    handleDelete: (uid:string) => void;
}

export const List = ({items = [], handleUpdate, handleDelete}: IListName) => {
    return (
        <ul className={styles.list}>
            {items.map(i =>
                <ListItem
                    key={i.uid}
                    uid={i.uid}
                    isMain={i.isMain}
                    isPurchase={i.isPurchase}
                    name={i.name}
                    description={i.description}
                    type={i.type}
                    stages={i.stages}
                    create_as={i.create_as}
                    duration={i.duration}
                    price={i.price}
                    bannerCourse={i.bannerCourse}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                />)}
        </ul>
    );
};
