import React, {useEffect, useState} from 'react';
import {Navigate, Outlet, Route, Routes, useLocation} from 'react-router-dom';
import {Login, ResetPasswordPage} from './pages/Login';
import {useAppDispatch, useAuth} from './hooks';
import {EmailVerify, Registration} from './pages/Registration';
import {Lesson} from './admin/Lesson';
import {Stage} from './admin/Stage';
import {Panel} from './admin/Panel';
import {Course} from './admin/Course';
import {CoursePage} from './pages/CoursePage';
import {HomePage} from './pages/HomePage';
import {getUser} from './admin/Panel/api';
import {setUser} from './store/app.slice';
import {Users} from './admin/Users';
import {AppLoader} from './components/AppLoader';
import {ErrorPage} from './pages/ErrorPage';
import {AllCourses} from './pages/AllCourses';
import {StagesPage} from './pages/StagesPage';
import {LessonPage} from './pages/LessonPage';
import {Post} from './admin/Post';
import {AllPostsPage, PostPage} from './pages/PostPage';
import {DayOfTheWeek} from './pages/DayOfTheWeek';
import {TemporaryCourse} from './pages/TemporaryCourse';
import {PostCategory} from './admin/PostCategory';
import {Shastr} from './admin/Shastr';
import {ShastrCategory} from './admin/CategoryShastr';
import {PublicPostCategory} from './pages/PostPage/PublicPostCategory';
import {AllShastrPage, ShastrPage} from './pages/ShastrPage';
import {PublicShastrCategory} from './pages/ShastrPage/PublicShastrCategory';
import {Policy} from './pages/policy';
import {Consultation} from './pages/Consultation';
import {ConsultationAdmin} from './admin/ConsultationAdmin';

const App = () => {
    useEffect(() => {
        window.oncontextmenu = null;
    }, []);
    return (
        <Routes>
            <Route path={'/'} element={<HomePage />} />
            <Route path={'/login'} element={<Login />} />
            <Route path={'/registration'} element={<Registration />} />
            <Route path={'/email-verify'} element={<EmailVerify />} />
            <Route path={'/reset-password'} element={<ResetPasswordPage />} />
            <Route path={'/courses/main'} element={<CoursePage />}/>
            <Route path={'/courses/main/stages/*'} element={<PrivateRoutes />}>
                <Route path={':stagesUid'} element={<StagesPage />}/>
             </Route>
             <Route path={'/courses/main/stages/:stagesUid/lesson/*'} element={<PrivateRoutes />}>
                <Route path={':lessonUid'} element={<LessonPage />}/>
             </Route>
            <Route path={'/posts/:id'} element={<AllPostsPage />} />
            <Route path={'/post-category'} element={<PublicPostCategory />} />
            <Route path={'/consultation'} element={<Consultation />} />
            <Route path={'/post'} element={<PostPage />} />
            <Route path={'/shastrs/:id'} element={<AllShastrPage />} />
            <Route path={'/shastr-category'} element={<PublicShastrCategory />} />
            <Route path={'/shastr'} element={<ShastrPage />} />
            <Route path={'/content/day-of-the-week'} element={<DayOfTheWeek />} />
            <Route path={'/policy'} element={<Policy />} />
            <Route path={'/courses/all'} element={<AllCourses />} />
            <Route path={'/courses/all/temporary/*'} element={<PrivateRoutes />}>
                <Route path={':uid'} element={<TemporaryCourse />}/>
             </Route>
             <Route path={'/courses/all/temporary/:uid/stages/*'} element={<PrivateRoutes />}>
                <Route path={':stagesUid'} element={<StagesPage />}/>
             </Route>
             <Route path={'/courses/all/temporary/:uid/lesson/*'} element={<PrivateRoutes />}>
                <Route path={':lessonUid'} element={<LessonPage />}/>
             </Route>
            <Route path={'/courses/all/stages/:stagesUid'} element={<StagesPage />} />
            <Route path={'/courses/all/stages/:stagesUid/lesson/:lessonUid'} element={<LessonPage />} />
            <Route path={'*'} element={<ErrorPage />} />
            <Route path={'/panel/'} element={<PanelRoutes />}>
                <Route index element={<Panel />} />
                <Route path={'course'} element={<Course />} />
                <Route path={'stage'} element={<Stage />} />
                <Route path={'lesson'} element={<Lesson />} />
                <Route path={'users'} element={<Users />} />
                <Route path={'posts'} element={<Post />} />
                <Route path={'category-posts'} element={<PostCategory />} />
                <Route path={'shaster'} element={<Shastr />} />
                <Route path={'category-shaster'} element={<ShastrCategory />} />
                <Route path={'consultation'} element={<ConsultationAdmin />} />
            </Route>
        </Routes>
    );
};

function PrivateRoutes() {
    const {isAuth} = useAuth();
    const location = useLocation();

    return isAuth ? (
        <Outlet/>
    ) : (
        <Navigate to={'/login'} state={location.state}/>
    );
}

function PanelRoutes() {
    const dispatch = useAppDispatch();
    const {role, uid} = useAuth();
    const [loader, setLoader] = useState<boolean>(true);
    useEffect(() => {
        getUser(uid).then(user => {
            if(user) {
                dispatch(setUser(user));
            }
            setLoader(false);
        });
    },[dispatch, uid]);

    if(loader) {
        return <AppLoader loading={loader} />;
    }

    return role === 'ADMIN' ? (
        <Outlet />
    ) : (
        <Navigate to={'/'}/>
    );
}

export default App;
