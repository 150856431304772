import {createSlice} from '@reduxjs/toolkit';
import {defaultCourse} from '../../models/Course';
import {IAllCoursesState} from './types';

const initialState: IAllCoursesState = {
    temporaryCourse: defaultCourse,
    temporaryCoursesNotBanner: [],
    freeLessons: [],
    freeCourses: [],
    freeStages: []
};

const AllCoursesSlice = createSlice({
    name: 'allCourses',
    initialState,
    reducers: {
        setTemporaryCourse: (state, {payload}) => {
            state.temporaryCourse = payload;
        },
        setTemporaryCoursesNotBanner: (state, {payload}) => {
            state.temporaryCoursesNotBanner = payload;
        },
        setFreeLessons: (state, {payload}) => {
            state.freeLessons = payload;
        },
        setFreeCourses: (state, {payload}) => {
            state.freeCourses = payload;
        },
        setFreeStages: (state, {payload}) => {
            state.freeStages = payload;
        }
    }
});

export const {setTemporaryCourse, setFreeLessons, setFreeCourses, setFreeStages, setTemporaryCoursesNotBanner} = AllCoursesSlice.actions;

export default AllCoursesSlice.reducer;
