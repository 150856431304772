import {createSlice} from '@reduxjs/toolkit';
import {defaultShastr, IPostState} from './types';

const initialState:IPostState = {
    shastrs: [],
    currentShastr: defaultShastr
};

const ShastrSlice = createSlice({
    name: 'post',
    initialState,
    reducers:{
        setShasters: (state, {payload}) => {
            state.shastrs = payload;
        },
        setShaster: (state, {payload}) => {
            state.currentShastr = payload;
        },
        clearCurrentShaster: (state) => {
            state.currentShastr = defaultShastr;
        }
    }
});

export const {setShasters,
    setShaster,
    clearCurrentShaster} = ShastrSlice.actions;

export default ShastrSlice.reducer;
