import React from 'react';
import styles from '../Stage.module.scss';
import {IStage} from '../../../models/Stage';
import {ListItem} from './ListItem';

interface IListName {
    items: IStage[];
    handleUpdate: (uid: string) => void;
    handleDelete: (uid: string, belonging: string) => void;
}

export const List = ({items, handleUpdate, handleDelete}: IListName) => {

    return (
        <ul className={styles.list}>
            {items.map(i =>
                <ListItem
                    key={i.uid}
                    uid={i.uid}
                    name={i.name}
                    serialNumber={i.serialNumber}
                    belonging={i.belonging}
                    description={i.description}
                    lessonsIn={i.lessonsIn}
                    type={i.type}
                    price={i.price}
                    isPurchase={i.isPurchase}
                    handleDelete={handleDelete}
                    handleUpdate={handleUpdate}
                />)}
        </ul>
    );
};
