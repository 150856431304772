import React from 'react';
import {ITemplateNaneProps} from './types';
import styles from './Title.module.scss';
// eslint-disable-next-line
export const Title = ({title}: ITemplateNaneProps) => {
    // const {displayName} = useAppSelector(state => state.front.app.user);
    return (
        <div className={styles.titleWRap}>
            <h1 className={styles.titleWRap__title}>{title}</h1>
            {/*<p className={styles.titleWRap__displayName}>{displayName}</p>*/}
        </div>
    );
};

