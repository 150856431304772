import React from 'react';
import {Button, colors} from '@mui/material';
import styles from '../Lesson.module.scss';
import {useAppSelector, useTranslateServerData} from '../../../hooks';
import {ILessonsIn} from '../../../models/lesson';

interface IListItemProps extends ILessonsIn{
    handleUpdate: (uid: string ) => void;
    handleDelete:(uid: string,belonging:string) => void;
}

export const ListItem = ({name, uid, belonging, source, description, homework, create_as, serialNumber, handleUpdate, handleDelete}:IListItemProps) => {
    const {translate} = useTranslateServerData();
    const {all} = useAppSelector(state => state.admin.stage);
    const bel = all.find(i => i.uid === belonging)?.name.ru;

    return (
        <li className={styles.list__item}>
            <p className={styles.list__text}>{translate(name)}</p>
            <p className={styles.list__text}>{serialNumber}</p>
            <p className={styles.list__text}>{bel}</p>
            <div className={styles.list__controls}>
                <Button
                    variant="outlined"
                    onClick={() => uid ? handleUpdate(uid) : null}>редактировать</Button>
                <Button
                    sx={{marginLeft: '8px', color: colors.red[700]}}
                    variant={'text'}
                    onClick={() => uid ? handleDelete(uid, belonging) : null}>удалить</Button>
            </div>
        </li>
    );
};
