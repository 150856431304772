import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {InputAdornment} from '@mui/material';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import styles from '../Registration.module.scss';
import {CustomButton, CheckboxField, Input} from '../../../fields';
import {value} from '../../../fields/Input';
import {ButtonVariables} from '../../../fields/CustomButton';
import keyIcon from '../../../assets/icons/key.svg';
import mailIcon from '../../../assets/icons/envelope.svg';
import smileIcon from '../../../assets/icons/smile.svg';
import returnIcon from '../../../assets/icons/return.svg';
import homeIcon from '../../../assets/icons/home.svg';

interface IFormProps {
    handleSubmit: (email: value, password: value, displayName: value) => void;
}

export const Form = ({handleSubmit}: IFormProps) => {
    const {t} = useTranslation();
    const location:any = useLocation();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };
    const [email, setEmail] = useState<value>('');
    const [displayName, setDisplayName] = useState<value>('');
    const [password, setPassword] = useState<value>('');
    const [repeatPassword, setRepeatPassword] = useState<value>('');
    const [isAgreement, setAgreement] = useState<boolean>(true);
    const [passwordSwitchType, setSwitchType] = useState<boolean>(true);
    const [isDisabled, setDisabled] = useState<boolean>(false);

    return (
        <div className={styles.form}>
            <p className={styles.form__title}>
                <span>{t('auth.title')}</span> <br/>{t('auth.subtitle')}</p>
            <p className={styles.form__text}>{t('auth.text')}</p>
            <div className={styles.form__box}>
                <Input
                    type={'Имя'}
                    placeholder={`${t('auth.name')}:`}
                    defaultValue={displayName}
                    handleChangeValue={value => setDisplayName(value)}
                    icon={
                        <InputAdornment position="start">
                            <img style={{width: '20px'}} src={smileIcon} alt={'key'} />
                        </InputAdornment>
                    }
                />
            </div>
            <div className={styles.form__box}>
                <Input
                    type={'email'}
                    placeholder={`${t('auth.email')}:`}
                    defaultValue={email}
                    handleChangeValue={value => setEmail(value)}
                    icon={
                        <InputAdornment position="start">
                            <img style={{width: '20px'}} src={mailIcon} alt={'key'} />
                        </InputAdornment>
                    }
                />
            </div>
            <div className={styles.form__box}>
                <Input
                    type={passwordSwitchType ? 'password' : 'text'}
                    placeholder={`${t('auth.password')}:`}
                    defaultValue={password}
                    handleChangeValue={value => setPassword(value)}
                    onBlur={() => {
                        if(password !== repeatPassword) {
                            setDisabled(true);
                        } else {
                            setDisabled(false);
                        }
                    }}
                    icon={
                        <InputAdornment
                            position="start"
                            sx={{cursor : 'pointer'}}
                            onClick={() => setSwitchType(!passwordSwitchType)}>
                            <img style={{width: '20px'}} src={keyIcon} alt={'key'} />
                        </InputAdornment>
                    }
                />
            </div>
            <div className={styles.form__box}>
                <Input
                    type={passwordSwitchType ? 'password' : 'text'}
                    placeholder={`${t('auth.repeat')}:`}
                    defaultValue={repeatPassword}
                    handleChangeValue={value => setRepeatPassword(value)}
                    error={isDisabled}
                    onBlur={() => {
                        if(password !== repeatPassword) {
                            setDisabled(true);
                        } else {
                            setDisabled(false);
                        }
                    }}
                    icon={
                        <InputAdornment
                            position="start"
                            sx={{cursor : 'pointer'}}
                            onClick={() => setSwitchType(!passwordSwitchType)}>
                            <img style={{width: '20px'}} src={keyIcon} alt={'key'} />
                        </InputAdornment>
                    }
                />
            </div>
            <div className={styles.form__box}>
                <CheckboxField
                    isChecked={isAgreement}
                    handleChecked={() => setAgreement(!isAgreement)}
                    label={''}
                />
                <NavLink to={'/policy'} className={styles.form__link}>{t('auth.policy')}
                </NavLink>
            </div>
            <div className={styles.form__box}>
                <CustomButton
                    disabled={isDisabled}
                    handleClick={() => handleSubmit(email, password, displayName)}
                    variant={ButtonVariables.contained}
                    sx={{
                        padding: '7px 25px',
                        margin: 'auto',
                        display: 'inline-block'
                    }}
                >
                    {t('auth.registration')}
                </CustomButton>
            </div>
            <NavLink to={'/login'} state={{redirect: location.state?.redirect}} className={styles.register__link}>
                {t('auth.login')}
            </NavLink>
            <div className={styles.breadCrumbs}>
                <button onClick={handleGoBack}>
                    <img src={returnIcon} className={styles.breadCrumbs__icon} alt={'home'} />
                </button>
                <NavLink to={'/'}>
                    <img src={homeIcon} className={styles.breadCrumbs__icon} alt={'home'} />
                </NavLink>
            </div>
        </div>
    );
};
