import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {PanelLayout} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {IStage} from '../../models/Stage';
import {AppLoader} from '../../components/AppLoader';
import {ConfirmationModal} from '../../components/ConfirmationModal';
import styles from './Stage.module.scss';
import {Form} from './components';
import {List} from './components/List';
import {clearCurrentStage, setCurrentStage, setStages} from './Stage.slice';
import {deleteStage, getStages} from './api';



export const Stage = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {all} = useAppSelector(state => state.admin.stage);
    const {all : AllCourses} = useAppSelector(state => state.admin.course);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<{ uid: string, belonging: string }>({uid: '', belonging: ''});
    async  function sortStages(stages: IStage[]) {
        let STAGES:IStage[] = [];
        const uids: string[] = [];
        for await (const stage of stages) {
            const uidCourse = AllCourses.filter(i => i.uid === stage.belonging).map(i => i.uid).pop() as string;
            uids.push(uidCourse);
        }

        const courseUids = uids.filter((element, index) => {
            return uids.indexOf(element) === index;
        });

        for await (const uid of courseUids) {
            const arr = stages.filter(item => item.belonging === uid);
            STAGES = [...STAGES, ...arr.sort((a, b) => a.serialNumber - b.serialNumber)];
        }

        return STAGES;
    }

    useEffect(() => {
        setLoading(true);
        getStages().then( async (stages) => {
           sortStages(stages as IStage[]).then(stages => {
               dispatch(setStages(stages));
           });
        });
        setLoading(false);
    }, [dispatch, showForm]);

    const handleBack = () => {
        setLoading(true);
        setShowForm(false);
        dispatch(clearCurrentStage());
        setLoading(false);
    };

    const handleUpdate = useCallback(async (uid: string) => {
        setLoading(true);
        const current = all.find(i => i.uid === uid) as IStage;
        dispatch(setCurrentStage(current));
        setShowForm(true);
        setLoading(false);
    }, [dispatch, all]);

    const handleDelete = useCallback(async (uid: string, belonging: string) => {
        setLoading(true);
        const stages = await deleteStage(uid, belonging);
        dispatch(setStages(stages));
        setLoading(false);
    }, [dispatch]);

    return (
        <PanelLayout>
            <>
                <AppLoader loading={loading}/>
                <div className={styles.stage}>
                    <div className={styles.stage__header}>
                        <h1 className={styles.stage__title}>{t('stage.title')}</h1>
                        {!showForm && <Button
                            variant={'contained'}
                            className={styles.course__createBtn}
                            onClick={() => setShowForm(true)}
                        >Создать уровень</Button>}
                    </div>
                    {showForm
                        ? (<div>
                            <Button variant={'text'} startIcon={<ChevronLeftIcon />} onClick={handleBack}>Назад</Button>
                            <Form handleBack={handleBack} setLoader={() => setLoading(true)}/>
                        </div>)
                        : (
                            <List
                                items={all}
                                handleUpdate={handleUpdate}
                                handleDelete={(uid, belonging) => {
                                    setOpenConfirmation(true);
                                    setCurrentItem({
                                        uid, belonging
                                    });
                                }
                            }/>
                        )}
                </div>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    text={'Если вы удалите этот уровень, то удалятся все принадлежавшие к нему уроки'}
                    handleDelete={() => handleDelete(currentItem.uid, currentItem.belonging)}/>
            </>
        </PanelLayout>
    );
};

