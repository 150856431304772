import React, {useEffect, useState} from 'react';
import { doc, onSnapshot, getFirestore } from 'firebase/firestore';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Vimeo from '@u-wave/react-vimeo';
import YouTube, { YouTubeProps } from 'react-youtube';
import {Main} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {defaultStage, IStage} from '../../models/Stage';
import {AppLoaderSmall} from '../../components/AppLoader';
import {setCurrentLessons, setLesson} from '../LessonPage/LessonPage.slice';
import {ILessonsIn} from '../../models/lesson';
import {BreadCrumbs} from '../../components/BreadCrumbs';
import {Title} from '../../components/Title';
import {typesCourse} from '../../models/Course';
import styles from './StagesPage.module.scss';
import {getLessonInStage} from './api';

export const StagesPage = () => {
    const {stagesUid} = useParams();
    const {i18n, t} = useTranslation();
    const isRu = i18n.language === 'ru';
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {currentLessons} = useAppSelector(state => state.front.lessons);
    const {user} = useAppSelector(state => state.front.app);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentStage, setCurrentStage] = useState<IStage>(defaultStage);
    const [isAssets, setAssets] = useState<boolean>(false);

    const pay = (currentCourse: any, userInfo: any, isRu: any) => {
        (window as any).buyCourse(currentCourse, userInfo, isRu);
    };

    const opts: YouTubeProps['opts'] = {
        height: '768',
        width: '432',
    };

    const createLabel = (number: number, titles: Array<string>) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
    };

    const [updatedUser, setUpdatedUser] = useState<any>(user);
    const [snapshotCount, setSnapshotCount] = useState<any>(0);

    useEffect(() => {
        const db = getFirestore();

        onSnapshot(doc(db, 'users', user.uid), (doc) => {
            setUpdatedUser(doc.data());
            setSnapshotCount(snapshotCount + 1);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        getLessonInStage(stagesUid as string).then(({lessons, stage}) => {

            if(stage.type === typesCourse.temporary) {
                const ids = updatedUser.temporary.map((i:any) => i.id);
                if (ids.includes(stagesUid as string)) {
                    setAssets(true);
                } else {
                    setAssets(false);
                }
            }

            if(stage.type === typesCourse.constant) {
                if(updatedUser.mainStages.includes(stagesUid as string)) {
                    setAssets(true);
                } else {
                    setAssets(false);
                }
            }

            dispatch(setCurrentLessons(lessons));
            setCurrentStage(stage);
            setLoading(false);
        });
    }, [snapshotCount]);

    const handleJump = async (i: ILessonsIn) => {
        await dispatch(setLesson(i));
        navigate(`lesson/${i.uid}`);
    };

    return (
        <Main>
            <div className={styles.stagesPage}>
                <Title title={isRu ? currentStage.name.ru : currentStage.name.en} />
                {loading ? 
                    <AppLoaderSmall loading={loading}/> :

                    !isAssets ?
                    
                        <>

                            { currentStage.isPurchase ?
                                <>
                                    <div className={styles.stagesPage__info_price}>{t('course.price')}&nbsp;
                                        {isRu ? (
                                            currentStage.price > 0 ? currentStage.price + createLabel(currentStage.price, [' рубль', ' рубля', ' рублей']) : 'бесплатно'
                                        ) : (
                                            currentStage.price > 0 ? currentStage.price + (currentStage.price > 1 ? ' ruble' : ' rubles') : 'free'
                                        )}
                                    </div>
                                    <div className={styles.stagesPage__info}>{t('temporaryCourse.subDuration')}&nbsp;
                                        {isRu ? 'бессрочный' : 'unlimited'}
                                    </div>
                                    <button className={styles.stagesPage__button} onClick={() => pay(currentStage,updatedUser,isRu)}>{t('course.buy')}</button>
                                </>
                                : <div className={styles.stagesPage__info_price}>{t('course.locked')}</div>
                            }
                        </>
                    : 
                        <div className={styles.list}>
                            <div className={styles.row}>
                                {currentLessons.map(i => (
                                    <div
                                        key={i.uid}
                                        className={styles.list__item}
                                        onClick={() => handleJump(i)}>
                                        {(`${i.source.ru}`)?.length === 11 ?
                                            <YouTube
                                                videoId={isRu ? (`${i.source.ru}`) : (`${i.source.en}`)}
                                                className={styles.video}
                                                opts={opts}
                                            />
                                            :
                                            <Vimeo
                                                video={isRu ? i.source.ru : i.source.en}
                                                className={styles.video}
                                                width={768}
                                                height={768 / 16 * 9}
                                                controls
                                            />
                                        }
                                        <span className={styles.list__link}>
                                            <span>{isRu ? i.name.ru : i.name.en}</span>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                }
            </div>
            <BreadCrumbs isBack={true}/>
        </Main>
    );
};

