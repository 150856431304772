import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {useLocation} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {switchLang} from '../../store/app.slice';
import styles from './Switcher.module.scss';

const yellowPaths = ['/registration', '/login', '/reset-password'];

export const SwitcherLang = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const {lang} = useAppSelector(state => state.front.app);
    const {i18n} = useTranslation();
    const changeLang = async (lang: string) => {
        dispatch(switchLang(lang));
    };

    useEffect( () => {
        (async() => await i18n.changeLanguage(lang))();
    }, [lang]);

    return (
        <div className={classNames(styles.switcher, styles.switcher__MlAuto)}>
            <button className={
                location.pathname === '/'
                    ? styles.switcher__button
                    : yellowPaths.includes(location.pathname)
                        ? classNames(styles.switcher__button, styles.switcher__buttonAuth)
                        : classNames(styles.switcher__button, styles.switcher__buttonSingle)
            } onClick={() => changeLang('ru')}>rus
            </button>
            <button className={
                location.pathname === '/'
                    ? styles.switcher__button
                    : yellowPaths.includes(location.pathname)
                        ? classNames(styles.switcher__button, styles.switcher__buttonAuth)
                        : classNames(styles.switcher__button, styles.switcher__buttonSingle)
            } onClick={() => changeLang('en')}>eng
            </button>
        </div>
    );
};
