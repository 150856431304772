import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where} from 'firebase/firestore';
import {deleteObject, ref, uploadString} from 'firebase/storage';
import {ICourse, typesCourse} from '../../../models/Course';
import {firestore, storage} from '../../../firebase';
import {IStage} from '../../../models/Stage';

async function uploadFile(base64: string, postUid: string) {
    try {
        const storageRef = ref(storage, `gs://djsya-web-app.appspot.com/${postUid}`);
        await uploadString(storageRef, base64, 'data_url');
    } catch (e) {
    }
};

export const getCourses = async () => {
    const courses: ICourse[] = [];
    const docRef = collection(firestore, 'courses');
    const docs = await getDocs(docRef);
    docs.forEach((doc) => {
        const data = doc.data() as ICourse;
        const uid = doc.id;
        courses.push({...data, uid});
    });

    return courses;
};

export const createCourse = async (course: ICourse, base64?: string, base64EN?: string) => {

    const courseRef = await addDoc(collection(firestore, 'courses'), {...course, create_as: Date.now()});
    base64 !== undefined && await uploadFile(base64 as string, courseRef.id);
    base64EN !== undefined && await uploadFile(base64EN as string, `en-${courseRef.id}`);

    const docRef = doc(firestore, 'temporaryNames', courseRef.id);
    await setDoc(docRef, {
        uid: courseRef.id,
        name: course.name,
        bannerCourse: course.bannerCourse,
        create_as: Date.now()
    });

    return await getCourses();
};

export const updateCourse = async (course: ICourse, uid: string, base64?: string, base64EN?: string) => {
    try {

        const temporaryNamesRef = doc(firestore, 'temporaryNames', uid);
        const temporaryNamesSnap = await getDoc(temporaryNamesRef);
        const item = {
            uid: uid,
            name: course.name,
            bannerCourse: course.bannerCourse,
            create_as: course.create_as
        };
        if(temporaryNamesSnap.exists()) {
            await updateDoc(temporaryNamesRef,{
                ...item
            });
        } else {
            await setDoc(temporaryNamesRef, {...item});
        }

        const washingtonRef = await doc(firestore, `courses/${uid}`);
        const docSnap = await getDoc(washingtonRef);
        if (docSnap.exists()) {
            const prevCourse = docSnap.data() as ICourse;
            if (prevCourse.type === typesCourse.temporary && course.type !== typesCourse.temporary) {
                const desertRef = ref(storage, uid);
                await deleteObject(desertRef);
            }
            if(prevCourse.isMain && course.isMain) {
                await updateFreeStages(uid, course); //@todo если главный курс изменен то удаляем
            }
            if (course.type === typesCourse.temporary) {
                base64 !== undefined && await uploadFile(base64 as string, uid);
                base64EN !== undefined && await uploadFile(base64EN as string, `en-${uid}`);
            }
            await updateDoc(washingtonRef, {
                ...course
            });
        }


        return await getCourses();
    } catch (e) {

    }
};

export const deleteCourse = async (uid: string, isMain: boolean = false) => {
    const temporaryNamesRef = await collection(firestore, 'temporaryNames');
    const qTN = await query(temporaryNamesRef, where('uid', '==', uid));
    const docsTN = await getDocs(qTN);
    docsTN.forEach((d) => {
        deleteDoc(doc(firestore, 'temporaryNames', d.id));
    });

    const stageRef = collection(firestore, 'stages');
    const q = query(stageRef, where('belonging', '==', uid));
    const docs = await getDocs(q);
    docs.forEach((d) => {
        deleteBelongingLesson(d.id); // удаляем все уроки относящиеся к уровню
        deleteDoc(doc(firestore, 'stages', d.id)); // удаляем все уровни относящиеся к курсу
    });
    await removeCourseFromUser(uid); // удаляем этот курс у всех пользователей
    isMain && await deleteDoc(doc(firestore, 'freeStages', uid));  // удаляем все уровни относящиеся к курсу
    await deleteDoc(doc(firestore, 'courses', uid));
};

const deleteBelongingLesson = async (uid: string) => {
    const lessonRef = await collection(firestore, 'lessons');
    const q = await query(lessonRef, where('belonging', '==', uid));
    const docs = await getDocs(q);
    docs.forEach((d) => {
        deleteDoc(doc(firestore, 'lessons', d.id));
    });
};

const removeCourseFromUser = async (uid: string) => {
    const userRef = await collection(firestore, 'users');
    const q = await query(userRef, where('courses', 'array-contains-any', [uid]));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const user = doc.data();
        updateDoc(doc.ref, {
            'courses': user.courses.filter((i: string) => i !== uid),
        });
    });

    const qTemporary = await query(userRef, where('temporary', 'array-contains-any', [uid]));
    const querySnapshotTemporary = await getDocs(qTemporary);
    querySnapshotTemporary.forEach((doc) => {
        const user = doc.data();
        updateDoc(doc.ref, {
            'temporary': user.temporary.filter((i: string) => i !== uid),
        });
    });
};

export const updateFreeStages = async (uid: string, items: ICourse) => {
    try {
        const oldUid = await findAndDeleteMainCourse();
        if (oldUid !== uid) {
            const titleStages = [];
            for (let i = 0; i < items.stages.length; i++) {
                const washingtonRef = doc(firestore, `stages/${items.stages[i]}`);
                const docSnap = await getDoc(washingtonRef);
                if (docSnap.exists()) {
                    const {name} = docSnap.data() as IStage;
                    titleStages.push({name, uid: docSnap.id});
                }
            }
            await setStagesFreeAccess({oldUid: oldUid, newUid: uid, titleStages});
        }
    } catch (e) {
    }
};

const findAndDeleteMainCourse = async () => {
    try {
        const q = await query(collection(firestore, 'courses'), where('isMain', '==', true));
        if (q) {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.size > 0) {
                const oldCourse: ICourse[] = [];
                await querySnapshot.forEach((doc) => {
                    oldCourse.push({...doc.data(), uid: doc.id} as ICourse);
                });
                const resetMain = {...oldCourse[0], isMain: false};
                const washingtonRef = doc(firestore, `courses/${resetMain.uid}`);
                await updateDoc(washingtonRef, {
                    ...resetMain
                });

                return oldCourse[0].uid as string;
            }
        }
    } catch (e) {
        throw  e;
    }
};

interface IFreeStages {
    titleStages: any,
    oldUid?: string,
    newUid: string
}

export const setStagesFreeAccess = async ({titleStages, newUid, oldUid}: IFreeStages) => {
    try {
        if (titleStages) {
            const docRef = doc(firestore, 'freeStages', newUid);
            await setDoc(docRef, {titleStages});
            if (oldUid !== undefined) {
                await deleteDoc(doc(firestore, 'freeStages', oldUid));
            }
        }
    } catch (e) {
    }
};
