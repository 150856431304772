import React from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';

interface ICheckedProps {
    isChecked: boolean;
    handleChecked: () => void;
    label?: string;
}

export const CheckboxField = ({isChecked, handleChecked, label}:ICheckedProps) => {

    return (
        <FormControlLabel
            control={
                <Checkbox checked={isChecked} onChange={handleChecked} name={label} />
            }
            sx={{
                '& span' : {
                    fontSize: '12px'
                }
            }}
            label={label}
        />
    );
};
