import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import {colors} from '../../utils/constants';
import {useDayOfWeek} from '../../hooks';
import styles from './NavClient.module.scss';
import {config, configDisabledPaths} from './config';

export const NavClient = () => {
    const location = useLocation();
    const isHome = location.pathname === '/';
    const dayOfTheWeek = location.pathname === '/content/day-of-the-week';
    const {t} = useTranslation();
    const {color, colorsLight} = useDayOfWeek();
    const [toggleMenu, setToggleMenu] = useState<boolean>(false);

    useEffect(() => {
        if (location.state !== null) {
            const prevPath = location.state;
            if (prevPath !== location.pathname) {
                setToggleMenu(false);
            }
        }
    }, [location]);

    

    return (
        <>
            <span className={styles.switcher} onClick={() => {
                setToggleMenu(!toggleMenu);
            }}>
                {toggleMenu
                    ? <CloseIcon
                        sx={{
                            backgroundColor: 'rgba(2,1,58,0.56)',
                            color: '#ffffff',
                            fontSize: '42px'
                        }}/>
                    : <MenuIcon
                        sx={{
                            backgroundColor: 'rgba(2,1,58,0.56)',
                            color: '#ffffff',
                            fontSize: '42px'
                        }}/>}
            </span>
            <nav className={toggleMenu
                ? styles.navClient
                : classNames(styles.navClient, styles.top)}
                 style={{
                     backgroundColor: isHome ? colors.light :
                         dayOfTheWeek ? colorsLight : colors.darkOpacity
                 }}>
                <div className={styles.navClient__bg}>
                    <div
                        className={styles.navClient__inner}
                        style={{
                            backgroundColor: isHome ? colors.light :
                                dayOfTheWeek ? color : colors.darkOpacity
                        }}
                    >
                        {toggleMenu && !isHome &&
                            <span className={styles.switcherClose} onClick={() => {
                                setToggleMenu(!toggleMenu);
                            }}>
                            <CloseIcon
                                sx={{
                                    backgroundColor: 'rgba(2,1,58,0.56)',
                                    color: '#ffffff',
                                    fontSize: '42px'
                                }}/>
                            </span>}

                        {config.map(i => (
                            <NavLink
                                key={i.path}
                                to={!configDisabledPaths.includes(i.path) ? i.path : location.pathname}
                                state={{prevPath: location.pathname, redirect: i.path}}
                                className={styles.navClient__link}>
                                {t(`navClient.${[i.name]}`)}
                            </NavLink>
                        ))}
                    </div>
                </div>
            </nav>
        </>
    );
};

