import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Main} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {AppLoaderSmall} from '../../components/AppLoader';
import {getShastrCategoryById} from '../../admin/Shastr/api';
import {loadingPost, setCurrentShastr, setShastr} from './ShastrPage.slice';
import styles from './ShastrPage.module.scss';

export const AllShastrPage = () => {
    const { id } = useParams();
    const {i18n, t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {shastrs} = useAppSelector(state => state.front.shastrs);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        if(id) {
            getShastrCategoryById(id).then(data => {
                dispatch(setShastr(data));
                setLoading(false);
            });
        }
    }, []);

    const handleJump = (uid: string) => {
        const post = shastrs.find(i => i.uid === uid);
        dispatch(loadingPost(true));
        dispatch(setCurrentShastr(post));
        navigate('/shastr');
    };

    if (loading) {
        return (
            <Main>
                <div className={styles.postPage}>
                    <ul className={styles.list}>
                        <AppLoaderSmall loading={loading}/>
                    </ul>
                </div>
            </Main>);
    }

    return (
        <Main>
            <div className={styles.postPage}>
                <ul className={styles.list}>
                    {shastrs?.length > 0 ? shastrs.map(post => (
                        <li key={post.uid} className={styles.list__item}>
                            <div className={styles.card}>
                                <div className={styles.card__img}>
                                    {post?.urlImage !== ''
                                     ? <img src={post.urlImage} alt={post.name.en as string}/>
                                        : null}
                                </div>
                                <div className={styles.card__body}>
                                    <h3 className={styles.card__title}>{
                                        i18n.language === 'ru' ? post.name.ru : post.name.en
                                    }</h3>
                                    <span onClick={() => handleJump(post.uid as string)} className={styles.card__link}>
                                        {t('postsCategory.shastr')}
                                    </span>
                                </div>
                            </div>
                        </li>
                    )) : <AppLoaderSmall loading={loading}/>}
                </ul>
            </div>

        </Main>
    );
};
