import {createSlice} from '@reduxjs/toolkit';
import {ILessonsIn} from '../../models/lesson';
import {ILessonPageState} from './types';

const initialState:ILessonPageState = {
    currentLessons:  [],
    currentLesson: null,
    loading: false
};

const LessonPageSlice = createSlice({
    name: 'lessonPage',
    initialState,
    reducers:{
        setCurrentLessons: (state, {payload}:{payload:ILessonsIn[]}) => {
            state.currentLessons = payload;
        },
        setLesson: (state, {payload}:{payload: ILessonsIn | null}) => {
            state.currentLesson = payload;
        },
        loadingStart: (state) => {
            state.loading = true;
        },
        loadingEnd: (state) => {
            state.loading = false;
        }
    }
});

export const {setCurrentLessons, setLesson, loadingStart, loadingEnd} = LessonPageSlice.actions;

export default LessonPageSlice.reducer;
