import React from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {useDayOfWeek} from '../../hooks';
import {colors} from '../../utils/constants';
import styles from './FooterClient.module.scss';
/* #427 раскомментировать блок и перенести выше после импорта {colors}, чтобы отобразились иконки соцсетей в футере
import tg from '../../assets/icons/telegram.svg';
import inst from '../../assets/icons/insta.svg'; */

export const FooterClient = () => {
    const location = useLocation();
    const dayOfTheWeek = location.pathname === '/content/day-of-the-week';
    const {t} = useTranslation();
    const {color} = useDayOfWeek();
    return (
        <footer className={styles.footerClient} style={{
            backgroundColor: dayOfTheWeek ? color : colors.dark
        }}>
{/* #427 раскомментировать блок, чтобы отобразились иконки соцсетей в футере */}
{/*             <div className={styles.footerClient__social}>
                <p className={styles.footerClient__text}>{t('footer.social')}</p>
                <a href={'https://t.me/alexshamanov'} target={'_blank'} className={styles.footerClient__icon} rel="noreferrer"><img src={tg} alt={'icon'}/></a>
                <a href={'https://instagram.com/alexjeweller'} target={'_blank'} className={styles.footerClient__icon} rel="noreferrer"><img src={inst} alt={'icon'}/></a>
            </div> */}

            <p className={styles.footerClient__text}>{t('footer.copy')}</p>
        </footer>
    );
};

