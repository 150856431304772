import React from 'react';
import {Main} from '../../layouts';
import styles from './HomePage.module.scss';

export const HomePage = ( ) => {
    return (
        <Main>
            <div className={styles.homePage}>

            </div>
        </Main>
    );
};

