import {createSlice} from '@reduxjs/toolkit';
import {IUser, userDefault} from '../models/User';

interface loginTitleObject {
    ru: string;
    en: string;
}

interface IAppState {
    loader:boolean;
    user:IUser;
    lang: 'ru' | 'en';
    loginTitle:loginTitleObject;
    loginHistoryUrl:string;
}

const initialState:IAppState = {
    loader: false,
    user: userDefault,
    lang: 'ru',
    loginTitle: {ru: 'Авторизация', en: 'Authorization'},
    loginHistoryUrl: '',
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, {payload}:{payload: IUser}) => {
            state.user.email = payload.email;
            state.user.displayName = payload.displayName;
            state.user.uid = payload.uid;
            state.user.role = payload.role;
            state.user.subscribe = payload.subscribe;
            state.user.isPaid = payload.isPaid;
            state.user.mainStages = payload.mainStages;
            state.user.temporary = payload.temporary;
            state.user.courses = payload.courses;
            state.user.create_as = payload.create_as;
        },
        switchLang: (state, {payload}) => {
            state.lang = payload;
        },
        changeLoginTitle: (state, {payload}) => {
            state.loginTitle = payload;
        },
        setLoginHistoryUrl: (state, {payload}) => {
            state.loginHistoryUrl = payload;
        },
    }
});

export const {
    setUser, 
    switchLang, 
    changeLoginTitle, 
    setLoginHistoryUrl
} = appSlice.actions;

export default appSlice.reducer;
