import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import Vimeo from '@u-wave/react-vimeo';
import YouTube, { YouTubeProps } from 'react-youtube';
import {Main} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {AppLoaderSmall} from '../../components/AppLoader';
import {ReadOnlyText} from '../../components/ReadOnlyText';
import {BreadCrumbs} from '../../components/BreadCrumbs';
import {Title} from '../../components/Title';
import {Pagination} from '../../components/Pagination';
import {Banner} from '../TemporaryCourse/components/Banner';
import styles from './LessonPage.module.scss';
import {loadingEnd} from './LessonPage.slice';

export const LessonPage = () => {
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru';
    const dispatch = useAppDispatch();
    const {currentLesson, loading} = useAppSelector(state => state.front.lessons);

    const opts: YouTubeProps['opts'] = {
        height: '768',
        width: '432',
    };

    useEffect(() => {
        dispatch(loadingEnd());
    }, [currentLesson]);

    return (
        <Main>
            <div className={styles.lessonPage}>
                {loading ? <AppLoaderSmall loading={loading}/>
                    : currentLesson !== null ? (
                        <>
                            <Title title={isRu ? currentLesson.name.ru : currentLesson.name.en}/>
                            <div className={styles.banner}>
                                <Banner uid={currentLesson.uid}/>
                            </div>
                            {isRu ?
                                currentLesson.source.ru ?
                                <div className={styles.lessonPage__videoWrapper}>
                                    {(`${currentLesson.source.ru}`)?.length === 11 ?
                                        <YouTube
                                            videoId={(`${currentLesson.source.ru}`)}
                                            className={styles.video}
                                            opts={opts}
                                        />
                                        :
                                        <Vimeo
                                            video={currentLesson.source.ru}
                                            className={styles.video}
                                            width={768}
                                            height={768 / 16 * 9}
                                            controls
                                        />
                                    }
                                </div> : <></>
                                 :
                                currentLesson.source.en ?
                                <div className={styles.lessonPage__videoWrapper}>
                                    {(`${currentLesson.source.en}`)?.length === 11 ?
                                        <YouTube
                                            videoId={(`${currentLesson.source.en}`)}
                                            className={styles.video}
                                            opts={opts}
                                        />
                                        :
                                        <Vimeo
                                            video={currentLesson.source.en}
                                            className={styles.video}
                                            width={768}
                                            height={768 / 16 * 9}
                                            controls
                                        />
                                    }
                                </div> : <></>
                            }

                            <Pagination
                                type={'lessons'}
                                uid={currentLesson.uid as string}
                            />

                            <div className={isRu ? styles.lessonPage__box : styles.lessonPage__boxHide}>
                                <ReadOnlyText
                                    initialValue={currentLesson?.description.ru}/>
                            </div>
                            <div className={!isRu ? styles.lessonPage__box : styles.lessonPage__boxHide}>
                                <ReadOnlyText
                                    initialValue={currentLesson?.description.en}/>
                            </div>
                            <div className={isRu ? styles.lessonPage__box : styles.lessonPage__boxHide}>
                                <ReadOnlyText
                                    initialValue={currentLesson?.homework.ru}/>
                            </div>
                            <div className={!isRu ? styles.lessonPage__box : styles.lessonPage__boxHide}>
                                <ReadOnlyText
                                    initialValue={currentLesson?.homework.en}/>
                            </div>
                        </>
                    ) : null
                }
            </div>
            <BreadCrumbs isBack={true}/>
        </Main>
    );
};

