import React from 'react';
import {Button, colors} from '@mui/material';
import styles from '../Shastr.module.scss';
import {useTranslateServerData} from '../../../hooks';
import {IPost} from '../../../models/Post';

interface IListItemProps extends IPost {
    handleUpdate: (uid: string) => void;
    handleDelete: (uid: string) => void;
}

export const ListItem = ({name, description, uid, handleUpdate, handleDelete}: IListItemProps) => {
    const {translate} = useTranslateServerData();

    return (
        <li className={styles.list__item}>
            <p className={styles.list__text}>{translate(name)}</p>
            <div className={styles.list__controls}>
                <Button
                    variant="outlined"
                    onClick={() => uid ? handleUpdate(uid) : null}>редактировать</Button>
                <Button
                    sx={{marginLeft: '8px', color: colors.red[700]}}
                    variant={'text'}
                    onClick={() => uid ? handleDelete(uid) : null}>удалить</Button>
            </div>
        </li>
    );
};
