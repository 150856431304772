import React from 'react';
import {ColorRing} from 'react-loader-spinner';
import {ITemplateNaneProps} from './types';

export const AppLoaderSmall = ({loading}:ITemplateNaneProps) => {
    if(!loading) {
        return <></>;
    }

    return (
        <ColorRing
            visible={true}
            height="40"
            width="40"
            ariaLabel="blocks-loading"
            wrapperStyle={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
            }}
            wrapperClass="blocks-wrapper"
            colors={['#2c2c2c', '#2c2c2c', '#2c2c2c', '#2c2c2c', '#2c2c2c']}
        />
    );
};
