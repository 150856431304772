import {createSlice} from '@reduxjs/toolkit';
import {defaultPost} from '../../admin/Post/types';
import {IPostPageState} from './types';

const initialState: IPostPageState = {
    loader: false,
    posts: [],
    currentPost: defaultPost,
    postCategory: []
};

const PostPageSlice = createSlice({
    name: 'postPage',
    initialState,
    reducers: {
        loadingPost: (state, {payload}) => {
            state.loader = payload;
        },
        setPosts: (state, {payload}) => {
            state.loader = false;
            state.posts = payload;
        },
        setCurrentPost: (state, {payload}) => {
            state.currentPost = payload;
        },
        setPostCategory: (state, {payload}) => {
            state.postCategory = payload;
        }
    }
});

export const {loadingPost, setCurrentPost, setPosts} = PostPageSlice.actions;

export default PostPageSlice.reducer;
