import {createSlice} from '@reduxjs/toolkit';
import {defaultPost} from '../../admin/Post/types';
import {IShastrPageState} from './types';

const initialState: IShastrPageState = {
    loader: false,
    currentShastr: defaultPost,
    shastrCategory: [],
    shastrs: []
};

const ShastrPageSlice = createSlice({
    name: 'postPage',
    initialState,
    reducers: {
        loadingPost: (state, {payload}) => {
            state.loader = payload;
        },
        setShastr: (state, {payload}) => {
            state.loader = false;
            state.shastrs = payload;
        },
        setCurrentShastr: (state, {payload}) => {
            state.currentShastr = payload;
        },
        setShastrCategory: (state, {payload}) => {
            state.shastrCategory = payload;
        }
    }
});

export const {loadingPost, setShastr, setCurrentShastr, setShastrCategory} = ShastrPageSlice.actions;

export default ShastrPageSlice.reducer;
