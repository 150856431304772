import React, {useEffect, useState} from 'react';
import {CheckboxField, CustomButton, CustomSelect, Input} from '../../../fields';
import {RichText} from '../../../components/RichText';
import {value} from '../../../fields/Input';
import {IOption} from '../../../fields/CustomSelect';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import styles from '../Stage.module.scss';
import {IStage} from '../../../models/Stage';
import {createStage, updateStage} from '../api';
import {setStages} from '../Stage.slice';
import {ButtonVariables} from '../../../fields/CustomButton';
import {typesCourse} from '../../../models/Course';

interface IFormProps {
    handleBack: () => void;
    setLoader: () => void;
}

export const Form = ({handleBack, setLoader}: IFormProps) => {
    const dispatch = useAppDispatch();
    const {all} = useAppSelector(state => state.admin.course);
    const {currentStage} = useAppSelector(state => state.admin.stage);
    const [nameRU, setNameRU] = useState<value>(currentStage.name.ru);
    const [nameEN, setNameEN] = useState<value>(currentStage.name.en);

    const [descriptionRU, setDescriptionRU] = useState<string>(currentStage.description.ru);
    const [descriptionEN, setDescriptionEN] = useState<string>(currentStage.description.en);

    const [courseType, setCourseType] = useState<IOption[]>([]);
    const [serialNumber, setSerialNumber] = useState<value>(currentStage.serialNumber);
    const [belonging, setBelonging] = useState<string>(currentStage.belonging);

    const [price, setPrice] = useState<number>(currentStage.price);
    const [isPurchased, setPurchased] = useState<boolean>(currentStage.isPurchase ?? false);


    useEffect(() => {
        const courses = all.map(i => {
            return {
                value: i.uid ? i.uid : '',
                label: i.name.ru.toString()
            };
        });
        setCourseType(courses);
    }, [all]);

    const handleSelectType = (option: IOption) => {
        setBelonging(option.value);
    };

    const submit = async () => {
        setLoader();
        const typeStage = all.find(c => c.uid === belonging)?.type as typesCourse;
        try {
            // @ts-ignore
            const stage: IStage = {
                name: {
                    ru: nameRU,
                    en: nameEN
                }, description: {
                    ru: descriptionRU,
                    en: descriptionEN
                },
                belonging: belonging,
                serialNumber: Number(serialNumber),
                type: typeStage,
                price: price,
                isPurchase: isPurchased
            };
            const stages =
                currentStage.uid !== null
                    ? await updateStage(stage, currentStage.uid as string)
                    : await createStage(stage);
            dispatch(setStages(stages));
            handleBack();
        } catch (e) {
        }
    };


    return (
        <>
            <div className={styles.stage__row}>
                <div className={styles.stage__colM}>
                    <div className={styles.stage__box}>
                        <Input
                            label={'Название ru'}
                            defaultValue={nameRU}
                            handleChangeValue={setNameRU}
                            type={'text'}/>
                    </div>
                    <div className={styles.stage__box}>
                        <Input
                            label={'Название en'}
                            defaultValue={nameEN}
                            handleChangeValue={setNameEN}
                            type={'text'}/>
                    </div>
                </div>
                <div className={styles.stage__colM}>
                    <div className={styles.stage__box}>
                        <Input
                            label={'Номер ступени'}
                            defaultValue={serialNumber}
                            handleChangeValue={setSerialNumber}
                            type={'number'}/>
                    </div>
                    <div className={styles.stage__box}>
                        <label className={styles.stage__label}>Принадлежность к курсу</label>
                        <CustomSelect
                            defaultValue={currentStage.belonging}
                            options={courseType}
                            handler={handleSelectType}
                            className={''}/>
                    </div>
                </div>
            </div>

            <div className={styles.stage__box}>
                <label className={styles.stage__label}>Описание ru</label>
                <RichText handleChange={setDescriptionRU} data={descriptionRU.toString()} height={150}/>
            </div>
            <div className={styles.stage__box}>
                <label className={styles.stage__label}>Описание en</label>
                <RichText handleChange={setDescriptionEN} data={descriptionEN.toString()} height={150}/>
            </div>
            
            <div className={styles.course__box}>
                <Input
                    label={'Цена, RUB'}
                    defaultValue={price}
                    handleChangeValue={(value) => setPrice(Number(value))}
                    type={'number'}/>
            </div>
            <div className={styles.course__box}>
                <CheckboxField isChecked={isPurchased} handleChecked={() => setPurchased(!isPurchased)} label={'доступен для оплаты'}/>
            </div>

            <CustomButton
                variant={ButtonVariables.contained}
                handleClick={submit}>{
                currentStage.uid !== null
                    ? 'Сохранить изменения'
                    : 'Сохранить'}
            </CustomButton>
        </>
    );
};
