import {ref, uploadString, getDownloadURL, deleteObject} from 'firebase/storage';
import {addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import {IPost} from '../../../models/Post';
import {firestore, storage} from '../../../firebase';
import {IShastr} from '../../../models/Shastr';

async function uploadFile(base64: string, postUid: string) {
    try {
        const storageRef = ref(storage, `gs://djsya-web-app.appspot.com/${postUid}`);
        await uploadString(storageRef, base64, 'data_url');
    } catch (e) {
    }
};


export const getShastrs = async () => {
    let posts: IPost[] = [];
    const docRef = collection(firestore, 'shastrs');
    const docs = await getDocs(docRef);
    docs.forEach((doc) => {
        const data = doc.data() as IShastr;
        const uid = doc.id;
        posts.push({...data, uid});
    });

    for await (const post of posts) {
        const urlImage = await getDownloadURL(ref(storage, post.uid)).then(url => url.toString()).catch(e => {
            return '';
        });
        posts = posts.map(p => p.uid === post.uid ? {...p, urlImage} : p);
    }

    const data = posts.sort(function (a, b) {
        const i = a.create_as as number;
        const n = b.create_as as number;
        return n - i;
    });
    return data;
};

export const createShastr = async ({post, base64}: { post: IShastr, base64: string }) => {
    try {
        const postRef = await addDoc(collection(firestore, 'shastrs'), {...post, create_as: Date.now()});
        await uploadFile(base64, postRef.id);
    } catch (e) {
    }
    return await getShastrs();
};

export const updateShastr = async ({post, base64}: { post: IPost, base64: string }) => {
    try {
        await uploadFile(base64, post.uid as string);
        const washingtonRef = await doc(firestore, `shastrs/${post.uid}`);
        await updateDoc(washingtonRef, {
            ...post
        });
        return await getShastrs();
    } catch (e) {
    }
};

export const deleteShastr = async (uid: string) => {
    const desertRef = ref(storage, uid);
    await deleteObject(desertRef);
    await deleteDoc(doc(firestore, 'shastrs', uid));
    return await getShastrs();
};

export const getShastrCategoryById = async (uid: string) => {
    let posts:IPost[] = [];
    const q = query(collection(firestore, 'shastrs'), where('category', '==', uid));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
        const post = {...doc.data(), uid : doc.id} as IPost;
        posts.push(post);
    });

    for await (const post of posts) {
        const urlImage = await getDownloadURL(ref(storage, post.uid)).then(url => url.toString()).catch(e => {
            return '';
        });
        posts = posts.map(p => p.uid === post.uid ? {...p, urlImage} : p);
    }

    return posts.sort(function (a, b) {
        const i = a.create_as as number;
        const n = b.create_as as number;
        return n - i;
    });
};
