//localisation
import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import I18NextHttpBackend from 'i18next-http-backend';

i18next
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .use(I18NextHttpBackend)
    .init({
        supportedLngs: ['ru', 'en'],
        fallbackLng: 'ru',
        lng: 'ru',
        detection: {
            order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18next;
