import React, {useCallback, useMemo} from 'react';
import {withReact, Slate, Editable} from 'slate-react';
import {
    Editor,
    createEditor,
    BaseEditor,
} from 'slate';
import isHotkey from 'is-hotkey';
import {withHistory} from 'slate-history';
import {ITemplateNaneProps} from './types';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};

export const ReadOnlyText = ({initialValue}: ITemplateNaneProps) => {
    const renderElement = useCallback((props: JSX.IntrinsicAttributes & { attributes: any; children: any; element: any }) =>
        <Element {...props} />, []);
    const renderLeaf = useCallback((props: JSX.IntrinsicAttributes & { attributes: any; children: any; leaf: any }) =>
        <Leaf {...props} />, []);

    const editor = useMemo(() => withHistory(withReact(createEditor())), []);


    if(initialValue.length === 0) return <></>;
    return (
        <Slate editor={editor} value={JSON.parse(initialValue)} >
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                readOnly
                spellCheck

                onKeyDown={event => {
                    for (const hotkey in HOTKEYS) {
                        if (isHotkey(hotkey, event as any)) {
                            event.preventDefault();
                            // @ts-ignore
                            const mark = HOTKEYS[hotkey];
                            toggleMark(editor, mark);
                        }
                    }
                }}
            />
        </Slate>
    );
};


const toggleMark = (editor: BaseEditor, format: string) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
        Editor.removeMark(editor, format);
    } else {
        Editor.addMark(editor, format, true);
    }
};


const isMarkActive = (editor: BaseEditor, format: string) => {
    const marks = Editor.marks(editor);
    // @ts-ignore
    return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }: any) => {
    const style = { textAlign: element.align };
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            );
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            );
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            );
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            );
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            );
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            );
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            );
    }
};

const Leaf = ({ attributes, children, leaf }:any) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>;
    }

    if (leaf.code) {
        children = <code>{children}</code>;
    }

    if (leaf.italic) {
        children = <em>{children}</em>;
    }

    if (leaf.underline) {
        children = <u>{children}</u>;
    }

    return <span {...attributes}>{children}</span>;
};
