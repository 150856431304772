import React, {useCallback, useEffect, useState} from 'react';
import {Button} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {PanelLayout} from '../../layouts';
import {AppLoader} from '../../components/AppLoader';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ConfirmationModal} from '../../components/ConfirmationModal';
import {IPostCategory} from '../../models/Post';
import {getPosts} from '../Post/api';
import {setPosts} from '../Post/Post.slice';
import {Form, List} from './components';
import styles from './Post.module.scss';
import {deletePostCategory, getPostsCategory} from './api';
import {clearCurrentPostCategory, setPostCategory, setPostsCategory} from './PostCategory.slice';

export const PostCategory = () => {
    const dispatch = useAppDispatch();
    const {category} = useAppSelector(state => state.admin.postsCategory);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [currentUid, setCurrentUid] = useState<string>('');


    useEffect(() => {
        getPostsCategory().then(posts => dispatch(setPostsCategory(posts)));
    }, []);

    const handleUpdate = useCallback(async (uid: string) => {
        setLoading(true);
        const current = category.find(i => i.uid === uid) as IPostCategory;
        dispatch(setPostCategory(current));
        setShowForm(true);
        setLoading(false);
    }, [dispatch, category]);

    const handleDelete = useCallback(async (uid: string) => {
        setLoading(true);
        const posts = await deletePostCategory(uid);
        dispatch(setPostsCategory(posts));
        getPosts().then(posts => dispatch(setPosts(posts)));
        setLoading(false);
    }, [dispatch]);

    const handleBack = useCallback(() => {
        dispatch(clearCurrentPostCategory());
        setShowForm(false);
    }, [dispatch]);

    const handleCreated = useCallback(async () => {
        handleBack();
        setLoading(false);
    }, [dispatch, handleBack]);
    return (
        <PanelLayout>
            <div className={styles.post}>
                <AppLoader loading={loading}/>
                <div className={styles.post__header}>
                    <h1 className={styles.post__title}>Категории записей</h1>
                    {!showForm && <Button
                        variant={'contained'}
                        className={styles.course__createBtn}
                        onClick={() => setShowForm(true)}
                    >Создать категорию</Button>}
                </div>
                {
                    showForm
                        ? (<div>
                            <Button variant={'text'} startIcon={<ChevronLeftIcon />} onClick={handleBack}>Назад</Button>
                            <Form handleBack={handleCreated} setLoader={() => setLoading(true)}/>
                        </div>)
                        : (
                            <List items={category} handleUpdate={handleUpdate} handleDelete={(uid) => {
                                setOpenConfirmation(true);
                                setCurrentUid(uid);
                            }} />
                        )
                }
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    text={'При удалении категории текстов, удалятся все принадлежавшие к ней тексты'}
                    handleDelete={() => handleDelete(currentUid)} />
            </div>
        </PanelLayout>
    );
};

