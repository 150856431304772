import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Card from '@mui/material/Card';
import {Avatar, Button, CardActions, CardHeader} from '@mui/material';
import {red} from '@mui/material/colors';
import _ from 'lodash';
import classNames from 'classnames';
import {ITemporary, IUser, userDefault} from '../../../models/User';
import styles from '../Users.module.scss';
import {CheckboxField, MultipleSelect} from '../../../fields';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {setUsersByEmail} from '../Users.slice';
import {ICourse} from '../../../models/Course';

export interface IData {
    isPaid: boolean;
    temporary: ITemporary[];
    //courses: string[];
    mainStages: string[];
    role: string;
}

interface ICardUserProps {
    user: IUser;
    handleSave: (data: IData) => void;
}

export const CardUser = ({user, handleSave}: ICardUserProps) => {
    const dispatch = useAppDispatch();
    const {all} = useAppSelector(state => state.admin.course);
    const {stage} = useAppSelector(state => state.admin);
    const [isPaid, setPaid] = useState<boolean>(user.isPaid);
    const [temporary, setTemporary] = useState<string[]>([]);
    const [temporarySelected, setTemporarySelected] = useState<ITemporary[]>(user.temporary);
    const [stagesIsMain, setStagesIsMain] = useState<string[]>([]);


    const abr = user?.displayName?.charAt(0);

    useEffect(() => {
        if (user?.temporary?.length > 0) {
            const items = temporaryCourses.filter(i => _.includes(Object.values(user.temporary).map(i => i.id), i.value)).map(i => i.label);
            setTemporary(items);
        }
        if (user?.mainStages?.length > 0) {
            const items = mainStages.filter(i => _.includes(Object.values(user.mainStages).map(i => i), i.value)).map(i => i.label);
            setStagesIsMain(items);
        }

        return () => {
            setTemporary([]);
            setStagesIsMain([]);
        };
    }, [user]);

    const temporaryCourses = all.filter(i => i.type === 'temporary').map(i => {
        return {
            value: i.uid ? i.uid.toString() : '',
            label: i.name.ru.toString()
        };
    });

    const main = all.find(i => i.isMain) as ICourse;
    const mainStages = stage.all.filter(stage => stage.belonging === main.uid).map(s => {
        return {
            value: s.uid ? s.uid.toString() : '',
            label: s.name.ru.toString()
        };
    });

    const handleSelect = useCallback((items: string[]) => {
        setTemporary(items);
    }, []);
    const handleSelectMainStages = useCallback((items: string[]) => {
        setStagesIsMain(items);
    }, []);

    const addTemporary = (items: string[]) => {
        const newTC:ITemporary[] = [];
            const selectedIds = temporaryCourses.filter(item => _.includes(items, item.label)).map(i => i.value);
            const currentTemporary = user?.temporary?.map(i => i.id) ?? [];
            for (const id of selectedIds) {
                if (!currentTemporary.includes(id)) {
                    const ts = Date.now();
                    newTC.push({id, timeStump: ts});
                }
            }
        const remainder = user?.temporary?.filter(i => _.includes(selectedIds, i.id)) ?? [];
            setTemporarySelected([...remainder, ...newTC]);
    };

    const save = async () => {
        const mainStagesUids = mainStages.filter(i => _.includes(stagesIsMain, i.label)).map(i => i.value);

        handleSave({
            isPaid: isPaid,
            temporary: temporarySelected,
            mainStages: mainStagesUids,
            role: 'USER'
        });
        dispatch(setUsersByEmail(userDefault));
    };

    const cancel = () => {
        dispatch(setUsersByEmail(userDefault));
    };

    const renderSelectMainCourse = useMemo(() => {
        if(isPaid){
            return (
                <div className={classNames(styles.box, styles.box__column)}>
                    <MultipleSelect
                        placeholder={'Добавить уровни главного курса'}
                        options={mainStages}
                        value={stagesIsMain}
                        handleChange={handleSelectMainStages}/>
                </div>
            );
        } else {
            <></>;
        }
    }, [isPaid, user, stagesIsMain, mainStages]);

    return (
        <Card sx={{width: '100%'}}>
            <CardHeader
                avatar={
                    <Avatar sx={{bgcolor: red[500]}} aria-label="recipe">
                        {abr}
                    </Avatar>
                }
                title={user.displayName}
                subheader={user.email}
            />
            <CardActions>
                <CheckboxField isChecked={isPaid} handleChecked={() => setPaid(!isPaid)}/>
                <Button size="small" onClick={() => setPaid(!isPaid)}>Пометить как оплачено</Button>
            </CardActions>
            <div className={classNames(styles.box, styles.box__column)}>
                <MultipleSelect
                    placeholder={'Добавить временные курсы'}
                    options={temporaryCourses}
                    value={temporary}
                    handleChange={handleSelect}
                    addTemporary={addTemporary}
                />
            </div>
            {renderSelectMainCourse}
            <CardActions>
                <>
                    <Button variant="outlined" sx={{
                        marginLeft: 'auto'
                    }} className={styles.button} onClick={cancel}>Закрыть окно</Button>
                    <Button variant="contained" sx={{
                        marginLeft: 'auto'
                    }} className={styles.button} onClick={save}>Сохранить</Button>
                </>
            </CardActions>
        </Card>
    );
};
