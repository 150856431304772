import {createSlice} from '@reduxjs/toolkit';
import {userDefault} from '../../models/User';
import {IUsersState} from './types';

const initialState:IUsersState = {
    users: [],
    currentUser: userDefault
};

const UsersSlice = createSlice({
    name: 'users',
    initialState,
    reducers:{
        setUsersByEmail: (state, {payload}) => {
            state.currentUser = payload;
        }
    }
});

export const {setUsersByEmail} = UsersSlice.actions;

export default UsersSlice.reducer;
