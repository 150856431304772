import {IPost} from '../../models/Post';


export const defaultPost:IPost = {
    name: {ru: '', en: ''},
    description:{ru: '', en: ''},
    category: ''
};

export interface IPostState {
    posts: IPost[],
    currentPost : IPost
}
