import React, { useState} from 'react';
import {CustomButton, Input} from '../../../fields';
import {RichText} from '../../../components/RichText';
import {ButtonVariables} from '../../../fields/CustomButton';
import {useAppDispatch} from '../../../hooks';
import {value} from '../../../fields/Input';
import styles from '../ConsultationAdmin.module.scss';
import {createConsultation, updateConsultation} from '../api';
import {IConsultation} from '../../../models/Consultation';
import {setConsultation, setLoading} from '../ConsultationAdmin.slice';

export const Form = ({item}:{item:IConsultation}) => {
    const dispatch = useAppDispatch();

    const [nameRU, setNameRU] = useState<value>(item.name.ru);
    const [nameEN, setNameEN] = useState<value>(item.name.en);

    const [descriptionRU, setDescriptionRU] = useState<string>(item.description.ru);
    const [descriptionEN, setDescriptionEN] = useState<string>(item.description.en);


    const submit = async () => {
       dispatch(setLoading(true));
        try {
            const data:IConsultation = {
                uid: item.uid,
                name: {
                    ru: nameRU, en: nameEN
                },
                description: {
                    ru: descriptionRU, en: descriptionEN
                }
            };
            if(item.uid === undefined) {
                const consultation = await createConsultation({consultation: data});
                dispatch(setConsultation(consultation));
            } else {
                const result = await updateConsultation({consultation: data});
                dispatch(setConsultation(result));
            }
        } catch (e) {
            dispatch(setLoading(false));
        }
    };
    return (
        <>
            <div className={styles.post__row}>
                <div className={styles.post__colM}>
                    <div className={styles.post__box}>
                        <Input
                            label={'Название ru'}
                            defaultValue={nameRU}
                            handleChangeValue={setNameRU}
                            type={'text'}/>
                    </div>
                    <div className={styles.post__box}>
                        <Input
                            label={'Название en'}
                            defaultValue={nameEN}
                            handleChangeValue={setNameEN}
                            type={'text'}/>
                    </div>
                </div>
            </div>
            <div className={styles.post__box}>
                <label className={styles.post__label}>Описание ru</label>
                <RichText handleChange={setDescriptionRU} data={descriptionRU.toString()} height={150}/>
            </div>
            <div className={styles.post__box}>
                <label className={styles.post__label}>Описание en</label>
                <RichText handleChange={setDescriptionEN} data={descriptionEN.toString()} height={150}/>
            </div>
            <CustomButton
                style={{marginTop: '20px'}}
                variant={ButtonVariables.contained}
                handleClick={submit}>Сохранить изменения
            </CustomButton>
        </>
    );
};

export default Form;
