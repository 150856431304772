import React, {useCallback, useState} from 'react';
import {Button} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {PanelLayout} from '../../layouts';
import {AppLoader} from '../../components/AppLoader';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ConfirmationModal} from '../../components/ConfirmationModal';
import {IShastr} from '../../models/Shastr';
import styles from './Shastr.module.scss';
import {Form, List} from './components';
import { clearCurrentShaster, setShaster, setShasters} from './Shastr.slice';
import {deleteShastr} from './api';

export const Shastr = () => {
    const dispatch = useAppDispatch();
    const {shastrs} = useAppSelector(state => state.admin.shastrs);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [currentUid, setCurrentUid] = useState<string>('');

    // useEffect(() => {
    //     setLoading(true);
    //     getShastrs().then(posts => {
    //         dispatch(setShasters(posts));
    //         setLoading(false);
    //     } );
    // }, [dispatch]);

    const handleUpdate = useCallback(async (uid: string) => {
        setLoading(true);
        const current = shastrs.find(i => i.uid === uid) as IShastr;
        dispatch(setShaster(current));
        setShowForm(true);
        setLoading(false);
    }, [dispatch, shastrs]);

    const handleDelete = useCallback(async (uid: string) => {
        setLoading(true);
        const posts = await deleteShastr(uid);
        dispatch(setShasters(posts));
        setLoading(false);
    }, [dispatch]);

    const handleBack = useCallback(() => {
        dispatch(clearCurrentShaster());
        setShowForm(false);
    }, [dispatch]);

    const handleCreated = useCallback(async () => {
        handleBack();
        setLoading(false);
    }, [dispatch, handleBack]);
    return (
        <PanelLayout>
            <div className={styles.post}>
                <AppLoader loading={loading}/>
                <div className={styles.post__header}>
                    <h1 className={styles.post__title}>Шастры</h1>
                    {!showForm && <Button
                        variant={'contained'}
                        className={styles.course__createBtn}
                        onClick={() => setShowForm(true)}
                    >Создать запись</Button>}
                </div>
                {
                    showForm
                        ? (<div>
                            <Button variant={'text'} startIcon={<ChevronLeftIcon />} onClick={handleBack}>Назад</Button>
                            <Form handleBack={handleCreated} setLoader={() => setLoading(true)}/>
                        </div>)
                        : (
                            <List items={shastrs} handleUpdate={handleUpdate} handleDelete={(uid) => {
                                setOpenConfirmation(true);
                                setCurrentUid(uid);
                            }} />
                        )
                }
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    text={'При удалении категории шастр, удалятся все принадлежавшие к ней шастры'}
                    handleDelete={() => handleDelete(currentUid)} />
            </div>
        </PanelLayout>
    );
};

