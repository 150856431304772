import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Main} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ReadOnlyText} from '../../components/ReadOnlyText';
import {AppLoader} from '../../components/AppLoader';
import {Title} from '../../components/Title';
import {Pagination} from '../../components/Pagination';
import styles from './ShastrPage.module.scss';
import {loadingPost} from './ShastrPage.slice';

export const ShastrPage = () => {
    const dispatch = useAppDispatch();
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru';
    const {currentShastr, loader} = useAppSelector(state => state.front.shastrs);

    useEffect(() => {
         dispatch(loadingPost(false));
    }, [loader] );


    return (
        <Main>
            {loader
                ? <AppLoader loading={loader}/>
                : (
                    <div className={styles.singlePost}>
                        <div className={classNames(styles.singlePost__box, styles.singlePost__boxMbottom)}>
                            <Title title={isRu ? currentShastr.name.ru : currentShastr.name.en} />
                        </div>
                        <div className={styles.singlePost__image}>
                            <img src={currentShastr.urlImage} alt={currentShastr.name.en as string}/>
                        </div>
                        <Pagination type={'shastr'} uid={currentShastr.uid as string} />

                            <div className={i18n.language === 'ru' ? styles.singlePost__box : styles.singlePost__boxHide}>
                                <ReadOnlyText
                                    initialValue={currentShastr.description.ru}/></div>

                            <div className={i18n.language !== 'ru' ? styles.singlePost__box : styles.singlePost__boxHide}>
                                <ReadOnlyText
                                    initialValue={currentShastr.description.en}/>
                            </div>

                    </div>
                )}
        </Main>
    );
};

