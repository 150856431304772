import {useEffect, useState} from 'react';
import {getDownloadURL, ref} from 'firebase/storage';
import {useTranslation} from 'react-i18next';
import styles from '../TemporaryCourse.module.scss';
import {storage} from '../../../firebase';

export const Banner = ({uid}: { uid: string | null | undefined }) => {
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru';
    const [source, setSource] = useState<string | null>(null);
    const [sourceEn, setSourceEn] = useState<string | null>(null);

    useEffect(() => {
        (async () => {
           if(uid) {
               await getDownloadURL(ref(storage, uid as any))
                   .then(urlImage => setSource(urlImage))
                   .catch(err => setSource(null));
               await getDownloadURL(ref(storage, `en-${uid}` as any))
                   .then(urlImage => setSourceEn(urlImage))
                   .catch(err => setSourceEn(null));
           }
        })();
    }, [uid]);

    if (source === null) {
        return <></>;
    }
    return (
        <article className={styles.banner} >
             <img src={`${isRu ? source : sourceEn}`} alt="" className={styles.banner__cover} />
        </article>
    );
};
