export interface MessagesIndex {
    [index: string]: string;
}

export const errorsTypes = {
    'auth/user-not-found' : 'Нет существующей записи пользователя, соответствующей предоставленному идентификатору.',
    'auth/weak-password' : 'Пароль должен быть не менее 6 символов',
    'auth/invalid-email' : 'Email указан неверно',
    'auth/internal-error' : 'Неправильный пароль',
    'auth/email-already-in-use': 'Электронная почта уже используется'
} as MessagesIndex;

export const errorsTypesEn = {
    'auth/user-not-found' : 'There is no existing user record corresponding to the provided identifier.',
    'auth/weak-password' : 'Password should be at least 6 characters',
    'auth/invalid-email' : 'Invalid email',
    'auth/internal-error' : 'Wrong password',
    'auth/email-already-in-use': 'Email already in use'
} as MessagesIndex;


