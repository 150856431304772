import React from 'react';
import {Button, ButtonProps} from '@mui/material';
import {styled} from '@mui/material/styles';
import {blueGrey, grey} from '@mui/material/colors';

export enum ButtonVariables {
    contained = 'contained',
    outlined = 'outlined',
    text = 'text',
}

interface IButton extends ButtonProps{
    handleClick: () => void;
    children: string;
    variant: ButtonVariables
}

const ColorButton = styled(Button)<ButtonProps>(({theme}) => ({
    color: '#ffffff',
    backgroundColor: grey['800'],
    '&:hover': {
        backgroundColor: blueGrey['A700'],
    },
}));

export const CustomButton = ({handleClick, children, variant, ...props}: IButton) => {
    return (
        <ColorButton
            {...props}
            variant={variant}
            onClick={handleClick}
        >
            {children}
        </ColorButton>
    );
};
