import React, {ReactNode} from 'react';
import {SwitcherLang} from '../../components/SwitcherLang';
import {useProgressiveImage} from '../../hooks';
import coverMain from '../../assets/images/Background_Home.png';
import optimizeCoverMain from '../../assets/images/Background_Home.png';
import styles from './AuthLauoyt.module.scss';

export const AuthLayout = ({children}:{children: ReactNode}) => {
    const {sourceLoaded} = useProgressiveImage(coverMain);
    return (
        <div className={styles.wrapper}>
            <div className={styles.wrapperBg} style={{
                backgroundImage: `url(${sourceLoaded || optimizeCoverMain})`
            }} />
            <div className={styles.header}>
                <SwitcherLang />
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    );
};
