import React from 'react';
import {ColorRing} from 'react-loader-spinner';
import {ITemplateNaneProps} from './types';
import styles from './AppLoader.module.scss';

export const AppLoader = ({loading}:ITemplateNaneProps) => {
    if(!loading) {
        return <></>;
    }
    return (
        <div className={styles.appLoader}>
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#2c2c2c', '#2c2c2c', '#2c2c2c', '#2c2c2c', '#2c2c2c']}
            />
        </div>
    );
};

