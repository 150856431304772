import React, {useState} from 'react';
import styles from '../Shastr.module.scss';
import {CustomButton, Input} from '../../../fields';
import {ButtonVariables} from '../../../fields/CustomButton';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {value} from '../../../fields/Input';
import {Reader} from '../../../components/Reader';
import {createShastrCategory, updateShastrCategory} from '../api';
import {IShastrCategory} from '../../../models/Shastr';
import {setShastrCategorys} from '../ShastrCategory.slice';

interface IFormProps {
    handleBack: () => void;
    setLoader: () => void;
}

export const Form = ({handleBack, setLoader}: IFormProps) => {
    const dispatch = useAppDispatch();
    const {currentShastrCategory} = useAppSelector(state => state.admin.shastrsCategory);

    const [serialNumber, setSerialNumber] = useState<value>(currentShastrCategory?.serialNumber);
    const [nameRU, setNameRU] = useState<value>(currentShastrCategory.name.ru);
    const [nameEN, setNameEN] = useState<value>(currentShastrCategory.name.en);
    const [base64, setBase64] = useState<any>(currentShastrCategory.urlImage);

    const submit = async () => {
        setLoader();
        try {
            const shastrCategory: IShastrCategory = {
                name: {
                    ru: nameRU,
                    en: nameEN
                },
                serialNumber: Number(serialNumber)
            };

            const posts = currentShastrCategory.uid
                ? await updateShastrCategory({shastrCategory: {...shastrCategory, uid: currentShastrCategory.uid}, base64})
                : await createShastrCategory({shastrCategory, base64});
            dispatch(setShastrCategorys(posts));
            handleBack();
        } catch (e) {

        }
    };
    return (
        <>
            <div className={styles.post__row}>
                <div className={styles.post__colM}>
                    <div className={styles.post__box}>
                        <Input
                            label={'Название ru'}
                            defaultValue={nameRU}
                            handleChangeValue={setNameRU}
                            type={'text'}/>
                    </div>
                    <div className={styles.post__box}>
                        <Input
                            label={'Название en'}
                            defaultValue={nameEN}
                            handleChangeValue={setNameEN}
                            type={'text'}/>
                    </div>
                </div>
                <div className={styles.post__colM}>
                    <div className={styles.post__box}>
                        <Input
                            label={'Номер категории'}
                            defaultValue={serialNumber}
                            handleChangeValue={setSerialNumber}
                            type={'number'}/>
                    </div>
                </div>
            </div>
            <div className={styles.post__row}>
                <div className={styles.post__colM}>
                    <Reader handleReader={setBase64} buttonText={'Загрузите фото'} defaultBase64={currentShastrCategory.urlImage} />
                </div>
            </div>
            <CustomButton
                style={{marginTop: '20px'}}
                variant={ButtonVariables.contained}
                handleClick={submit}>Сохранить изменения
            </CustomButton>
        </>
    );
};

export default Form;
