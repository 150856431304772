import {createSlice} from '@reduxjs/toolkit';
import {defaultCourse} from '../../models/Course';
import {ICoursePageState} from './types';

const initialState: ICoursePageState = {
    course: defaultCourse,
    stages: [],
    titleStages: []
};

const CoursePageSlice = createSlice({
    name: 'coursePage',
    initialState,
    reducers: {
        setCourseMain: (state, {payload}) => {
            state.course = payload.course;
            state.stages = payload.stages;

        },
        setNameStates: (state, {payload}) => {
           state.titleStages = payload.titleStages;
        }
    }
});

export const {setCourseMain, setNameStates} = CoursePageSlice.actions;

export default CoursePageSlice.reducer;
