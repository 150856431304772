import React from 'react';
import {useTranslation} from 'react-i18next';
import {Main} from '../../layouts';
import {Title} from '../../components/Title';
import styles from './styles.module.scss';

export const Policy = () => {
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru';
    return (
        <Main>
            <div className={styles.page}>
                <Title title={isRu ? 'Политика конфиденциальности' : 'Privacy policy'}/>

                {
                    isRu
                        ? (
                            <>
                                <p className={styles.subtitle}>1. Общие положения</p>
                                <p className={styles.text}>Настоящая политика обработки персональных данных составлена в
                                    соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных
                                    данных» (далее — Закон о персональных данных) и определяет порядок обработки
                                    персональных данных и меры по обеспечению безопасности персональных данных,
                                    предпринимаемые название оператора (далее — Оператор).</p>
                                <p className={styles.text}>1.1. Оператор ставит своей важнейшей целью и условием
                                    осуществления своей деятельности соблюдение прав и свобод человека и гражданина при
                                    обработке его персональных данных, в том числе защиты прав на неприкосновенность частной
                                    жизни, личную и семейную тайну.</p>
                                <p className={styles.text}>1.2. Настоящая политика Оператора в отношении обработки
                                    персональных данных (далее — Политика) применяется ко всей информации, которую Оператор
                                    может получить о посетителях веб-сайта httpsː//thismywebsite·com</p>
                                <p className={styles.subtitle}>2. Основные понятия, используемые в Политике</p>
                                <p className={styles.text}>2.1. Автоматизированная обработка персональных данных — обработка
                                    персональных данных с помощью средств вычислительной техники.</p>
                                <p className={styles.text}>2.2. Блокирование персональных данных — временное прекращение
                                    обработки персональных данных (за исключением случаев, если обработка необходима для
                                    уточнения персональных данных).</p>
                                <p className={styles.text}>2.3. Веб-сайт — совокупность графических и информационных
                                    материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети
                                    интернет по сетевому адресу httpsː//thismywebsite·com.</p>
                                <p className={styles.text}>2.4. Информационная система персональных данных — совокупность
                                    содержащихся в базах данных персональных данных и обеспечивающих их обработку
                                    информационных технологий и технических средств.</p>
                                <p className={styles.text}>2.5. Обезличивание персональных данных — действия, в результате
                                    которых невозможно определить без использования дополнительной информации принадлежность
                                    персональных данных конкретному Пользователю или иному субъекту персональных данных.</p>
                                <p className={styles.text}>2.6. Обработка персональных данных — любое действие (операция)
                                    или совокупность действий (операций), совершаемых с использованием средств автоматизации
                                    или без использования таких средств с персональными данными, включая сбор, запись,
                                    систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
                                    использование, передачу (распространение, предоставление, доступ), обезличивание,
                                    блокирование, удаление, уничтожение персональных данных.</p>
                                <p className={styles.text}>2.7. Оператор — государственный орган, муниципальный орган,
                                    юридическое или физическое лицо, самостоятельно или совместно с другими лицами
                                    организующие и/или осуществляющие обработку персональных данных, а также определяющие
                                    цели обработки персональных данных, состав персональных данных, подлежащих обработке,
                                    действия (операции), совершаемые с персональными данными.</p>
                                <p className={styles.text}>2.8. Персональные данные — любая информация, относящаяся прямо
                                    или косвенно к определенному или определяемому Пользователю веб-сайта
                                    httpsː//thismywebsite·com.</p>
                                <p className={styles.text}>2.9. Персональные данные, разрешенные субъектом персональных
                                    данных для распространения, — персональные данные, доступ неограниченного круга лиц к
                                    которым предоставлен субъектом персональных данных путем дачи согласия на обработку
                                    персональных данных, разрешенных субъектом персональных данных для распространения в
                                    порядке, предусмотренном Законом о персональных данных (далее — персональные данные,
                                    разрешенные для распространения).</p>
                                <p className={styles.text}>2.10. Пользователь — любой посетитель веб-сайта
                                    httpsː//thismywebsite·com.</p>
                                <p className={styles.text}>2.11. Предоставление персональных данных — действия, направленные
                                    на раскрытие персональных данных определенному лицу или определенному кругу лиц.</p>
                                <p className={styles.text}>2.12. Распространение персональных данных — любые действия,
                                    направленные на раскрытие персональных данных неопределенному кругу лиц (передача
                                    персональных данных) или на ознакомление с персональными данными неограниченного круга
                                    лиц, в том числе обнародование персональных данных в средствах массовой информации,
                                    размещение в информационно-телекоммуникационных сетях или предоставление доступа к
                                    персональным данным каким-либо иным способом.</p>
                                <p className={styles.text}>2.13. Трансграничная передача персональных данных — передача
                                    персональных данных на территорию иностранного государства органу власти иностранного
                                    государства, иностранному физическому или иностранному юридическому лицу.</p>
                                <p className={styles.text}>2.14. Уничтожение персональных данных — любые действия, в
                                    результате которых персональные данные уничтожаются безвозвратно с невозможностью
                                    дальнейшего восстановления содержания персональных данных в информационной системе
                                    персональных данных и/или уничтожаются материальные носители персональных данных</p>
                                <p className={styles.subtitle}>3. Основные права и обязанности Оператора</p>
                                <p className={styles.text}>3.1. Оператор имеет право:</p>
                                <p className={styles.text}>— получать от субъекта персональных данных достоверные информацию
                                    и/или документы, содержащие персональные данные;</p>
                                <p className={styles.text}>— в случае отзыва субъектом персональных данных согласия на
                                    обработку персональных данных, а также, направления обращения с требованием о
                                    прекращении обработки персональных данных, Оператор вправе продолжить обработку
                                    персональных данных без согласия субъекта персональных данных при наличии оснований,
                                    указанных в Законе о персональных данных;</p>
                                <p className={styles.text}>— самостоятельно определять состав и перечень мер, необходимых и
                                    достаточных для обеспечения выполнения обязанностей, предусмотренных Законом о
                                    персональных данных и принятыми в соответствии с ним нормативными правовыми актами, если
                                    иное не предусмотрено Законом о персональных данных или другими федеральными
                                    законами.</p>
                                <p className={styles.text}>3.2. Оператор обязан:</p>
                                <p className={styles.text}>— предоставлять субъекту персональных данных по его просьбе
                                    информацию, касающуюся обработки его персональных данных;</p>
                                <p className={styles.text}>— организовывать обработку персональных данных в порядке,
                                    установленном действующим законодательством РФ;</p>
                                <p className={styles.text}>— отвечать на обращения и запросы субъектов персональных данных и
                                    их законных представителей в соответствии с требованиями Закона о персональных
                                    данных;</p>
                                <p className={styles.text}>— сообщать в уполномоченный орган по защите прав субъектов
                                    персональных данных по запросу этого органа необходимую информацию в течение 10 дней с
                                    даты получения такого запроса;</p>
                                <p className={styles.text}>— публиковать или иным образом обеспечивать неограниченный доступ
                                    к настоящей Политике в отношении обработки персональных данных;</p>
                                <p className={styles.text}>— принимать правовые, организационные и технические меры для
                                    защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения,
                                    изменения, блокирования, копирования, предоставления, распространения персональных
                                    данных, а также от иных неправомерных действий в отношении персональных данных;</p>
                                <p className={styles.text}>— прекратить передачу (распространение, предоставление, доступ)
                                    персональных данных, прекратить обработку и уничтожить персональные данные в порядке и
                                    случаях, предусмотренных Законом о персональных данных;</p>
                                <p className={styles.text}>— исполнять иные обязанности, предусмотренные Законом о
                                    персональных данных</p>

                                <p className={styles.subtitle}>4. Основные права и обязанности субъектов персональных
                                    данных</p>
                                <p className={styles.text}>4.1. Субъекты персональных данных имеют право:</p>
                                <p className={styles.text}>— получать информацию, касающуюся обработки его персональных
                                    данных, за исключением случаев, предусмотренных федеральными законами. Сведения
                                    предоставляются субъекту персональных данных Оператором в доступной форме, и в них не
                                    должны содержаться персональные данные, относящиеся к другим субъектам персональных
                                    данных, за исключением случаев, когда имеются законные основания для раскрытия таких
                                    персональных данных. Перечень информации и порядок ее получения установлен Законом о
                                    персональных данных;</p>
                                <p className={styles.text}>— требовать от оператора уточнения его персональных данных, их
                                    блокирования или уничтожения в случае, если персональные данные являются неполными,
                                    устаревшими, неточными, незаконно полученными или не являются необходимыми для
                                    заявленной цели обработки, а также принимать предусмотренные законом меры по защите
                                    своих прав;</p>
                                <p className={styles.text}>— выдвигать условие предварительного согласия при обработке
                                    персональных данных в целях продвижения на рынке товаров, работ и услуг;</p>
                                <p className={styles.text}>— на отзыв согласия на обработку персональных данных, а также, на
                                    направление требования о прекращении обработки персональных данных;</p>
                                <p className={styles.text}>— обжаловать в уполномоченный орган по защите прав субъектов
                                    персональных данных или в судебном порядке неправомерные действия или бездействие
                                    Оператора при обработке его персональных данных;</p>
                                <p className={styles.text}>— на осуществление иных прав, предусмотренных законодательством
                                    РФ.</p>
                                <p className={styles.text}>4.2. Субъекты персональных данных обязаны:</p>
                                <p className={styles.text}>— предоставлять Оператору достоверные данные о себе;</p>
                                <p className={styles.text}>— сообщать Оператору об уточнении (обновлении, изменении) своих
                                    персональных данных.</p>
                                <p className={styles.text}>4.3. Лица, передавшие Оператору недостоверные сведения о себе,
                                    либо сведения о другом субъекте персональных данных без согласия последнего, несут
                                    ответственность в соответствии с законодательством РФ</p>

                                <p className={styles.subtitle}>5. Принципы обработки персональных данных</p>
                                <p className={styles.text}>5.1. Обработка персональных данных осуществляется на законной и
                                    справедливой основе.</p>
                                <p className={styles.text}>5.2. Обработка персональных данных ограничивается достижением
                                    конкретных, заранее определенных и законных целей. Не допускается обработка персональных
                                    данных, несовместимая с целями сбора персональных данных.</p>
                                <p className={styles.text}>5.3. Не допускается объединение баз данных, содержащих
                                    персональные данные, обработка которых осуществляется в целях, несовместимых между
                                    собой.</p>
                                <p className={styles.text}>5.4. Обработке подлежат только персональные данные, которые
                                    отвечают целям их обработки.</p>
                                <p className={styles.text}>5.5. Содержание и объем обрабатываемых персональных данных
                                    соответствуют заявленным целям обработки. Не допускается избыточность обрабатываемых
                                    персональных данных по отношению к заявленным целям их обработки.</p>
                                <p className={styles.text}>5.6. При обработке персональных данных обеспечивается точность
                                    персональных данных, их достаточность, а в необходимых случаях и актуальность по
                                    отношению к целям обработки персональных данных. Оператор принимает необходимые меры
                                    и/или обеспечивает их принятие по удалению или уточнению неполных или неточных
                                    данных.</p>
                                <p className={styles.text}>5.7. Хранение персональных данных осуществляется в форме,
                                    позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели
                                    обработки персональных данных, если срок хранения персональных данных не установлен
                                    федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем
                                    по которому является субъект персональных данных. Обрабатываемые персональные данные
                                    уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты
                                    необходимости в достижении этих целей, если иное не предусмотрено федеральным
                                    законом</p>

                                <p className={styles.subtitle}>6. Цели обработки персональных данных</p>
                                <p className={styles.text}></p>

                                <p className={styles.subtitle}>7. Условия обработки персональных данных</p>
                                <p className={styles.text}>7.1. Обработка персональных данных осуществляется с согласия
                                    субъекта персональных данных на обработку его персональных данных.</p>
                                <p className={styles.text}>7.2. Обработка персональных данных необходима для достижения
                                    целей, предусмотренных международным договором Российской Федерации или законом, для
                                    осуществления возложенных законодательством Российской Федерации на оператора функций,
                                    полномочий и обязанностей.</p>
                                <p className={styles.text}>7.3. Обработка персональных данных необходима для осуществления
                                    правосудия, исполнения судебного акта, акта другого органа или должностного лица,
                                    подлежащих исполнению в соответствии с законодательством Российской Федерации об
                                    исполнительном производстве.</p>
                                <p className={styles.text}>7.4. Обработка персональных данных необходима для исполнения
                                    договора, стороной которого либо выгодоприобретателем или поручителем по которому
                                    является субъект персональных данных, а также для заключения договора по инициативе
                                    субъекта персональных данных или договора, по которому субъект персональных данных будет
                                    являться выгодоприобретателем или поручителем.</p>
                                <p className={styles.text}>7.5. Обработка персональных данных необходима для осуществления
                                    прав и законных интересов оператора или третьих лиц либо для достижения общественно
                                    значимых целей при условии, что при этом не нарушаются права и свободы субъекта
                                    персональных данных.</p>
                                <p className={styles.text}>7.6. Осуществляется обработка персональных данных, доступ
                                    неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по
                                    его просьбе (далее — общедоступные персональные данные).</p>
                                <p className={styles.text}>7.7. Осуществляется обработка персональных данных, подлежащих
                                    опубликованию или обязательному раскрытию в соответствии с федеральным законом</p>

                                <p className={styles.subtitle}>8. Порядок сбора, хранения, передачи и других видов обработки
                                    персональных данных</p>
                                <p className={styles.text}>Безопасность персональных данных, которые обрабатываются
                                    Оператором, обеспечивается путем реализации правовых, организационных и технических мер,
                                    необходимых для выполнения в полном объеме требований действующего законодательства в
                                    области защиты персональных данных.</p>
                                <p className={styles.text}>8.1. Оператор обеспечивает сохранность персональных данных и
                                    принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных
                                    лиц.</p>
                                <p className={styles.text}>8.2. Персональные данные Пользователя никогда, ни при каких
                                    условиях не будут переданы третьим лицам, за исключением случаев, связанных с
                                    исполнением действующего законодательства либо в случае, если субъектом персональных
                                    данных дано согласие Оператору на передачу данных третьему лицу для исполнения
                                    обязательств по гражданско-правовому договору.</p>
                                <p className={styles.text}>8.3. В случае выявления неточностей в персональных данных,
                                    Пользователь может актуализировать их самостоятельно, путем направления Оператору
                                    уведомление на адрес электронной почты Оператора privacy@thismywebsite·com с пометкой
                                    «Актуализация персональных данных».</p>
                                <p className={styles.text}>8.4. Срок обработки персональных данных определяется достижением
                                    целей, для которых были собраны персональные данные, если иной срок не предусмотрен
                                    договором или действующим законодательством.</p>
                                <p className={styles.text}>Пользователь может в любой момент отозвать свое согласие на
                                    обработку персональных данных, направив Оператору уведомление посредством электронной
                                    почты на электронный адрес Оператора privacy@thismywebsite·com с пометкой «Отзыв
                                    согласия на обработку персональных данных».</p>
                                <p className={styles.text}>8.5. Вся информация, которая собирается сторонними сервисами, в
                                    том числе платежными системами, средствами связи и другими поставщиками услуг, хранится
                                    и обрабатывается указанными лицами (Операторами) в соответствии с их Пользовательским
                                    соглашением и Политикой конфиденциальности. Субъект персональных данных и/или с
                                    указанными документами. Оператор не несет ответственность за действия третьих лиц, в том
                                    числе указанных в настоящем пункте поставщиков услуг.</p>
                                <p className={styles.text}>8.6. Установленные субъектом персональных данных запреты на
                                    передачу (кроме предоставления доступа), а также на обработку или условия обработки
                                    (кроме получения доступа) персональных данных, разрешенных для распространения, не
                                    действуют в случаях обработки персональных данных в государственных, общественных и иных
                                    публичных интересах, определенных законодательством РФ.</p>
                                <p className={styles.text}>8.7. Оператор при обработке персональных данных обеспечивает
                                    конфиденциальность персональных данных.</p>
                                <p className={styles.text}>8.8. Оператор осуществляет хранение персональных данных в форме,
                                    позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели
                                    обработки персональных данных, если срок хранения персональных данных не установлен
                                    федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем
                                    по которому является субъект персональных данных.</p>
                                <p className={styles.text}>8.9. Условием прекращения обработки персональных данных может
                                    являться достижение целей обработки персональных данных, истечение срока действия
                                    согласия субъекта персональных данных, отзыв согласия субъектом персональных данных или
                                    требование о прекращении обработки персональных данных, а также выявление неправомерной
                                    обработки персональных данных.</p>

                                <p className={styles.subtitle}>9. Перечень действий, производимых Оператором с полученными
                                    персональными данными</p>
                                <p className={styles.text}>9.1. Оператор осуществляет сбор, запись, систематизацию,
                                    накопление, хранение, уточнение (обновление, изменение), извлечение, использование,
                                    передачу (распространение, предоставление, доступ), обезличивание, блокирование,
                                    удаление и уничтожение персональных данных.</p>
                                <p className={styles.text}>9.2. Оператор осуществляет автоматизированную обработку
                                    персональных данных с получением и/или передачей полученной информации по
                                    информационно-телекоммуникационным сетям или без таковой</p>

                                <p className={styles.subtitle}>10. Трансграничная передача персональных данных</p>
                                <p className={styles.text}>10.1. Оператор до начала осуществления деятельности по
                                    трансграничной передаче персональных данных обязан уведомить уполномоченный орган по
                                    защите прав субъектов персональных данных о своем намерении осуществлять трансграничную
                                    передачу персональных данных (такое уведомление направляется отдельно от уведомления о
                                    намерении осуществлять обработку персональных данных).</p>
                                <p className={styles.text}>10.2. Оператор до подачи вышеуказанного уведомления, обязан
                                    получить от органов власти иностранного государства, иностранных физических лиц,
                                    иностранных юридических лиц, которым планируется трансграничная передача персональных
                                    данных, соответствующие сведения</p>

                                <p className={styles.subtitle}>11. Конфиденциальность персональных данных</p>
                                <p className={styles.text}>Оператор и иные лица, получившие доступ к персональным данным,
                                    обязаны не раскрывать третьим лицам и не распространять персональные данные без согласия
                                    субъекта персональных данных, если иное не предусмотрено федеральным законом.</p>

                                <p className={styles.subtitle}>12. Заключительные положения</p>
                                <p className={styles.text}>12.1. Пользователь может получить любые разъяснения по
                                    интересующим вопросам, касающимся обработки его персональных данных, обратившись к
                                    Оператору с помощью электронной почты privacy@thismywebsite·com.</p>
                                <p className={styles.text}>12.2. В данном документе будут отражены любые изменения политики
                                    обработки персональных данных Оператором. Политика действует бессрочно до замены ее
                                    новой версией.</p>
                                <p className={styles.text}>12.3. Актуальная версия Политики в свободном доступе расположена
                                    в сети Интернет по адресу httpsː//thismywebsite·com/privacy/</p>

                            </>
                        ) : (
                            <>

                                <p className={styles.subtitle}>Interpretation and Definitions</p>

                                <p className={styles.subtitle}>Interpretation</p>
                                <p className={styles.text}>The words of which the initial letter is capitalized have meanings defined
                                    under the following conditions. The following definitions shall have the same
                                    meaning regardless of whether they appear in singular or in plural.</p>

                                <p className={styles.subtitle}>Definitions </p>

                                <p className={styles.subtitle}>For the purposes of this Privacy Policy:</p>
                                <p className={styles.text}>* Account means a unique account created for You to access our Service or parts of our Service.</p>
                                <p className={styles.text}>* Affiliate means an entity that controls, is controlled by or is under
                                    common control with a party, where "control" means ownership of 50% or
                                    more of the shares, equity interest or other securities entitled to vote
                                    for election of directors or other managing authority.</p>
                                <p className={styles.text}>* Company (referred to as either "the Company", "We", "Us" or "Our" in this
                                    Agreement) refers to My Site.</p>
                                <p className={styles.text}>* Cookies are small files that are placed on Your computer, mobile device or
                                    any other device by a website, containing the details of Your browsing
                                    history on that website among its many uses.</p>
                                <p className={styles.text}>* Country refers to: Indonesia</p>
                                <p className={styles.text}>* Device means any device that can access the Service such as a computer, a
                                    cellphone or a digital tablet.</p>
                                <p className={styles.text}>* Personal Data is any information that relates to an identified or
                                    identifiable individual.</p>
                                <p className={styles.text}>* Service refers to the Website.</p>
                                <p className={styles.text}>* Service Provider means any natural or legal person who processes the data
                                    on behalf of the Company. It refers to third-party companies or
                                    individuals employed by the Company to facilitate the Service, to provide
                                    the Service on behalf of the Company, to perform services related to the
                                    Service or to assist the Company in analyzing how the Service is used.</p>
                                <p className={styles.text}>* Usage Data refers to data collected automatically, either generated by the
                                    use of the Service or from the Service infrastructure itself (for example,
                                    the duration of a page visit).</p>
                                <p className={styles.text}>* Website refers to My Site, accessible from
                                    [httpsː//thismywebsite·com](https%CB%90//thismywebsite%C2%B7com)</p>
                                <p className={styles.text}>* You means the individual accessing or using the Service, or the company,
                                    or other legal entity on behalf of which such individual is accessing or
                                    using the Service, as applicable.</p>

                                <p className={styles.subtitle}>Collecting and Using Your Personal Data</p>
                                <p className={styles.subtitle}>Types of Data Collected</p>
                                <p className={styles.subtitle}>Personal Data</p>
                                <p className={styles.text}>While using Our Service, We may ask You to provide Us with certain personally
                                    identifiable information that can be used to contact or identify You.
                                    Personally identifiable information may include, but is not limited to:</p>
                                <p className={styles.text}>* Email address</p>
                                <p className={styles.text}>* First name and last name</p>
                                <p className={styles.text}>* Usage Data</p>

                                <p className={styles.subtitle}>Usage Data</p>
                                <p className={styles.text}>Usage Data is collected automatically when using the Service.</p>
                                <p className={styles.text}>Usage Data may include information such as Your Device's Internet Protocol
                                    address (e.g. IP address), browser type, browser version, the pages of our
                                    Service that You visit, the time and date of Your visit, the time spent on
                                    those pages, unique device identifiers and other diagnostic data.</p>
                                <p className={styles.text}>When You access the Service by or through a mobile device, We may collect
                                    certain information automatically, including, but not limited to, the type of
                                    mobile device You use, Your mobile device unique ID, the IP address of Your
                                    mobile device, Your mobile operating system, the type of mobile Internet
                                    browser You use, unique device identifiers and other diagnostic data.</p>
                                <p className={styles.text}>We may also collect information that Your browser sends whenever You visit our
                                    Service or when You access the Service by or through a mobile device.</p>

                                <p className={styles.subtitle}>Tracking Technologies and Cookies</p>
                                <p className={styles.text}>We use Cookies and similar tracking technologies to track the activity on Our
                                    Service and store certain information. Tracking technologies used are beacons,
                                    tags, and scripts to collect and track information and to improve and analyze
                                    Our Service. The technologies We use may include:</p>
                                <p className={styles.text}>  * Cookies or Browser Cookies. A cookie is a small file placed on Your
                                    Device. You can instruct Your browser to refuse all Cookies or to indicate
                                    when a Cookie is being sent. However, if You do not accept Cookies, You
                                    may not be able to use some parts of our Service. Unless you have adjusted
                                    Your browser setting so that it will refuse Cookies, our Service may use
                                    Cookies.</p>
                                <p className={styles.text}>  * Web Beacons. Certain sections of our Service and our emails may contain
                                    small electronic files known as web beacons (also referred to as clear
                                    gifs, pixel tags, and single-pixel gifs) that permit the Company, for
                                    example, to count users who have visited those pages or opened an email
                                    and for other related website statistics (for example, recording the
                                    popularity of a certain section and verifying system and server
                                    integrity).</p>
                                <p className={styles.text}>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on
                                    Your personal computer or mobile device when You go offline, while Session
                                    Cookies are deleted as soon as You close Your web browser. Learn more about
                                    cookies on the [Free Privacy Policy
                                    website](https://www.freeprivacypolicy.com/blog/sample-privacy-policy-
                                    template/#Use_Of_Cookies_And_Tracking) article.</p>
                                <p className={styles.text}>We use both Session and Persistent Cookies for the purposes set out below:</p>
                                <p className={styles.text}>* Necessary / Essential Cookies</p>
                                <p className={styles.text}>Type: Session Cookies</p>
                                <p className={styles.text}>Administered by: Us</p>
                                <p className={styles.text}>Purpose: These Cookies are essential to provide You with services
                                    available through the Website and to enable You to use some of its
                                    features. They help to authenticate users and prevent fraudulent use of
                                    user accounts. Without these Cookies, the services that You have asked for
                                    cannot be provided, and We only use these Cookies to provide You with
                                    those services.</p>
                                <p className={styles.text}>* Cookies Policy / Notice Acceptance Cookies</p>
                                <p className={styles.text}>Type: Persistent Cookies</p>
                                <p className={styles.text}>Administered by: Us</p>
                                <p className={styles.text}>Purpose: These Cookies identify if users have accepted the use of cookies
                                    on the Website.</p>
                                <p className={styles.text}> * Functionality Cookies</p>
                                <p className={styles.text}>Type: Persistent Cookies</p>
                                <p className={styles.text}>Administered by: Us</p>
                                <p className={styles.text}>Purpose: These Cookies allow us to remember choices You make when You use
                                    the Website, such as remembering your login details or language
                                    preference. The purpose of these Cookies is to provide You with a more
                                    personal experience and to avoid You having to re-enter your preferences
                                    every time You use the Website.</p>
                                <p className={styles.text}>For more information about the cookies we use and your choices regarding
                                    cookies, please visit our Cookies Policy or the Cookies section of our Privacy
                                    Policy.</p>

                                <p className={styles.subtitle}>Use of Your Personal Data</p>
                                <p className={styles.text}>The Company may use Personal Data for the following purposes:</p>
                                <p className={styles.text}>* To provide and maintain our Service , including to monitor the usage of
                                    our Service.</p>
                                <p className={styles.text}>* To manage Your Account: to manage Your registration as a user of the
                                    Service. The Personal Data You provide can give You access to different
                                    functionalities of the Service that are available to You as a registered
                                    user.</p>
                                <p className={styles.text}>* For the performance of a contract: the development, compliance and
                                    undertaking of the purchase contract for the products, items or services
                                    You have purchased or of any other contract with Us through the Service.</p>
                                <p className={styles.text}>* To contact You: To contact You by email, telephone calls, SMS, or other
                                    equivalent forms of electronic communication, such as a mobile
                                    application's push notifications regarding updates or informative
                                    communications related to the functionalities, products or contracted
                                    services, including the security updates, when necessary or reasonable for
                                    their implementation.</p>
                                <p className={styles.text}>* To provide You with news, special offers and general information about
                                    other goods, services and events which we offer that are similar to those
                                    that you have already purchased or enquired about unless You have opted
                                    not to receive such information.</p>
                                <p className={styles.text}>* To manage Your requests: To attend and manage Your requests to Us.</p>
                                <p className={styles.text}>* For business transfers: We may use Your information to evaluate or conduct
                                    a merger, divestiture, restructuring, reorganization, dissolution, or
                                    other sale or transfer of some or all of Our assets, whether as a going
                                    concern or as part of bankruptcy, liquidation, or similar proceeding, in
                                    which Personal Data held by Us about our Service users is among the assets
                                    transferred.</p>
                                <p className={styles.text}>* For other purposes : We may use Your information for other purposes, such
                                    as data analysis, identifying usage trends, determining the effectiveness
                                    of our promotional campaigns and to evaluate and improve our Service,
                                    products, services, marketing and your experience.</p>
                                <p className={styles.text}>We may share Your personal information in the following situations:</p>
                                <p className={styles.text}>* With Service Providers: We may share Your personal information with
                                    Service Providers to monitor and analyze the use of our Service, to
                                    contact You.</p>
                                <p className={styles.text}>* For business transfers: We may share or transfer Your personal information
                                    in connection with, or during negotiations of, any merger, sale of Company
                                    assets, financing, or acquisition of all or a portion of Our business to
                                    another company.</p>
                                <p className={styles.text}>* With Affiliates: We may share Your information with Our affiliates, in
                                    which case we will require those affiliates to honor this Privacy Policy.
                                    Affiliates include Our parent company and any other subsidiaries, joint
                                    venture partners or other companies that We control or that are under
                                    common control with Us.</p>
                                <p className={styles.text}> * With business partners: We may share Your information with Our business
                                    partners to offer You certain products, services or promotions.</p>
                                <p className={styles.text}>* With other users: when You share personal information or otherwise
                                    interact in the public areas with other users, such information may be
                                    viewed by all users and may be publicly distributed outside.</p>
                                <p className={styles.text}>* With Your consent : We may disclose Your personal information for any
                                    other purpose with Your consent.</p>

                                <p className={styles.subtitle}>Retention of Your Personal Data  </p>
                                <p className={styles.text}>The Company will retain Your Personal Data only for as long as is necessary
                                    for the purposes set out in this Privacy Policy. We will retain and use Your
                                    Personal Data to the extent necessary to comply with our legal obligations
                                    (for example, if we are required to retain your data to comply with applicable
                                    laws), resolve disputes, and enforce our legal agreements and policies.</p>
                                <p className={styles.text}>The Company will also retain Usage Data for internal analysis purposes. Usage
                                    Data is generally retained for a shorter period of time, except when this data
                                    is used to strengthen the security or to improve the functionality of Our
                                    Service, or We are legally obligated to retain this data for longer time
                                    periods.</p>

                                <p className={styles.subtitle}>Transfer of Your Personal Data </p>
                                <p className={styles.text}>Your information, including Personal Data, is processed at the Company's
                                    operating offices and in any other places where the parties involved in the
                                    processing are located. It means that this information may be transferred to —
                                    and maintained on — computers located outside of Your state, province, country
                                    or other governmental jurisdiction where the data protection laws may differ
                                    than those from Your jurisdiction.</p>
                                <p className={styles.text}>Your consent to this Privacy Policy followed by Your submission of such
                                    information represents Your agreement to that transfer.</p>
                                <p className={styles.text}>The Company will take all steps reasonably necessary to ensure that Your data
                                    is treated securely and in accordance with this Privacy Policy and no transfer
                                    of Your Personal Data will take place to an organization or a country unless
                                    there are adequate controls in place including the security of Your data and
                                    other personal information.</p>

                                <p className={styles.subtitle}>Delete Your Personal Data </p>
                                <p className={styles.text}>You have the right to delete or request that We assist in deleting the
                                    Personal Data that We have collected about You.</p>
                                <p className={styles.text}>Our Service may give You the ability to delete certain information about You
                                    from within the Service.</p>
                                <p className={styles.text}>You may update, amend, or delete Your information at any time by signing in to
                                    Your Account, if you have one, and visiting the account settings section that
                                    allows you to manage Your personal information. You may also contact Us to
                                    request access to, correct, or delete any personal information that You have
                                    provided to Us.</p>
                                <p className={styles.text}>Please note, however, that We may need to retain certain information when we
                                    have a legal obligation or lawful basis to do so.</p>

                                <p className={styles.subtitle}>Disclosure of Your Personal Data</p>
                                <p className={styles.subtitle}>Business Transactions </p>
                                <p className={styles.text}>If the Company is involved in a merger, acquisition or asset sale, Your
                                    Personal Data may be transferred. We will provide notice before Your Personal
                                    Data is transferred and becomes subject to a different Privacy Policy.</p>

                                <p className={styles.subtitle}>Law enforcement </p>
                                <p className={styles.text}>Under certain circumstances, the Company may be required to disclose Your
                                    Personal Data if required to do so by law or in response to valid requests by
                                    public authorities (e.g. a court or a government agency).</p>

                                <p className={styles.subtitle}>Other legal requirements</p>
                                <p className={styles.text}>The Company may disclose Your Personal Data in the good faith belief that such
                                    action is necessary to:</p>
                                <p className={styles.text}>  * Comply with a legal obligation</p>
                                <p className={styles.text}>  * Protect and defend the rights or property of the Company</p>
                                <p className={styles.text}>  * Prevent or investigate possible wrongdoing in connection with the Service</p>
                                <p className={styles.text}>  * Protect the personal safety of Users of the Service or the public</p>
                                <p className={styles.text}>  * Protect against legal liability</p>

                                <p className={styles.subtitle}>Security of Your Personal Data </p>
                                <p className={styles.text}>The security of Your Personal Data is important to Us, but remember that no
                                    method of transmission over the Internet, or method of electronic storage is
                                    100% secure. While We strive to use commercially acceptable means to protect
                                    Your Personal Data, We cannot guarantee its absolute security.</p>

                                <p className={styles.subtitle}>Children's Privacy  </p>
                                <p className={styles.text}>Our Service does not address anyone under the age of 13. We do not knowingly
                                    collect personally identifiable information from anyone under the age of 13.
                                    If You are a parent or guardian and You are aware that Your child has provided
                                    Us with Personal Data, please contact Us. If We become aware that We have
                                    collected Personal Data from anyone under the age of 13 without verification
                                    of parental consent, We take steps to remove that information from Our
                                    servers.</p>
                                <p className={styles.text}>If We need to rely on consent as a legal basis for processing Your information
                                    and Your country requires consent from a parent, We may require Your parent's
                                    consent before We collect and use that information.</p>

                                <p className={styles.subtitle}>Links to Other Websites </p>
                                <p className={styles.text}>Our Service may contain links to other websites that are not operated by Us.
                                    If You click on a third party link, You will be directed to that third party's
                                    site. We strongly advise You to review the Privacy Policy of every site You
                                    visit.</p>
                                <p className={styles.text}>We have no control over and assume no responsibility for the content, privacy
                                    policies or practices of any third party sites or services.</p>

                                <p className={styles.subtitle}>Changes to this Privacy Policy</p>
                                <p className={styles.text}>We may update Our Privacy Policy from time to time. We will notify You of any
                                    changes by posting the new Privacy Policy on this page.</p>
                                <p className={styles.text}>We will let You know via email and/or a prominent notice on Our Service, prior
                                    to the change becoming effective and update the "Last updated" date at the top
                                    of this Privacy Policy.</p>
                                <p className={styles.text}>You are advised to review this Privacy Policy periodically for any changes.
                                    Changes to this Privacy Policy are effective when they are posted on this
                                    page.</p>

                                <p className={styles.subtitle}>Contact Us</p>
                                <p className={styles.text}>If you have any questions about this Privacy Policy, You can contact us:</p>
                                <p className={styles.text}>* By email: privacy@thismywebsite·com</p>

                            </>
                        )
                }
            </div>
        </Main>
    );
};
