import React, {useEffect, useState} from 'react';
import { doc, onSnapshot, getFirestore } from 'firebase/firestore';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {Main} from '../../layouts';
import {AppLoader} from '../../components/AppLoader';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ReadOnlyText} from '../../components/ReadOnlyText';
import {ILessonsIn} from '../../models/lesson';
import {setCurrentLessons} from '../LessonPage/LessonPage.slice';
import {Title} from '../../components/Title';
import {checkAssetsByDate} from '../../helpers';
import {defaultCourse, ICourse} from '../../models/Course';
import styles from './TemporaryCourse.module.scss';
import {getTemporaryData} from './api';
import {ContentState} from './types';
import {List} from './components';

export const TemporaryCourse = () => {
    const params = useParams<{ uid: string }>();
    const {i18n, t} = useTranslation();
    const isRu = i18n.language === 'ru';
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [content, setContent] = useState<ContentState[] | null>(null);
    const [noAccess, setNoAccess] = useState<boolean>(false);
    const [currentCourse, setCurrentCourse] = useState<ICourse>(defaultCourse);

    const {user} = useAppSelector(state => state.front.app);

    const [date, setDate] = useState<string>('');
    const [isValid, setValid] = useState<boolean>(true);
    
    const pay = (currentCourse: any, userInfo: any, isRu: any) => {
        (window as any).buyCourse(currentCourse, userInfo, isRu);
    };

    const createLabel = (number: number, titles: Array<string>) => {
        const cases = [2, 0, 1, 1, 1, 2];
        return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
    };

    const [updatedUser, setUpdatedUser] = useState<any>(user);
    const [snapshotCount, setSnapshotCount] = useState<any>(0);

    useEffect(() => {
        const db = getFirestore();

        onSnapshot(doc(db, 'users', user.uid), (doc) => {
            setUpdatedUser(doc.data());

            let timer;
            clearTimeout(timer);
            setTimeout(() => {
                setSnapshotCount(snapshotCount + 1);
            }, 1000);
            
        });
    }, []);

    useEffect(() => {

        const {uid} = params;
        let assetsIds = '';
        setLoading(true);

        assetsIds = updatedUser.temporary.map((i:any) => i.id) as any;
        if(!assetsIds?.includes(uid as string)) {
            setNoAccess(true);
        } else {
            setNoAccess(false);
        }

        getTemporaryData(uid as string).then(({content, temporaryCourse}) => {
            const currentLessons: ILessonsIn[] = [];
            content.forEach(i => currentLessons.push(...i.lessons));
            dispatch(setCurrentLessons(currentLessons));
            setContent(content);
            setCurrentCourse(temporaryCourse);
            setLoading(false);

            if(temporaryCourse.duration > 0) {
                let current = '' as any;
                current = updatedUser.temporary.filter((i:any) => i.id === uid).pop() as any;
                if (current !== undefined && current.timeStump !== null && current.timeStump !== undefined) {
                    const otherDay = current.timeStump + temporaryCourse.duration * 24 * 60 * 60 * 1000;
                    const isAssets = checkAssetsByDate(otherDay as number);

                    const D = new Date(current.timeStump as number);
                    D.setDate(D.getDate());
                    setDate(D.toLocaleDateString('ru-RU'));

                    if(!isAssets) {
                        setValid(false);
                    }else{
                        setValid(true);
                    }
                }
            }

        }).catch(e => {
            setNoAccess(true);
            setLoading(false);
        });

        return () => {
            setCurrentCourse(defaultCourse);
        };

    }, [snapshotCount]);
      
    return (
        <Main>
            {
                loading
                    ?
                        <AppLoader loading={loading}/>
                    :
                        <div className={styles.temporaryCourse}>
                            {
                                noAccess
                                    ?
                                        <>
                                            <Title title={isRu ? currentCourse.name.ru : currentCourse.name.en}/>
                                            {currentCourse.description.ru ? (<div className={isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                                                <ReadOnlyText initialValue={currentCourse.description.ru}/>
                                            </div>) : null}
                                            {currentCourse.description.en ? (<div className={!isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                                                <ReadOnlyText initialValue={currentCourse.description.en}/>
                                            </div>) : null}
                                            { currentCourse.isPurchase ?
                                                <>
                                                    <div className={styles.temporaryCourse__info}>{t('course.price')}&nbsp;
                                                        {isRu ? (
                                                            currentCourse.price > 0 ? currentCourse.price + createLabel(currentCourse.price, [' рубль', ' рубля', ' рублей']) : 'бесплатно'
                                                        ) : (
                                                            currentCourse.price > 0 ? currentCourse.price + (currentCourse.price > 1 ? ' ruble' : ' rubles') : 'free'
                                                        )}
                                                    </div>
                                                    <div className={styles.temporaryCourse__info}>{t('temporaryCourse.subDuration')}&nbsp;
                                                        {isRu ? (
                                                            currentCourse.duration > 0 ? currentCourse.duration + createLabel(currentCourse.duration, [' день', ' дня', ' дней']) : 'бессрочный'
                                                        ) : (
                                                            currentCourse.duration > 0 ? currentCourse.duration + (currentCourse.duration > 1 ? ' day' : ' days') : 'unlimited'
                                                        )}
                                                    </div>

                                                    <button className={styles.temporaryCourse__button} onClick={() => pay(currentCourse,updatedUser,isRu)}>{t('course.buy')}</button>
                                                </>
                                                : <div className={styles.temporaryCourse__info}>{t('course.locked')}</div>
                                            }
                                        </>
                                    : isValid ? (
                                        <>
                                            <span className={styles.accessDate}>
                                                {currentCourse.duration > 0
                                                    ? `${t('temporaryCourse.accessDate')} ${date}`
                                                    : null}
                                            </span>
                                            <Title title={isRu ? currentCourse.name.ru : currentCourse.name.en}/>
                                            {currentCourse.description.ru ? (<div className={isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                                                <ReadOnlyText initialValue={currentCourse.description.ru}/>
                                            </div>) : null}
                                            {currentCourse.description.en ? (<div className={!isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                                                <ReadOnlyText initialValue={currentCourse.description.en}/>
                                            </div>) : null}
                                            <div className={styles.temporaryCourse__content}>
                                                {content ? content?.map(item => (
                                                    <List key={item.uid} item={item} isRu={isRu}/>
                                                )) : null}
                                            </div>
                                        </>
                                    )
                                    :
                                        <>
                                            <span className={styles.accessDate}>
                                                {t('temporaryCourse.accessError')}
                                            </span>
                                            <Title title={isRu ? currentCourse.name.ru : currentCourse.name.en}/>
                                            {currentCourse.description.ru ? (<div className={isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                                                <ReadOnlyText initialValue={currentCourse.description.ru}/>
                                            </div>) : null}
                                            {currentCourse.description.en ? (<div className={!isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                                                <ReadOnlyText initialValue={currentCourse.description.en}/>
                                            </div>) : null}
                                            { currentCourse.isPurchase ?
                                                <>
                                                    <div className={styles.temporaryCourse__info}>{t('course.price')}&nbsp;
                                                        {isRu ? (
                                                            currentCourse.price > 0 ? currentCourse.price + createLabel(currentCourse.price, [' рубль', ' рубля', ' рублей']) : 'бесплатно'
                                                        ) : (
                                                            currentCourse.price > 0 ? currentCourse.price + (currentCourse.price > 1 ? ' rubles' : ' ruble') : 'free'
                                                        )}
                                                    </div>
                                                    <div className={styles.temporaryCourse__info}>{t('temporaryCourse.subDuration')}&nbsp;
                                                        {isRu ? (
                                                            currentCourse.duration > 0 ? currentCourse.duration + createLabel(currentCourse.duration, [' день', ' дня', ' дней']) : 'бессрочный'
                                                        ) : (
                                                            currentCourse.duration > 0 ? currentCourse.duration + (currentCourse.duration > 1 ? ' days' : ' day') : 'unlimited'
                                                        )}
                                                    </div>

                                                    <button className={styles.temporaryCourse__button} onClick={() => pay(currentCourse,user,isRu)}>{t('course.buy')}</button>
                                                </>
                                                : <div className={styles.temporaryCourse__info}>{t('course.locked')}</div>
                                            }
                                        </>
                            }
                        </div>
            }
            

        </Main>
    );
};

