import {IPostCategory} from '../../models/Post';


export const defaultPostCategory:IPostCategory = {
    name: {ru: '', en: ''},
    serialNumber: 0,
};

export interface IPostCategoryState {
    category: IPostCategory[]
    currentPostCategory : IPostCategory
}
