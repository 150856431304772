import React from 'react';
import styles from '../Post.module.scss';
import {IPost} from '../../../models/Post';
import {ListItem} from './ListItem';

interface IListName {
    items: IPost[];
    handleUpdate: (uid:string) => void;
    handleDelete: (uid:string) => void;
}

export const List = ({items = [], handleUpdate, handleDelete}: IListName) => {
    return (
        <ul className={styles.list}>
            {items.map(i =>
                <ListItem
                    key={i.uid}
                    uid={i.uid}
                    name={i.name}
                    category={i.category}
                    description={i.description}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                />)}
        </ul>
    );
};
