import {locale, typesCourse} from './Course';

export interface IStage {
    uid?: string | null;
    name: locale;
    description: {ru: string, en: string};
    belonging: string;
    serialNumber: number;
    lessonsIn: string[];
    type: typesCourse.free | typesCourse.temporary | typesCourse.constant;
    price: number;
    isPurchase: boolean;
}

export const defaultStage: IStage = {
    uid: null,
    name: {ru: '', en: ''},
    description: {ru: '', en: ''},
    belonging: '',
    serialNumber: 0,
    lessonsIn: [],
    type: typesCourse.free,
    price: 0,
    isPurchase: false
};
