import React from 'react';
import {PanelLayout} from '../../layouts';
import {AppLoader} from '../../components/AppLoader';
import { useAppSelector} from '../../hooks';
import {IConsultationProps} from './types';
import styles from './ConsultationAdmin.module.scss';
import {Form} from './components';
// eslint-disable-next-line
export const ConsultationAdmin = ({}: IConsultationProps) => {
    const {loading, item} = useAppSelector(state => state.admin.consultationAdmin);


    return (
        <PanelLayout>
            <div className={styles.consultation}>
                <AppLoader loading={loading}/>
                <div className={styles.consultation__header}>
                    <h1 className={styles.consultation__title}>Консультация</h1>
                </div>
                <div className={styles.consultation__row}>
                    <div className={styles.consultation__colM}>
                        <Form item={item} />
                    </div>
                </div>
            </div>
        </PanelLayout>
    );
};

