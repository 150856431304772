import React, {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {NavClient} from '../../components/NavClient';
import {FooterClient} from '../../components/FooterClient';
import {useDayOfWeek, useProgressiveImage} from '../../hooks';
import {BreadCrumbs} from '../../components/BreadCrumbs';
import {Header} from './components';
import styles from './style.module.scss';
const coverMain = require('../../assets/images/Background_Home.png');
const optimizedCoverMain = require('../../assets/images/Optimized-Background_Home.png');
const coverSingle = require('../../assets/images/Background_Other.png');
const optimizedCoverSingle = require('../../assets/images/Optimized-Background_Other.png');

interface IMainLayoutProps {
    children: ReactNode
}

const innerContainerPaths = ['/', '/content/day-of-the-week'];

export const Main = ({children}: IMainLayoutProps) => {
    const location = useLocation();
    const {background, backgroundOpt} = useDayOfWeek();
    const {sourceLoaded} = useProgressiveImage(coverMain);
    const {sourceLoaded: singleImage} = useProgressiveImage(coverSingle);
    const {sourceLoaded: dayOfWeekImageBg} = useProgressiveImage(background);

    return (
        <div className={styles.wrapper} style={{
            backgroundImage: `url(${location.pathname === '/'
                ? sourceLoaded || optimizedCoverMain 
                : location.pathname === '/content/day-of-the-week'
                ? dayOfWeekImageBg || backgroundOpt
                : singleImage || optimizedCoverSingle
            })`
        }}>
            <Header/>
            <div className={
                location.pathname !== '/'
                    ? styles.content
                    : styles.contentReverse
            }>
                <NavClient/>
                <main className={innerContainerPaths.includes(location.pathname) ? styles.inner : styles.innerSinglePage}>
                    {children}
                    {location.pathname !== '/' ? <BreadCrumbs isBack={true}/> : null}
                </main>
            </div>
            <FooterClient/>
        </div>
    );
};
