import {useAppSelector} from './hooks';

export const useAuth = () => {
    const {email, uid, role, isPaid, mainStages } = useAppSelector(state => state.front.app.user);

    return {
        isAuth: !!email,
        email,
        uid,
        role,
        isPaid,
        mainStages
    };
};
