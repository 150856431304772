import Ponedelnik from '../assets/dayOfTheWeek/Ponedelnik.png';
import Vtornik from '../assets/dayOfTheWeek/Vtornik.png';
import Sreda from '../assets/dayOfTheWeek/Sreda.png';
import Chetverg from '../assets/dayOfTheWeek/Chetverg.png';
import Piatnica from '../assets/dayOfTheWeek/Piatnica.png';
import Subbota from '../assets/dayOfTheWeek/Subbota.png';
import Voskresenie from '../assets/dayOfTheWeek/Voskresenie.png';
import PonedelnikEn from '../assets/dayOfTheWeek/PonedelnikEN.png';
import VtornikEn from '../assets/dayOfTheWeek/VtornikEN.png';
import SredaEn from '../assets/dayOfTheWeek/SredaEN.png';
import ChetvergEn from '../assets/dayOfTheWeek/ChetvergEN.png';
import PiatnicaEn from '../assets/dayOfTheWeek/PiatnicaEN.png';
import SubbotaEn from '../assets/dayOfTheWeek/SubbotaEN.png';
import VoskresenieEn from '../assets/dayOfTheWeek/VoskresenieEN.png';
import BG_Ponedelnik from '../assets/dayOfTheWeek/Background_Ponedelnik.png';
import BG_Vtornik from '../assets/dayOfTheWeek/Background_Vtornik.png';
import BG_Sreda from '../assets/dayOfTheWeek/Background_Sreda.png';
import BG_Chetverg from '../assets/dayOfTheWeek/Background_Chetverg.png';
import BG_Piatnica from '../assets/dayOfTheWeek/Background_Piatnica.png';
import BG_Subbota from '../assets/dayOfTheWeek/Background_Subbota.png';
import BG_Voskresenie from '../assets/dayOfTheWeek/Background_Voskresenie.png';
import BG_Optimase_Ponedelnik from '../assets/dayOfTheWeek/Optimized-Background_Ponedelnik.png';
import BG_Optimase_Vtornik from '../assets/dayOfTheWeek/Optimized-Background_Vtornik.png';
import BG_Optimase_Sreda from '../assets/dayOfTheWeek/Optimized-Background_Sreda.png';
import BG_Optimase_Chetverg from '../assets/dayOfTheWeek/Optimized-Background_Chetverg.png';
import BG_Optimase_Piatnica from '../assets/dayOfTheWeek/Optimized-Background_Piatnica.png';
import BG_Optimase_Subbota from '../assets/dayOfTheWeek/Optimized-Background_Subbota.png';
import BG_Optimase_Voskresenie from '../assets/dayOfTheWeek/Optimized-Background_Voskresenie.png';
import {dayOfTheWeekColors, dayOfTheWeekColorsDark, dayOfTheWeekColorsLight} from '../utils/constants';

export const useDayOfWeek = () => {
    const daysImages = [
        Voskresenie,
        Ponedelnik,
        Vtornik,
        Sreda,
        Chetverg,
        Piatnica,
        Subbota,
    ];
    const daysImagesEn = [
        VoskresenieEn,
        PonedelnikEn,
        VtornikEn,
        SredaEn,
        ChetvergEn,
        PiatnicaEn,
        SubbotaEn

    ];

    const daysBG = [
        BG_Voskresenie,
        BG_Ponedelnik,
        BG_Vtornik,
        BG_Sreda,
        BG_Chetverg,
        BG_Piatnica,
        BG_Subbota,
    ];
    const daysBgOpt = [
        BG_Optimase_Voskresenie,
        BG_Optimase_Ponedelnik,
        BG_Optimase_Vtornik,
        BG_Optimase_Sreda,
        BG_Optimase_Chetverg,
        BG_Optimase_Piatnica,
        BG_Optimase_Subbota,
    ];
    const d = new Date();
    const day = d.getDay();

    return {
        image: daysImages[day],
        imageEn: daysImagesEn[day],
        background: daysBG[day],
        backgroundOpt: daysBgOpt[day],
        color: dayOfTheWeekColors[day],
        colorsLight: dayOfTheWeekColorsLight[day],
        colorDark: dayOfTheWeekColorsDark[day]
    };
};
