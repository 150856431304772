import React, {useEffect, useState} from 'react';
import {Button, IconButton, List, ListItem, ListItemText, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import classNames from 'classnames';
import {PanelLayout} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {AppLoaderSmall} from '../../components/AppLoader';
import {IUser} from '../../models/User';
import styles from './Users.module.scss';
import {deleteUser, getUserByEmail, getUsersIsPaid, updateUserStatus} from './api';
import {setUsersByEmail} from './Users.slice';
import {CardUser} from './components';
import {IData} from './components/CardUser';


export const Users = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [userEmail, setUserEmail] = useState<string>('');
    const {currentUser} = useAppSelector(state => state.admin.users);

    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>([]);

    useEffect(() => {
        getUsersIsPaid().then(users => setUsers(users));
    }, []);

    const handleGetUser = async () => {
        setLoading(true);
        const user = await getUserByEmail(userEmail);
        if (user) {
            await dispatch(setUsersByEmail(user));
        }
        setLoading(false);
    };

    const onUpdateUser = async ({isPaid, temporary, mainStages, role}: IData) => {
        setLoading(true);

       await updateUserStatus({
            ...currentUser,
            isPaid: isPaid,
            temporary: temporary ?? [],
            mainStages,
            role: role,
            update_as: Date.now()
        }, currentUser.isPaid);

        // if (newUser) {
        //     await dispatch(setUsersByEmail(userDefault));
        // }
        await getUsersIsPaid().then(users => {
            setUsers(users);
            // const updatedUser = users.find(i => i.uid === currentUser.uid);
            // dispatch(setUsersByEmail(updatedUser));
        });
        setLoading(false);
    };

    const editUser = (user: IUser) => {
        dispatch(setUsersByEmail(user));
    };

    const handleDeleteIsPaid = async (user:IUser) => {
        setLoading(true);
        const updatedUser:IUser = {
            ...user,
            isPaid: false,
            temporary: [],
            account: 'disable',
            courses: [],
            mainStages: []
        };
        const users = await deleteUser({user: updatedUser});
        setUsers(users);
        setLoading(false);
    };

    return (
        <PanelLayout>
            <div className={styles.users}>
                <h1 className={styles.users__title}>{t('users.title')}</h1>
                <div className={styles.row}>
                    <div className={classNames(styles.box, styles.box__column)}>
                        <h3 className={styles.users__subtitle}>Найти пользователя со статусом "не оплачено"</h3>
                        <TextField
                            size={'small'}
                            placeholder={'email пользователя'}
                            value={userEmail}
                            onChange={(event) => setUserEmail(event.target.value)}
                        />
                        <div className={styles.buttonWrapper}>
                            <Button
                                variant="outlined"
                                className={styles.button}
                                onClick={handleGetUser}>Получить
                                пользователя по email</Button>
                        </div>
                        <div className={styles.box__inner}>
                            {loading
                                ? <AppLoaderSmall loading={loading}/>
                                : currentUser.uid
                                    ? (<CardUser user={currentUser} handleSave={onUpdateUser}/>)
                                    : null
                            }
                        </div>
                    </div>
                    <div className={styles.box}>
                        <h3 className={styles.users__subtitle}>Все пользователи со статусом "оплачено"</h3>
                        <List sx={{margin: '0px', padding: '0px', maxHeight: '500px', overflowX: 'auto'}}>
                            {users.map(i => (
                                <ListItem
                                    key={i.uid}
                                    sx={{
                                        width: '100%',
                                        paddingTop: '0px',
                                        borderBottom: '1px solid #c2c2c2'
                                    }}
                                    secondaryAction={
                                        <>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => editUser(i)}
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleDeleteIsPaid(i)}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <ListItemText
                                        sx={{margin: '0px'}}
                                        primary={i.displayName}
                                        secondary={i.email}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </div>
            </div>
        </PanelLayout>
    );
};

