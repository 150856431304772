import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Main} from '../../layouts';
import {Title} from '../../components/Title';
import bg from '../../assets/images/Consultation_pic.png';
import {getConsultation} from '../../admin/ConsultationAdmin/api';
import {setConsultation} from '../../admin/ConsultationAdmin/ConsultationAdmin.slice';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ReadOnlyText} from '../../components/ReadOnlyText';
import styles from './style.module.scss';


export const Consultation = () => {
    const {i18n, t} = useTranslation();
    const isRu = i18n.language === 'ru';
    const dispatch = useAppDispatch();
    const {item} = useAppSelector(state => state.admin.consultationAdmin);

    useEffect(() => {
        getConsultation().then(result => dispatch(setConsultation(result)));
    }, []);

    return (
        <Main>
            <div className={styles.page}>
                <Title title={isRu ? item.name.ru : item.name.en}/>
                <div className={styles.container}>
                    <div className={styles.col}>
                        <div className={styles.bgImage} style={{
                            backgroundImage: `url(${bg})`
                        }}/>
                    </div>
                    <div className={styles.col}>
                        <div className={isRu ? styles.box : styles.boxHide}>
                            <ReadOnlyText
                                initialValue={item?.description.ru}/>
                        </div>
                        <div className={!isRu ? styles.box : styles.boxHide}>
                            <ReadOnlyText
                                initialValue={item?.description.en}/>
                        </div>
                        {/*{*/}
                        {/*    isRu*/}
                        {/*    ? (*/}
                        {/*        <>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                <strong>*/}
                        {/*                    Натальная карта или карта вашего рождения*/}
                        {/*                </strong>*/}
                        {/*                - это ваш личный гороскоп, сценарий вашей жизни:*/}
                        {/*                карма, дхарма, партнеры, отношения, брак, дети, Род, деньги, все ваши предназначения, цели*/}
                        {/*                жизни, цели Души, здоровье, болезни, благоприятные и неблагоприятные периоды, лучшее для вас*/}
                        {/*                место жительства и вся основная информация про вас, вашу жизнь и потенциальные возможности*/}
                        {/*                реализации.*/}
                        {/*            </p>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                <strong>Чтобы попасть ко мне в очередь на консультацию, вам необходимо прислать:</strong>*/}
                        {/*            </p>*/}
                        {/*            <ul className={styles.list}>*/}
                        {/*                <li className={styles.list__li}>*/}
                        {/*                    <p>данные вашего рождения: время, дату, место;</p>*/}
                        {/*                </li>*/}
                        {/*                <li className={styles.list__li}>*/}
                        {/*                    <p>ваши фамилию, имя и отчество;</p>*/}
                        {/*                </li>*/}
                        {/*                <li className={styles.list__li}>*/}
                        {/*                    <p>вашу фотографию (только лицо крупным планом во весь кадр, глаза на фотографии должны смотреть прямо в камеру);</p>*/}
                        {/*                </li>*/}
                        {/*                <li className={styles.list__li}>*/}
                        {/*                    <p>четко сформулированный запрос (причину обращения за*/}
                        {/*                        консультацией).</p>*/}
                        {/*                </li>*/}

                        {/*            </ul>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                Я делаю вашу натальную карту, работаю с ней, далее мы проводим консультацию в ранние утренние часы (это самое благое время для этой работы) в онлайн формате*.*/}
                        {/*            </p>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                Вы ОБЯЗАТЕЛЬНО записываете консультацию на диктофон, она длится 2-3 часа. Эта запись остается у вас, и вы в дальнейшем будете сами с ней работать, будете ее слушать столько раз, сколько это потребуется.*/}
                        {/*            </p>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                На консультации вы получите конкретные рекомендации, инструменты, техники и методы коррекции негативных тенденций, и всю необходимую информацию об этом, а также я отвечу на все ваши вопросы.*/}
                        {/*            </p>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                <strong>Стоимость: 30 000 рублей.</strong> Оплачивается непосредственно перед консультацией.Стоимость: 30 000 рублей. Оплачивается непосредственно перед консультацией.*/}
                        {/*            </p>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                Далее, по вашему запросу (по натальной карте, с учетом всех ее особенностей, слабых и сильных аспектов) я составляю индивидуально вашу персональную практику йоги на 1 год на каждый день. За практикой стоит обращаться ко мне, только если вы готовы к ежедневной, без перерывов, садхане на коврике не менее одного часа в течение 1 года.*/}
                        {/*            </p>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                <strong>Стоимость: 10 000 рублей.</strong>*/}
                        {/*            </p>*/}
                        {/*            <p className={styles.text}>*/}
                        {/*                <i>*/}
                        {/*                    * На день консультации лучше не планировать других дел. В этот день важно побыть с собой в тишине, дать себе время усвоить информацию, отдохнуть.*/}
                        {/*                </i>*/}
                        {/*            </p>*/}
                        {/*        </>*/}
                        {/*        ): (*/}
                        {/*            <>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    <strong>*/}
                        {/*                        Natal chart or chart of your birth*/}
                        {/*                    </strong>*/}
                        {/*                    - this is your personal horoscope, the scenario of your life:*/}
                        {/*                    karma, dharma, partners, relationships, marriage, children, gender, money, all your destinations, goals*/}
                        {/*                    life, goals of the Soul, health, illness, favorable and unfavorable periods, the best for you*/}
                        {/*                    place of residence and all the basic information about you, your life and potential opportunities*/}
                        {/*                    implementation.*/}
                        {/*                </p>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    <strong>To get on the waiting list with me, you need to send:</strong>*/}
                        {/*                </p>*/}
                        {/*                <ul className={styles.list}>*/}
                        {/*                    <li className={styles.list__li}>*/}
                        {/*                        <p>your birth data: time, date, place;</p>*/}
                        {/*                    </li>*/}
                        {/*                    <li className={styles.list__li}>*/}
                        {/*                        <p>your last name, first name and patronymic;</p>*/}
                        {/*                    </li>*/}
                        {/*                    <li className={styles.list__li}>*/}
                        {/*                        <p>your photo (only the face is close-up in the whole frame, the eyes in the photo should look directly into the camera);</p>*/}
                        {/*                    </li>*/}
                        {/*                    <li className={styles.list__li}>*/}
                        {/*                        <p>Clearly articulated request (reason for applying for*/}
                        {/*                            consultation).</p>*/}
                        {/*                    </li>*/}

                        {/*                </ul>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    I make your natal chart, work with it, then we conduct a consultation in the early morning hours (this is the best time for this work) in an online format*.*/}
                        {/*                </p>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    You MUST record the consultation on a dictaphone, it lasts 2-3 hours. This recording remains with you, and in the future you will work with it yourself, you will listen to it as many times as necessary.*/}
                        {/*                </p>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    At the consultation, you will receive specific recommendations, tools, techniques and methods for correcting negative trends, and all the necessary information about this, as well as I will answer all your questions.*/}
                        {/*                </p>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    <strong>Cost: 30,000 rubles.</strong> To be paid immediately before the consultation. Cost: 30,000 rubles. Paid directly before the consultation.*/}
                        {/*                </p>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    Further, at your request (according to the natal chart, taking into account all its features, weak and strong aspects), I individually compose your personal yoga practice for 1 year for every day. For practice, you should contact me only if you are ready for daily, without interruption, sadhana on the mat for at least one hour for 1 year.*/}
                        {/*                </p>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    <strong>Cost: 10,000 rubles.</strong>*/}
                        {/*                </p>*/}
                        {/*                <p className={styles.text}>*/}
                        {/*                    <i>*/}
                        {/*                        * On the day of the consultation, it is better not to plan other things. On this day, it is important to be with yourself in silence, give yourself time to assimilate information, relax.*/}
                        {/*                    </i>*/}
                        {/*                </p>*/}
                        {/*            </>*/}
                        {/*        )*/}
                        {/*}*/}
                        <a href={'https://t.me/Julia_Aum'} className={styles.link}>
                            {t('consultation.button')}
                            <span className={styles.link_line} />
                        </a>
                    </div>
                </div>
            </div>
        </Main>
    );
};
