import {createSlice} from '@reduxjs/toolkit';
import {defaultPostCategory, IPostCategoryState} from './types';

const initialState: IPostCategoryState = {
    category: [],
    currentPostCategory: defaultPostCategory
};

const PostCategorySlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        setPostsCategory: (state, {payload}) => {
            state.category = payload;
        },
        setPostCategory: (state, {payload}) => {
            state.currentPostCategory = payload;
        },
        clearCurrentPostCategory: (state) => {
            state.currentPostCategory = defaultPostCategory;
        }
    }
});

export const {
    setPostsCategory,
    setPostCategory,
    clearCurrentPostCategory
} = PostCategorySlice.actions;

export default PostCategorySlice.reducer;
