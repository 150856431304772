
export interface ITemplateNaneProps {

}

export interface IRichTextState {

}


export const defaultValueRichText:any[] =  [
    {
        type: 'paragraph',
        children: [
            { text: '' }
        ],
    },
];
