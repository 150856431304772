import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {value} from '../../fields/Input';
import {useAuth} from '../../hooks';
import styles from './style.module.scss';

interface IToolpitProps {
    uid: string;
    textRu: value;
    textEn: value;
    onClick: () => void;
}

export const Tooltip = ({uid, textEn, textRu, onClick}: IToolpitProps) => {
    const {i18n, t} = useTranslation();
    const {isAuth} = useAuth();
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    return (
        <span
            key={uid}
            className={styles.tooltip}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onClick={onClick}
        >
            {i18n.language === 'ru' ? textRu : textEn}
            {showTooltip ?
                <span
                    className={styles.tooltip__item}
                >{isAuth ? t('mainCourse.access') : t('auth.login')}
                </span>
                : null}
        </span>
    );
};
