import React, {useState} from 'react';
import {InputAdornment} from '@mui/material';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { sendPasswordResetEmail } from 'firebase/auth';
import {CustomButton, Input} from '../../fields';
import mailIcon from '../../assets/icons/envelope.svg';
import {ButtonVariables} from '../../fields/CustomButton';
import {value} from '../../fields/Input';
import {AuthLayout} from '../../layouts';
import {auth} from '../../firebase';
import returnIcon from '../../assets/icons/return.svg';
import homeIcon from '../../assets/icons/home.svg';
import styles from './Login.module.scss';

export const ResetPasswordPage = () => {
    const {t} = useTranslation();
    const location: any = useLocation();
    const [email, setEmail] = useState<value>('');
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };
    const handleSubmit = async (email: value) => {
        await sendPasswordResetEmail(auth, email as string);
        localStorage.setItem('service', JSON.stringify({state: 'resetPassword'}));
        alert(t('app.password-alert'));
    };
    return (
        <AuthLayout>
            <div className={styles.login}>
                <div className={styles.form}>
                    <p className={styles.form__title}>
                        <span>{t('auth.forgotTitle')}</span>
                    </p>
                    <p className={styles.form__text}>{t('auth.forgotText')}</p>
                    <div className={styles.form__box}>
                        <Input
                            type={'email'}
                            placeholder={`${t('auth.email')}:`}
                            defaultValue={email}
                            handleChangeValue={value => setEmail(value)}
                            icon={
                                <InputAdornment position="start">
                                    <img style={{width: '20px'}} src={mailIcon} alt={'key'}/>
                                </InputAdornment>
                            }
                        />
                    </div>
                    <div className={styles.form__box}>
                        <CustomButton
                            handleClick={() => handleSubmit(email)}
                            variant={ButtonVariables.contained}
                            sx={{
                                padding: '7px 25px'
                            }}
                        >
                            {t('auth.forgotButton')}
                        </CustomButton>
                    </div>

                    <NavLink to={'/login'} state={{redirect: location.state?.redirect}}
                             className={styles.login__link}>
                        {t('auth.login')}
                    </NavLink>
                    <div className={styles.breadCrumbs}>
                        <button onClick={handleGoBack}>
                            <img src={returnIcon} className={styles.breadCrumbs__icon} alt={'home'} />
                        </button>
                        <NavLink to={'/'}>
                            <img src={homeIcon} className={styles.breadCrumbs__icon} alt={'home'} />
                        </NavLink>
                    </div>
                </div>
            </div>
        </AuthLayout>
    );
};
