import React, {useEffect, useState} from 'react';
import Select  from 'react-select';

const customStyles = {
   input: () => ({
       display: 'none'
   }),
    control:() => ({
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '3px',
        display: 'flex',
        padding: '1px 5px'
    })
};

export interface IOption {
    value: string;
    label:string;
}

interface ICustomSelectProps {
    options:IOption[];
    handler:(option:IOption) => void;
    className: string;
    defaultValue: string;
}

export const CustomSelect = ({className, handler, options, defaultValue}:ICustomSelectProps) => {

    const [value, setValue] = useState<IOption>();

    useEffect(() => {
        const defaultOption = options.filter(i => i.value === defaultValue).pop() as IOption;
        setValue(defaultOption);
    }, [defaultValue, options]);

    const handleChange = (item:any) => {
        handler(item);
        setValue(item);
    };

    return (
        <Select
            className={className}
            options={options}
            styles={customStyles}
            value={value}
            //defaultValue={options.filter(i => i.value === defaultValue)}
            onChange={(newValue, actionMeta) => handleChange(newValue)}
            closeMenuOnSelect={true}
            closeMenuOnScroll={true}
        />
    );
};
