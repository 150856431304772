import {IShastrCategory} from '../../models/Shastr';


export const defaultShastrCategory:IShastrCategory = {
    name: {ru: '', en: ''},
    serialNumber: 0,
};

export interface IShastrCategoryState {
    shastrCategory: IShastrCategory[]
    currentShastrCategory : IShastrCategory
}
