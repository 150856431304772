import React, {useEffect, useState} from 'react';
import styles from '../Shastr.module.scss';
import {CustomButton, CustomSelect, Input} from '../../../fields';
import {RichText} from '../../../components/RichText';
import {ButtonVariables} from '../../../fields/CustomButton';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {value} from '../../../fields/Input';
import {IPost} from '../../../models/Post';
import {Reader} from '../../../components/Reader';
import {IOption} from '../../../fields/CustomSelect';
import {createShastr, updateShastr} from '../api';
import {setShasters} from '../Shastr.slice';

interface IFormProps {
    handleBack: () => void;
    setLoader: () => void;
}

export const Form = ({handleBack, setLoader}: IFormProps) => {
    const dispatch = useAppDispatch();
    const {currentShastr} = useAppSelector(state => state.admin.shastrs);
    const {shastrCategory} = useAppSelector(state => state.admin.shastrsCategory);

    const [catOptions, setCatOptions] = useState<IOption[]>([]);

    const [nameRU, setNameRU] = useState<value>(currentShastr.name.ru);
    const [nameEN, setNameEN] = useState<value>(currentShastr.name.en);

    const [descriptionRU, setDescriptionRU] = useState<string>(currentShastr.description.ru);
    const [descriptionEN, setDescriptionEN] = useState<string>(currentShastr.description.en);

    const [selectedCategory, setSelectedCategory] = useState<string>(currentShastr.category);

    const [base64, setBase64] = useState<any>(currentShastr.urlImage);

    useEffect(() => {

            const options = shastrCategory.map(i => {
                return {
                    value: i.uid ? i.uid : '',
                    label: i.name.ru.toString()
                };
            });

            setCatOptions(options);
    }, []);

    const submit = async () => {
        setLoader();
        try {
            const post: IPost = {
                name: {
                    ru: nameRU,
                    en: nameEN
                }, description: {
                    ru: descriptionRU,
                    en: descriptionEN,
                }, category: selectedCategory
            };

            const posts = currentShastr.uid
                ? await updateShastr({post: {...post, uid: currentShastr.uid}, base64})
                : await createShastr({post, base64});
            dispatch(setShasters(posts));
            handleBack();
        } catch (e) {

        }
    };
    return (
        <>
            <div className={styles.post__row}>
                <div className={styles.post__colM}>
                    <div className={styles.post__box}>
                        <Input
                            label={'Название ru'}
                            defaultValue={nameRU}
                            handleChangeValue={setNameRU}
                            type={'text'}/>
                    </div>
                    <div className={styles.post__box}>
                        <Input
                            label={'Название en'}
                            defaultValue={nameEN}
                            handleChangeValue={setNameEN}
                            type={'text'}/>
                    </div>
                </div>
                <div className={styles.post__colM}>
                    <div className={styles.post__box}>
                        <CustomSelect options={catOptions} handler={(option) => setSelectedCategory(option.value)}
                                      className={''} defaultValue={currentShastr.category}/>
                    </div>
                </div>
            </div>
            <div className={styles.post__row}>
                <div className={styles.post__colM}>
                    <Reader handleReader={setBase64} buttonText={'Загрузите фото'} defaultBase64={currentShastr.urlImage}/>
                </div>
            </div>
            <div className={styles.post__box}>
                <label className={styles.post__label}>Описание ru</label>
                <RichText handleChange={setDescriptionRU} data={descriptionRU.toString()} height={150}/>
            </div>
            <div className={styles.post__box}>
                <label className={styles.post__label}>Описание en</label>
                <RichText handleChange={setDescriptionEN} data={descriptionEN.toString()} height={150}/>
            </div>
            <CustomButton
                style={{marginTop: '20px'}}
                variant={ButtonVariables.contained}
                handleClick={submit}>Сохранить изменения
            </CustomButton>
        </>
    );
};

export default Form;
