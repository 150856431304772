import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {InputAdornment} from '@mui/material';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import keyIcon from '../../../assets/icons/key.svg';
import mailIcon from '../../../assets/icons/envelope.svg';
import styles from '../Login.module.scss';
import {CustomButton, Input} from '../../../fields';
import {value} from '../../../fields/Input';
import {ButtonVariables} from '../../../fields/CustomButton';
import returnIcon from '../../../assets/icons/return.svg';
import homeIcon from '../../../assets/icons/home.svg';
import {useAppSelector} from '../../../hooks';

interface IFormProps {
    handleSubmit: (email: value, password: value) => void;
}

export const Form = ({handleSubmit}: IFormProps) => {
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const location: any = useLocation();
    const navigate = useNavigate();
    const [email, setEmail] = useState<value>('');
    const [password, setPassword] = useState<value>('');
    const [passwordSwitchType, setSwitchType] = useState<boolean>(true);
    const {loginTitle} = useAppSelector(state => state.front.app);

    const handleGoBack = () => {
        if (location.state?.banner) {
            navigate('/courses/all');
        } else {
            navigate(-1);
        }
    };

    return (
        <div className={styles.form}>
            <p className={styles.form__title}>
                {i18n.language === 'ru' ? loginTitle.ru : loginTitle.en}
            </p>
            <p className={styles.form__text}>{t('auth.text')}</p>
            <div className={styles.form__box}>
                <Input
                    type={'email'}
                    placeholder={`${t('auth.email')}:`}
                    defaultValue={email}
                    handleChangeValue={value => setEmail(value)}
                    icon={
                        <InputAdornment position="start">
                            <img style={{width: '20px'}} src={mailIcon} alt={'key'}/>
                        </InputAdornment>
                    }
                />
            </div>
            <div className={styles.form__box}>
                <Input
                    type={passwordSwitchType ? 'password' : 'text'}
                    placeholder={`${t('auth.password')}:`}
                    defaultValue={password}
                    handleChangeValue={value => setPassword(value)}
                    icon={
                        <InputAdornment
                            position="start"
                            sx={{cursor: 'pointer'}}
                            onClick={() => setSwitchType(!passwordSwitchType)}>
                            <img style={{width: '20px'}} src={keyIcon} alt={'key'}/>
                        </InputAdornment>
                    }
                />
            </div>
            <div className={styles.form__box}>
                <CustomButton
                    handleClick={() => handleSubmit(email, password)}
                    variant={ButtonVariables.contained}
                    sx={{
                        padding: '7px 25px'
                    }}
                >
                    {t('auth.buttonTextLogin')}
                </CustomButton>
            </div>

            <NavLink to={'/registration'} state={{redirect: location.state?.redirect}}
                     className={styles.login__link}>
                {t('auth.registration')}
            </NavLink>
            <NavLink to={'/reset-password'} className={styles.login__link}>
                {t('auth.forgotPassword')}
            </NavLink>
            <div className={styles.breadCrumbs}>
                 <button onClick={handleGoBack}>
                    <img src={returnIcon} className={styles.breadCrumbs__icon} alt={'home'} />
                </button>
                <NavLink to={'/'}>
                    <img src={homeIcon} className={styles.breadCrumbs__icon} alt={'home'} />
                </NavLink>
            </div>
        </div>
    );
};
