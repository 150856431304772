import {doc, getDoc, collection, getDocs} from 'firebase/firestore';
import {defaultCourse, ICourse} from '../../../models/Course';
import {firestore} from '../../../firebase';
import {ContentState} from '../types';
import {defaultStage, IStage} from '../../../models/Stage';
import {ILessonsIn} from '../../../models/lesson';
import { sortBySerialNumber } from '../../../helpers/sortBySerialNumber';

export const getLessons = async () => {
    const AllLessons: any = [];
    const docRef = collection(firestore, 'lessons');
    const docs = await getDocs(docRef);
    docs.forEach((doc) => {
        const uid = doc.id;
        AllLessons.push(uid);
    });
    return AllLessons;
};

export const getTemporaryData = async (uid: string) => {
    let temporaryCourse:ICourse = defaultCourse;
    const content:ContentState[] = [];
    const AllLessons = await getLessons();

    const washingtonRef = doc(firestore, `courses/${uid}`);
    const docSnap = await getDoc(washingtonRef);
    if (docSnap.exists()) {
       temporaryCourse = {...docSnap.data(), uid: docSnap.id} as ICourse;
    }

    for (const stageUid of temporaryCourse.stages) {
        let stage:IStage = defaultStage;
        const washingtonRef = doc(firestore, `stages/${stageUid}`);
        const docSnap = await getDoc(washingtonRef);
        if (docSnap.exists()) {
            const data = docSnap.data() as IStage;
            stage = {...data, uid: docSnap.id};
        }

        if(stage?.lessonsIn?.length > 0) {
            const lessons: ILessonsIn[] = [];
            for (const lessonUid of stage?.lessonsIn as []) {
                if (AllLessons.includes(lessonUid)) {
                    const lessonRef = doc(firestore, `lessons/${lessonUid}`);
                    const docSnap = await getDoc(lessonRef);
                    if (docSnap.exists()) {
                        const data = docSnap.data() as ILessonsIn;
                        lessons.push({...data, uid: docSnap.id});
                    }
                }
            }

            // Sort lessons by ascending SerialNumber
            lessons.sort(sortBySerialNumber);

            content.push({...stage, lessons});
        }
    }

    // Sort stages by ascending SerialNumber
    content.sort((a, b) => a.serialNumber - b.serialNumber);

    return {content, temporaryCourse};
};
