import {createSlice} from '@reduxjs/toolkit';
import {defaultStage} from '../../models/Stage';
import {IStageState} from './types';

const initialState:IStageState = {
    all: [],
    currentStage: defaultStage
};

const StageSlice = createSlice({
    name: 'stage',
    initialState,
    reducers:{
        setStages: (state, {payload}) => {
            state.all = payload;
        },
        setCurrentStage: (state, {payload}) => {
            state.currentStage = payload;
        },
        clearCurrentStage: (state) => {
            state.currentStage = defaultStage;
        }
    }
});

export const {clearCurrentStage, setStages, setCurrentStage} = StageSlice.actions;

export default StageSlice.reducer;
