import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Main} from '../../layouts';
import {useAppDispatch} from '../../hooks';
import {AppLoader} from '../../components/AppLoader';
import {BreadCrumbs} from '../../components/BreadCrumbs';
import styles from './AllCourses.module.scss';
import {getTemporaryCourse} from './api';
import {
    setFreeCourses,
    setFreeLessons,
    setFreeStages,
    setTemporaryCourse,
    setTemporaryCoursesNotBanner
} from './AllCourses.slice';
import {FreeLessonsList, TemporaryNotBanner} from './components';

export const AllCourses = () => {
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru';
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [noAccess, setNoAccess] = useState<boolean>(false);
    useEffect(() => {
        setLoading(true);
        getTemporaryCourse()
            .then((
                {
                    temporaryCourse,
                    freeLessons,
                    freeCourses,
                    stages,
                    temporaryCoursesNotBanners
                }
            ) => {
                dispatch(setTemporaryCourse(temporaryCourse));
                dispatch(setTemporaryCoursesNotBanner(temporaryCoursesNotBanners));
                dispatch(setFreeLessons(freeLessons));
                dispatch(setFreeCourses(freeCourses));
                dispatch(setFreeStages(stages));

                setLoading(false);
            }).catch(e => {
            setNoAccess(true);
            setLoading(false);
        });
        return () => {
            setNoAccess(false);
        };
    }, []);

    return (
        <Main>
            <div className={styles.allCourses}>
                {loading
                    ? <AppLoader loading={loading}/>
                    : noAccess ? (
                        <p className={styles.noAccessText}>{isRu
                            ? 'Не удалось загрузить данные.'
                            : 'Failed to load data.'}</p>
                    ) : (
                        <>
                            
                            <TemporaryNotBanner/>
                            <FreeLessonsList/>
                        </>
                    )

                }
            </div>
            <BreadCrumbs isBack={false}/>
        </Main>
    );
};

