import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import {ref, uploadString} from 'firebase/storage';
import {firestore, storage} from '../../../firebase';
import {ILessonsIn} from '../../../models/lesson';
import {IStage} from '../../../models/Stage';

async function uploadFile(base64: string, postUid: string) {
    try {
        const storageRef = ref(storage, `gs://djsya-web-app.appspot.com/${postUid}`);
        await uploadString(storageRef, base64, 'data_url');
    } catch (e) {
    }
};

export const getLessons = async () => {
    const lessons: ILessonsIn[] = [];
    const docRef = collection(firestore, 'lessons');
    const docs = await getDocs(docRef);
    docs.forEach((doc) => {
        const data = doc.data() as ILessonsIn;
        const uid = doc.id;
        lessons.push({...data, uid});
    });
    return lessons;
};


export const createLesson = async (lesson: ILessonsIn, base64?: string, base64EN?: string) => {
    const lessonRef = await addDoc(collection(firestore, 'lessons'), {...lesson, create_as: Date.now()});
    base64 !== undefined && await uploadFile(base64 as string, lessonRef.id);
    base64EN !== undefined && await uploadFile(base64EN as string, `en-${lessonRef.id}`);
    const washingtonRef = doc(firestore, `stages/${lesson.belonging}`);
    const docSnap = await getDoc(washingtonRef);
    if (docSnap.exists()) {
        const {lessonsIn = []} = docSnap.data() as IStage;
        await updateDoc(washingtonRef, {
            lessonsIn: [...lessonsIn, lessonRef.id]
        });
    }
    return await getLessons();
};

export const updateLesson = async (lesson: ILessonsIn, uid: string, base64?: string, base64EN?: string, changedCourse: boolean = false) => {
    try {
        if (changedCourse) {
            // удаляем урок у всех уровней
            const allStageRef = collection(firestore, 'stages');
            const q = query(allStageRef, where('belonging', '==', uid));
            const docs = await getDocs(q);
            docs.forEach((doc) => {
                const stage = doc.data();
                updateDoc(doc.ref, {
                    'lessonsIn': stage.lessonsIn.filter((i: string) => i !== uid),
                });
            });

            // добавляем урок к нужному уровню
            const stageRef = doc(firestore, `stages/${lesson.belonging}`);
            const docSnap = await getDoc(stageRef);
            if (docSnap.exists()) {
                const {lessonsIn = []} = docSnap.data() as IStage;
                if(!lessonsIn.includes(uid)) {
                    await updateDoc(stageRef, {
                        lessonsIn: [...lessonsIn, uid]
                    });
                }

            }
        }

        // обновляем сам урок
        const washingtonRef = doc(firestore, `lessons/${uid}`);
        await updateDoc(washingtonRef, {
            ...lesson
        });
            
        base64 !== undefined && await uploadFile(base64 as string, uid);
        base64EN !== undefined && await uploadFile(base64EN as string, `en-${uid}`);
    } catch (e) {

    }

    return await getLessons();
};

export const deleteLesson = async (uid: string, belonging: string) => {
    await deleteDoc(doc(firestore, 'lessons', uid));
    const washingtonRef = doc(firestore, `stages/${belonging}`);
    const docSnap = await getDoc(washingtonRef);
    if (docSnap.exists()) {
        const {lessonsIn = []} = docSnap.data() as IStage;
        // @ts-ignore
        const newArr = lessonsIn.filter(i => i !== uid);
        await updateDoc(washingtonRef, {
            lessonsIn: newArr
        });
    }
    return await getLessons();
};



