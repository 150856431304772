import {doc, getDoc} from 'firebase/firestore';
import {firestore} from '../../../firebase';
import {IUser} from '../../../models/User';

export const loginUser = async (user: any) => {

    const washingtonRef = doc(firestore, `users/${user.uid}`);
    try {
        const docSnap = await getDoc(washingtonRef);

        if (docSnap.exists()) {
            return docSnap.data() as IUser;
        } else {
            return null;
        }
    } catch (e) {
        throw e;
    }
};
