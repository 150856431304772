import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {PanelLayout} from '../../layouts';
import {ICourse} from '../../models/Course';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {AppLoader} from '../../components/AppLoader';
import {ConfirmationModal} from '../../components/ConfirmationModal';
import styles from './Course.module.scss';
import {clearCurrentCourse, setCourses, setCurrentCourse} from './Course.slice';
import {List} from './components/List';
import {Form} from './components';
import {deleteCourse, getCourses} from './api';


export const Course = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {all} = useAppSelector(state => state.admin.course);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [currentUid, setCurrentUid] = useState<string>('');

    useEffect(() => {
        setLoading(true);
        getCourses().then(courses => dispatch(setCourses(courses)));
        setLoading(false);
    }, [dispatch]);

    const handleUpdate = async (uid: string) => {
        setLoading(true);

        const current = all.find(i => i.uid === uid) as ICourse;
        dispatch(setCurrentCourse(current));
        setShowForm(true);
        setLoading(false);
    } ;

    const handleDelete = async (uid: string) => {
        setLoading(true);
        const course = all.find(i => i.uid === uid) as ICourse;

        await deleteCourse(uid, course.isMain);
        const courses = await getCourses();
        dispatch(setCourses(courses));
        setLoading(false);
    };

    const handleBack = useCallback(() => {
        dispatch(clearCurrentCourse());
        setShowForm(false);
    }, [dispatch]);

    const handleCreated = useCallback(async () => {
        handleBack();
        setLoading(false);
    }, [dispatch, handleBack]);

    return (
        <PanelLayout>
            <>
                <AppLoader loading={loading}/>
                <div className={styles.course}>
                    <div className={styles.course__header}>
                        <h1 className={styles.course__title}>{t('course.title')}</h1>
                        {!showForm && <Button
                            variant={'contained'}
                            className={styles.course__createBtn}
                            onClick={() => setShowForm(true)}
                        >Создать курс</Button>}
                    </div>
                    {
                        showForm
                            ? (<div>
                                <Button variant={'text'} startIcon={<ChevronLeftIcon/>} onClick={handleBack}>Назад</Button>
                                <Form handleBack={handleCreated} setLoader={() => setLoading(true)}/>
                            </div>)
                            : (
                                <List
                                    items={all}

                                    handleDelete={uid => {
                                        setOpenConfirmation(true);
                                        setCurrentUid(uid);
                                    }}
                                    handleUpdate={handleUpdate}
                                />
                            )
                    }
                </div>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    text={'Если вы удалите этот курс, то удалятся все принадлежавшие к нему уровни и уроки'}
                    handleDelete={() => handleDelete(currentUid)}/>
            </>
        </PanelLayout>
    );
};

