import {ref, uploadString, getDownloadURL, deleteObject, listAll} from 'firebase/storage';
import {addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import {firestore, storage} from '../../../firebase';
import {IShastrCategory} from '../../../models/Shastr';

async function uploadFile(base64: string, postUid: string) {
    try {
        const storageRef = ref(storage, `gs://djsya-web-app.appspot.com/${postUid}`);
        await uploadString(storageRef, base64, 'data_url');
    } catch (e) {
    }
};


export const getShastrCategory = async () => {
    let posts: IShastrCategory[] = [];
    const docRefCategory = collection(firestore, 'shastrCategory');

    const docsCat = await getDocs(docRefCategory);
    docsCat.forEach((doc) => {
        const data = doc.data() as IShastrCategory;
        const uid = doc.id;
        posts.push({...data, uid});
    });

    for await (const post of posts) {
        const urlImage = await getDownloadURL(ref(storage, post.uid)).then(url => url.toString()).catch(e => {
            return '';
        });
        posts = posts.map(p => p.uid === post.uid ? {...p, urlImage} : p);
    }

    const data = posts.sort(function (a, b) {
        const i = a.serialNumber as number;
        const n = b.serialNumber as number;
        return i - n;
    });

    return data;
};

export const createShastrCategory = async ({shastrCategory, base64}: { shastrCategory: IShastrCategory, base64: string }) => {
    try {
        const postRef = await addDoc(collection(firestore, 'shastrCategory'), {...shastrCategory, create_as: Date.now()});
        await uploadFile(base64, postRef.id);
    } catch (e) {
    }
    return await getShastrCategory();
};

export const updateShastrCategory = async ({shastrCategory, base64}: { shastrCategory: IShastrCategory, base64: string }) => {
    try {
        await uploadFile(base64, shastrCategory.uid as string);
        const washingtonRef = await doc(firestore, `shastrCategory/${shastrCategory.uid}`);
        await updateDoc(washingtonRef, {
            ...shastrCategory
        });
        return await getShastrCategory();
    } catch (e) {
    }

};

export const deleteShastrCategory = async (uid: string) => {
    const desertRef = ref(storage);
    listAll(desertRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                if(itemRef.fullPath === uid) {
                    deleteObject(desertRef);
                }
            });
        }).catch(e => {});

    await deleteDoc(doc(firestore, 'shastrCategory', uid));

    const stageRef = collection(firestore, 'shastrs');
    const q = query(stageRef, where('category', '==', uid));
    const docs = await getDocs(q);
    docs.forEach((d) => {
        deleteDoc(doc(firestore, 'shastrs', d.id));
    });

    return await getShastrCategory();
};
