import {doc, getDoc} from 'firebase/firestore';
import {firestore} from '../../../firebase';
import {IUser} from '../../../models/User';

export const getUser = async (uid:string | null) => {
    const docRef = doc(firestore, `users/${uid}`);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data() as IUser;
    } else {
        return null;
    }
};
