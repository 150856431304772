import {ref, uploadString, getDownloadURL, deleteObject, listAll} from 'firebase/storage';
import {addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where} from 'firebase/firestore';
import {IPostCategory} from '../../../models/Post';
import {firestore, storage} from '../../../firebase';

async function uploadFile(base64: string, postUid: string) {
    try {
        const storageRef = ref(storage, `gs://djsya-web-app.appspot.com/${postUid}`);
        await uploadString(storageRef, base64, 'data_url');
    } catch (e) {
    }
};


export const getPostsCategory = async () => {
    let posts: IPostCategory[] = [];
    const docRefCategory = collection(firestore, 'postsCategory');

    const docsCat = await getDocs(docRefCategory);
    docsCat.forEach((doc) => {
        const data = doc.data() as IPostCategory;
        const uid = doc.id;
        posts.push({...data, uid});
    });

    for await (const post of posts) {
        const urlImage = await getDownloadURL(ref(storage, post.uid))
            .then(url => {
                return  url.toString();
            })
            .catch(e => {
                return '';
            });
        posts = posts.map(p => p.uid === post.uid ? {...p, urlImage} : p);
    }

    const data = posts.sort(function (a, b) {
        const i = a.serialNumber as number;
        const n = b.serialNumber as number;
        return i - n;
    });
    return data;
};

export const createPostCategory = async ({postCategory, base64}: { postCategory: IPostCategory, base64: string }) => {
    try {
        const postRef = await addDoc(collection(firestore, 'postsCategory'), {...postCategory, create_as: Date.now()});
        if(base64.length > 0) {
            await uploadFile(base64, postRef.id);
        }
        return await getPostsCategory();
    } catch (e) {
    }
    return await getPostsCategory();
};

export const updatePostCategory = async ({postCategory, base64}: { postCategory: IPostCategory, base64: string }) => {
    try {
        if(base64.length > 0) {
            await uploadFile(base64, postCategory.uid as string);
        }
        const washingtonRef = await doc(firestore, `postsCategory/${postCategory.uid}`);
        await updateDoc(washingtonRef, {
            ...postCategory
        });
        return await getPostsCategory();
    } catch (e) {
    }

};

export const deletePostCategory = async (uid: string) => {
    const desertRef = ref(storage);
    listAll(desertRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                if(itemRef.fullPath === uid) {
                    deleteObject(desertRef);
                }
            });
        }).catch(e => {});
    await deleteDoc(doc(firestore, 'postsCategory', uid));

    const stageRef = collection(firestore, 'posts');
    const q = query(stageRef, where('category', '==', uid));
    const docs = await getDocs(q);
    docs.forEach((d) => {
        deleteDoc(doc(firestore, 'posts', d.id));
    });

    return await getPostsCategory();
};
