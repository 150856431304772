import {getDatabase, ref, child, remove, get} from 'firebase/database';
import {doc, setDoc, getDoc, collection, getDocs, updateDoc, query} from 'firebase/firestore';
import {value} from '../../../fields/Input';
import {IUser} from '../../../models/User';
import {db, firestore} from '../../../firebase';

export const getUserByEmail = async (email: value) => {
    let user:IUser | null = null;
    const dbRef = ref(getDatabase());
    user = await get(child(dbRef, 'users')).then((snapshot) => {
        if (snapshot.exists()) {
            const val = Object.values(snapshot.val()) as IUser[];
            return val.find(i => i.email === email) as IUser;
        } else {
            return null;
        }
    }).catch();

    const q = query(collection(firestore, 'users'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        const U = doc.data() as IUser;
        if(U.email === email) {
            user = U;
        }
    });

    return user;
};

export const updateUserStatus = async (user: IUser, isUpdate: boolean) => {
    if(isUpdate) {
        const washingtonRef = doc(firestore, `users/${user.uid}`);

         await updateDoc(washingtonRef, {
            ...user
        });

    } else {
        const docRefUsers = doc(firestore, 'users', user.uid);

        await setDoc(docRefUsers, {
            ...user
        });
        const docRef = doc(firestore, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            await remove(ref(db, `users/${user.uid}`));
            return docSnap.data();
        } else {
            return null;
        }
    }
};

export const getAllUsers = async () => {
    const users: IUser[] = [];
    const docRef = collection(firestore, 'users');
    const docs = await getDocs(docRef);
    docs.forEach((doc) => {
        const data = doc.data() as IUser;
        const uid = doc.id;
        if (data.role !== 'ADMIN') {
            users.push({...data, uid});
        }
    });
    return users;
};

export const getUsersIsPaid = async () => {
    const users: IUser[] = [];
    const docRef = collection(firestore, 'users');
    const docs = await getDocs(docRef);
    docs.forEach((doc) => {
        const data = doc.data() as IUser;
        const uid = doc.id;
        if (data.role !== 'ADMIN' && data.isPaid) {
            users.push({...data, uid});
        }
    });
    return users;
};

export const deleteUser = async ({user}:{user: IUser}) => {

    const washingtonRef = doc(firestore, `users/${user.uid}`);
    await updateDoc(washingtonRef, {
        ...user
    });
    // await deleteDoc(doc(firestore, 'users', user.uid));
    // await set(ref(db, 'users/' + user.uid), user);
    return await getUsersIsPaid();
};
