import React from 'react';
import {useTranslation} from 'react-i18next';
import {Main} from '../../layouts';
import styles from './ErrorPage.module.scss';

export const ErrorPage = () => {
    const {t} = useTranslation();
    return (
        <Main>
            <div className={styles.errorPage}>
                <div className={styles.inner}>
                    <h1 className={styles.errorPage__title}>404</h1>
                    <p className={styles.errorPage__text}>{t('app.page-not-found')}</p>
                </div>

            </div>
        </Main>
    );
};

