import React  from 'react';
import {Button, Dialog, DialogTitle, styled, Typography} from '@mui/material';
import {ITemplateNaneProps} from './types';
import styles from './ConfirmationModal.module.scss';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),

    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        padding: '20px',
        maxWidth: '350px'
    }
}));

export const ConfirmationModal = ({open, onClose, handleDelete, text}: ITemplateNaneProps) => {

    return (
        <BootstrapDialog onClose={onClose} open={open}>
                <DialogTitle textAlign={'center'}>Действительно хотите удалить?</DialogTitle>
                <Typography variant="body2" component="p" textAlign={'center'}>
                    {text}
                </Typography>
                <div className={styles.buttons}>
                    <Button variant={'contained'} onClick={() => {
                        onClose();
                        handleDelete();
                    }}>Да я понимаю</Button>
                    <Button variant={'outlined'} onClick={onClose}>Отменить</Button>
                </div>
        </BootstrapDialog>
    );
};

