import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import YouTube, { YouTubeProps } from 'react-youtube';
import {useNavigate} from 'react-router-dom';
import {ContentState} from '../types';
import styles from '../TemporaryCourse.module.scss';
import {ReadOnlyText} from '../../../components/ReadOnlyText';
import {setLesson} from '../../LessonPage/LessonPage.slice';
import {useAppDispatch} from '../../../hooks';
import {ILessonsIn} from '../../../models/lesson';
import {Banner} from './Banner';

export const List = ({item, isRu}: { item: ContentState, isRu: boolean }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleJump = (lesson: ILessonsIn) => {
        dispatch(setLesson(lesson));
        navigate(`lesson/${lesson.uid}`);
    };

    const opts: YouTubeProps['opts'] = {
        height: '373',
        width: '210',
    };

    return (
        <div className={styles.list}>
            {isRu && item.name.ru ? <h3 className={styles.list__title}>{item.name.ru}</h3> : null}
            {!isRu && item.name.en ? <h3 className={styles.list__title}>{item.name.en}</h3> : null}

            {item.description.ru ? 
            <div className={isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                <ReadOnlyText initialValue={item.description.ru}/>
            </div> : null}
            {item.description.en ? 
            <div className={!isRu ? styles.temporaryCourse__box : styles.temporaryCourse__boxHide}>
                <ReadOnlyText initialValue={item.description.en}/>
            </div> : null}
            <div className={styles.list__wrapper}>
                {item.lessons.map((lesson, index) => (
                    <div key={lesson.uid} className={styles.lesson} onClick={() => handleJump(lesson)}>
                        <div className={styles.lesson__videoWrapper}>
                            {(`${lesson.source.ru}`)?.length === 11 ?
                                <YouTube
                                    videoId={(`${lesson.source.ru}`)}
                                    className={styles.video}
                                    opts={opts}
                                />
                                :
                                <Vimeo
                                    video={lesson.source.ru}
                                    className={styles.video}
                                    width={768}
                                    height={768 / 16 * 9}
                                    controls
                                />
                            }
                            <Banner uid={lesson.uid}/>
                        </div>
                        <h4 className={styles.list__title}>
                            {isRu ? lesson.name.ru : lesson.name.en}</h4>

                    </div>
                ))}
            </div>
        </div>
    );
};
