import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {PanelLayout} from '../../layouts';
import {useAppDispatch} from '../../hooks';
import {setCourses} from '../Course/Course.slice';
import {setStages} from '../Stage/Stage.slice';
import {getCourses} from '../Course/api';
import {getStages} from '../Stage/api';
import {getLessons} from '../Lesson/api';
import {setLessons} from '../Lesson/Lesson.slice';
import {getPostsCategory} from '../PostCategory/api';
import {setPostsCategory} from '../PostCategory/PostCategory.slice';
import {getShastrCategory} from '../CategoryShastr/api';
import {setShastrCategorys} from '../CategoryShastr/ShastrCategory.slice';
import {getShastrs} from '../Shastr/api';
import {setShasters} from '../Shastr/Shastr.slice';
import {getPosts} from '../Post/api';
import {setPosts} from '../Post/Post.slice';
import {getConsultation} from '../ConsultationAdmin/api';
import {setConsultation} from '../ConsultationAdmin/ConsultationAdmin.slice';
import styles from './Panel.module.scss';

export const Panel = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        getCourses()
            .then(courses => dispatch(setCourses(courses)));
        getStages()
            .then(stages => dispatch(setStages(stages)));
        getLessons()
            .then(lessons => dispatch(setLessons(lessons)));
        getPostsCategory().then(posts => dispatch(setPostsCategory(posts)));
        getShastrCategory().then(posts => {
            dispatch(setShastrCategorys(posts));
        });
        getShastrs().then(posts => {
            dispatch(setShasters(posts));
        });
        getPosts().then(posts => dispatch(setPosts(posts)));
        getConsultation().then(result => dispatch(setConsultation(result)));
    }, [dispatch]);


    return (
        <PanelLayout>
            <div className={styles.panel}>
                <h1 className={styles.panel__title}>{t('panel.title')}</h1>
            </div>
        </PanelLayout>
    );
};

