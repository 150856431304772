import React, {useState} from 'react';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import classNames from 'classnames';
import {NavPanel} from '../../components/NavPanel';
import styles from './PanelLayout.module.scss';

interface IPanelProps {
    children: JSX.Element
}

export const PanelLayout = ({children}:IPanelProps) => {
    const [open, setOpen] = useState<boolean>(true);
    return (
        <div className={styles.wrapper}>
            <div className={open ? styles.sidebar : classNames(styles.sidebar, styles.sidebar__close)}>
                <NavPanel isOpen={open} />
                <span className={styles.sidebarToggle} onClick={() => setOpen(!open)}>
                    <ViewSidebarIcon />
                </span>
            </div>
            <div className={styles.content}>
                <div className={styles.contentInner}>
                    {children}
                </div>
            </div>
        </div>
    );
};
