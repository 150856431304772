import {createSlice} from '@reduxjs/toolkit';
import {defaultPost, IPostState} from './types';

const initialState:IPostState = {
    posts: [],
    currentPost: defaultPost
};

const PostSlice = createSlice({
    name: 'post',
    initialState,
    reducers:{
        setPosts: (state, {payload}) => {
            state.posts = payload;
        },
        setPost: (state, {payload}) => {
            state.currentPost = payload;
        },
        clearCurrentPost: (state) => {
            state.currentPost = defaultPost;
        }
    }
});

export const {setPost, setPosts, clearCurrentPost} = PostSlice.actions;

export default PostSlice.reducer;
