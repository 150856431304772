import React from 'react';
import styles from '../Shastr.module.scss';
import {IPostCategory} from '../../../models/Post';
import {ListItem} from './ListItem';

interface IListName {
    items: IPostCategory[];
    handleUpdate: (uid: string) => void;
    handleDelete: (uid: string) => void;
}

export const List = ({items = [], handleUpdate, handleDelete}: IListName) => {

    return (
        <ul className={styles.list}>
            {items.map(i =>
                <ListItem
                    key={i.uid}
                    uid={i.uid}
                    name={i.name}
                    serialNumber={i.serialNumber}
                    handleUpdate={handleUpdate}
                    handleDelete={handleDelete}
                    urlImage={i.urlImage}/>
            )}
        </ul>
    );
};

