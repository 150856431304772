import {INavClient} from './types';

export const config:INavClient[] = [
    {name: 'mail', path: '/courses/main'},
    {name: 'all', path: '/courses/all'},
    {name: 'consultation', path: '/consultation'},
    {name: 'posts', path: '/post-category'},
    {name: 'shastrs', path: '/shastr-category'},
    {name: 'day-of-the-week', path: '/content/day-of-the-week'},
    {name: 'gurukula', path: '/content/page-3'},
];

export const configDisabledPaths:string[] = [
    '/content/page-3',
    '/consultation',
];
