import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import ApprovalIcon from '@mui/icons-material/Approval';
import SchoolIcon from '@mui/icons-material/School';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import BookIcon from '@mui/icons-material/Book';
import CategoryIcon from '@mui/icons-material/Category';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CompareIcon from '@mui/icons-material/Compare';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import {getAuth, signOut} from 'firebase/auth';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {setUser} from '../../store/app.slice';
import {userDefault} from '../../models/User';
import {useAppDispatch} from '../../hooks';
import styles from './NavPanel.module.scss';
import {ITemplateNaneProps} from './types';

export const NavPanel = ({isOpen}: ITemplateNaneProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    const handleSignOut = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            dispatch(setUser(userDefault));
            navigate('/login');
        }).catch((error) => {
            // An error happened.
        });
    };
    return (
        <div className={styles.navPanel}>
            <NavLink to={'/panel'} className={styles.navPanel__item}>
                {isOpen ?
                    <span className={styles.navPanel__itemText}>
                        <AdminPanelSettingsIcon sx={{color: '#606060'}}/>
                        Панель
                    </span>
                    : <AdminPanelSettingsIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/course'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <CastForEducationIcon sx={{color: '#606060'}}/>
                        Курс
                </span>
                    : <CastForEducationIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/stage'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <ApprovalIcon sx={{color: '#606060'}}/>
                        Уровень</span> : <ApprovalIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/lesson'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <SchoolIcon sx={{color: '#606060'}}/>
                        Урок</span> : <SchoolIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/users'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <PeopleAltIcon sx={{color: '#606060'}}/>
                        Пользователи</span> : <PeopleAltIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/category-posts'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <CategoryIcon sx={{color: '#606060'}}/>
                        Категории текстов</span> : <CategoryIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/posts'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <BookIcon sx={{color: '#606060'}}/>
                        Тексты</span> : <BookIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/category-shaster'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <CompareIcon sx={{color: '#606060'}}/>
                        Категории шастр</span> : <CompareIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/shaster'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <PsychologyIcon sx={{color: '#606060'}}/>
                        Шастры</span> : <PsychologyIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <NavLink to={'/panel/consultation'} className={styles.navPanel__item}>
                {isOpen
                    ? <span className={styles.navPanel__itemText}>
                        <HeadsetMicIcon sx={{color: '#606060'}}/>
                        Консультация</span> : <HeadsetMicIcon sx={{color: '#606060'}}/>}
            </NavLink>
            <button className={classNames(styles.navPanel__item, styles.navPanel__itemLogout)} onClick={handleSignOut}>
                {isOpen ? t('app.logout') : <LogoutIcon sx={{color: '#606060'}}/>}
            </button>
        </div>
    );
};

