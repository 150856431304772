import {
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Theme,
    useTheme
} from '@mui/material';
import React from 'react';
import Select from '@mui/material/Select';
import {IOption} from './CustomSelect';
import styles from './Fields.module.scss';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface IMuiSelectProps {
    value: string[];
    handleChange: (items: any) => void;
    addTemporary? : (items: any) => void;
    options: IOption[];
    placeholder: string;
}

export const MultipleSelect = ({value, handleChange, addTemporary, options, placeholder}: IMuiSelectProps) => {
    const theme = useTheme();

    return (
        <FormControl sx={{m: 1}}>
            <InputLabel className={styles.input__label} id="demo-multiple-name-label">{placeholder}</InputLabel>
            <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={value}
                onChange={event => {
                    handleChange(event.target.value);
                    if (addTemporary) {
                        addTemporary(event.target.value);
                    }
                }}
                input={<OutlinedInput sx={{
                    fontSize: '16px',
                    paddingBottom: '5px',
                    fontFamily: 'Averta CY sans-serif'
                }} label={placeholder}/>}
                MenuProps={MenuProps}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.label}
                        style={{...getStyles(option.label, value, theme), fontFamily: ''}}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
