import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector, useAuth} from '../../../hooks';
import styles from '../AllCourses.module.scss';
import heart from '../../../assets/icons/heart.svg';
import {Tooltip} from '../../../components/tooltip';
import {changeLoginTitle} from '../../../store/app.slice';
import {setLoginHistoryUrl} from '../../../store/app.slice';

export const TemporaryNotBanner = () => {
    const {i18n} = useTranslation();
    const {temporaryCoursesNotBanner} = useAppSelector(state => state.front.allCourses);
    const {isAuth} = useAuth();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleJump = (uid: string) => {
        navigate(`temporary/${uid}`);
    };

    const handleLogin = (title: object, url: string) => {
        if(!isAuth) {
            changeTitle(title);
            changeUrl(`/courses/all/temporary/${url}`);
            navigate('/login');
        }
    };

    const changeTitle = async (loginTitle: object) => {
        dispatch(changeLoginTitle(loginTitle));
    };

    const changeUrl = async (url: string) => {
        dispatch(setLoginHistoryUrl(url));
    };
    return (
        <ul className={styles.list}>
            {temporaryCoursesNotBanner.map((item) => (
                <li key={item.uid} className={styles.list__item}>
                    <button className={styles.list__link}
                            onClick={() => handleJump(item.uid as string)}
                    >
                        <img style={{width: '30px', height: '30px'}} src={heart} alt={'heart'}/>
                        {
                            isAuth
                                ? <span className={styles.list__linkSpan}>{i18n.language === 'ru' ? item.name.ru : item.name.en}</span>
                                : <Tooltip
                                    key={item.uid}
                                    uid={item.uid as string}
                                    textEn={item.name.en}
                                    textRu={item.name.ru}
                                    onClick={() => handleLogin(item.name as object, item.uid as string)}
                                />
                        }

                        <img style={{width: '30px', height: '30px'}} src={heart} alt={'heart'}/>
                    </button>
                </li>
            ))}
        </ul>
    );
};
