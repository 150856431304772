import {IShastr} from '../../models/Shastr';

export const defaultShastr:IShastr = {
    name: {ru: '', en: ''},
    description:{ru: '', en: ''},
    category: ''
};

export interface IPostState {
    shastrs: IShastr[],
    currentShastr : IShastr
}
