import React, {ReactNode, useState} from 'react';
import {TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import styles from './Fields.module.scss';

export type value = string | number;

interface IInputProps{
    label?: string | null;
    placeholder?: string;
    defaultValue: value;
    handleChangeValue: (value: value) => void;
    type: string;
    icon?: ReactNode;
    error?: boolean;
    onBlur?: () => void;
}

export const Input = (
    {label, placeholder, handleChangeValue, defaultValue, type, icon, error, onBlur}: IInputProps
) => {
    const {t} = useTranslation();
    const [value, setValue] = useState<value>(defaultValue);

    const handleEvent = (value:value) => {
        setValue(value);
        handleChangeValue(value);
    };

    return (
        <div className={styles.input}>
            {label
                ? <label className={styles.input__label}>{label}</label>
                : null}

            <TextField
                size="small"
                type={type}
                className={styles.input__field}
                placeholder={placeholder}
                value={value}
                variant="standard"
                sx={{
                    borderBottom : `1px dashed ${error? 'red' :'#606060'}`,
                    outline: 'none',
                }}
                InputProps={{
                    startAdornment: (icon),
                }}
                onChange={
                    (event: React.ChangeEvent<HTMLInputElement>) =>
                        handleEvent(event.target.value)
                }
                onBlur={onBlur && onBlur}
            />
            {error && <span style={{fontSize: '9px', color: 'red'}}>{t('auth.passwordError')}</span>}
        </div>
    );
};
