import {createSlice} from '@reduxjs/toolkit';
import {defaultConsultation, IConsultationState} from './types';

const initialState:IConsultationState = {
    item: defaultConsultation,
    loading: false
};

const ConsultationAdminSlice = createSlice({
    name: 'consultation',
    initialState,
    reducers:{
        setLoading: (state, {payload}) => {
            state.loading = payload;
        },
        setConsultation: (state, {payload}) => {
            state.item = payload;
            state.loading = false;
        }
    }
});

export const {setConsultation, setLoading} = ConsultationAdminSlice.actions;

export default ConsultationAdminSlice.reducer;
