import {configureStore, combineReducers} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import logger from 'redux-logger';
import adminReducer from '../admin/store/adminReducer';
import courseMain from '../pages/CoursePage/CoursePage.slice';
import allCourses from '../pages/AllCourses/AllCourses.slice';
import lessons from '../pages/LessonPage/LessonPage.slice';
import posts from '../pages/PostPage/PostPage.slice';
import shastrs from '../pages/ShastrPage/ShastrPage.slice';
import app from './app.slice';

const rootReducer = combineReducers({
    app,
    courseMain,
    lessons,
    allCourses,
    posts,
    shastrs
});

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
};

const R = combineReducers({front: rootReducer,admin: adminReducer});


const persistedReducer = persistReducer(persistConfig, R);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(logger),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
