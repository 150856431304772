import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {loadingStart, setLesson} from '../../pages/LessonPage/LessonPage.slice';
import {loadingPost, setCurrentPost} from '../../pages/PostPage/PostPage.slice';
import {setCurrentShastr} from '../../pages/ShastrPage/ShastrPage.slice';
import styles from './Pagination.module.scss';

interface IPaginationProps {
    uid: string;
    type: 'lessons' | 'posts' | 'shastr'
}

export const Pagination = ({uid, type}: IPaginationProps) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {currentLesson, currentLessons} = useAppSelector(state => state.front.lessons);
    const {posts} = useAppSelector(state => state.front.posts);
    const {shastrs} = useAppSelector(state => state.front.shastrs);

    const handlePrev = () => {

        // if (freeLessons.some(i => i.uid === uid)) {
        //     const index = freeLessons.map(i => i.uid).indexOf(currentLesson?.uid);
        //     if (index > 0) {
        //         const current = freeLessons[index - 1];
        //         dispatch(loadingStart());
        //         dispatch(setLesson(current));
        //     }
        // } else

            if (currentLessons.some(i => i.uid === uid)) {
            const index = currentLessons.map(i => i.uid).indexOf(currentLesson?.uid);
            if (index > 0) {
                const current = currentLessons[index - 1];
                dispatch(loadingStart());
                dispatch(setLesson(current));
            }
        } else if (shastrs.some(i => i.uid === uid)) {
            const index = shastrs.map(i => i.uid).indexOf(uid);
            if (index > 0) {
                const current = shastrs[index - 1];
                dispatch(loadingStart());
                dispatch(setCurrentShastr(current));
            }
        }
        else {
            const index = posts.map(i => i.uid).indexOf(uid);
            if (index > 0) {
                const current = posts[index - 1];
                dispatch(loadingPost(true));
                dispatch(setCurrentPost(current));
            }
        }
    };

    const handleNext = () => {
        // if (freeLessons.some(i => i.uid === uid)) {
        //     const index = freeLessons.map(i => i.uid).indexOf(uid);
        //     if (index < (freeLessons.length - 1)) {
        //         const current = freeLessons[index + 1];
        //         dispatch(loadingStart());
        //         dispatch(setLesson(current));
        //     }
        // } else

            if (currentLessons.some(i => i.uid === uid)) {
            const index = currentLessons.map(i => i.uid).indexOf(uid);
            if (index < (currentLessons.length - 1)) {
                const current = currentLessons[index + 1];
                dispatch(loadingStart());
                dispatch(setLesson(current));
            }
        } else if (shastrs.some(i => i.uid === uid)) {
            const index = shastrs.map(i => i.uid).indexOf(uid);
            if (index < (shastrs.length - 1)) {
                const current = shastrs[index + 1];
                dispatch(loadingStart());
                dispatch(setCurrentShastr(current));
            }
        } else {
            const index = posts.map(i => i.uid).indexOf(uid);
            if (index < (posts.length - 1)) {
                const post = posts[index + 1];
                dispatch(loadingPost(true));
                dispatch(setCurrentPost(post));
            }
        }
    };

    return (
        <div className={styles.pagination}>
            <button className={styles.pagination__btn} onClick={handlePrev}>
                {type === 'lessons'
                    ? t('pagination.lessons.prev')
                    : type === 'shastr'
                        ? t('pagination.shastrs.prev')
                        : t('pagination.posts.prev')}
            </button>
            <button className={styles.pagination__btn} onClick={handleNext}>
                {type === 'lessons'
                    ? t('pagination.lessons.next')
                    : type === 'shastr'
                    ? t('pagination.shastrs.next')
                    : t('pagination.posts.next')}
            </button>
        </div>
    );
};

