import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {Main} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {AppLoaderSmall} from '../../components/AppLoader';
import {getShastrCategory} from '../../admin/CategoryShastr/api';
import {loadingPost, setShastrCategory} from './ShastrPage.slice';
import styles from './ShastrPage.module.scss';

export const PublicShastrCategory = () => {
    const {i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {shastrCategory} = useAppSelector(state => state.front.shastrs);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getShastrCategory().then(data => {
            dispatch(setShastrCategory(data));
            setLoading(false);
        });
    }, []);

    const handleJump = (uid: string) => {
        dispatch(loadingPost(true));
        navigate(`/shastrs/${uid}`);
    };

    return (
        <Main>
            <div className={styles.postPage}>
                <ul className={styles.list}>
                    {shastrCategory.length > 0 ? shastrCategory.map(post => (
                        <li key={post.uid} className={styles.list__item}>
                            <div className={styles.card}>
                                <div className={styles.card__img}>
                                    {post.urlImage !== ''
                                        ? <img src={post.urlImage} alt={post.name.en as string}/>
                                        : null}
                                </div>
                                <div className={classNames(styles.card__body, styles.card__bodyNoBg)}>
                                    <h3 className={classNames(styles.card__title, styles.card__titleCat)}
                                        onClick={() => handleJump(post.uid as string)}>{
                                        i18n.language === 'ru' ? post.name.ru : post.name.en
                                    }</h3>
                                    {/*<span onClick={() => handleJump(post.uid as string)} className={styles.card__link}>*/}
                                    {/*    {t('postsCategory.more')}*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        </li>
                    )) : <AppLoaderSmall loading={loading}/>}
                </ul>
            </div>

        </Main>
    );
};
