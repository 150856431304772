import React, {useState} from 'react';
import {updateProfile, createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth';
//import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
// import {useAppDispatch} from '../../hooks';
// import {setUser} from '../../store/app.slice';
import {errorsTypes, errorsTypesEn} from '../../utils/errorsTypes';
import {auth} from '../../firebase';
import {IUser, userDefault} from '../../models/User';
import {value} from '../../fields/Input';
import {AuthLayout} from '../../layouts';
import styles from './Registration.module.scss';
import {Form} from './components';
import {writeUserData} from './api';


export const Registration = () => {
    const {i18n, t} = useTranslation();
    const isRu = i18n.language === 'ru';
    // const dispatch = useAppDispatch();
    // const navigate = useNavigate();
    // const location:any = useLocation();
    const [isError, serError] = useState<string | null>(null);

    const submit = async (email: value, password: value, displayName: value) => {
        try {
            const {user: FUser} = await createUserWithEmailAndPassword(auth, email.toString(), password.toString());
            if (auth.currentUser) {
                await sendEmailVerification(auth.currentUser);
                const createUser: IUser = {
                    ...userDefault,
                    email: FUser.email,
                    displayName: displayName.toString(),
                    account: 'disable',
                    uid: FUser.uid,
                };
                await updateProfile(auth.currentUser, {
                    displayName: displayName.toString(),
                    photoURL: 'disable',
                });
                await writeUserData({user: createUser});
                localStorage.setItem('service', JSON.stringify({state: 'emailVerify'}));
                alert(t('auth.registrationAlert'));
            }
        } catch (error: any) {
            const errorCode = error.code;
            const message = isRu ? errorsTypes[errorCode] : errorsTypesEn[errorCode];
            serError(message);
            throw error;
        }
    };
    return (
        <AuthLayout>
            <div className={styles.register}>
                {isError ? <p style={{color: 'red'}}>{isError}</p> : null}
                <Form handleSubmit={submit}/>
            </div>
        </AuthLayout>
    );
};

