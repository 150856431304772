import React from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import styles from '../style.module.scss';
import {SwitcherLang} from '../../../components/SwitcherLang';
import {colors} from '../../../utils/constants';
import {useDayOfWeek} from '../../../hooks';

export const Header = () => {
    const location = useLocation();
    const isHome = location.pathname === '/';
    const dayOfTheWeek = location.pathname === '/content/day-of-the-week';
    const {t} = useTranslation();
    const {colorDark} = useDayOfWeek();

    return (
        <header
            className={isHome ? styles.header : styles.header__single}
            style={{
                backgroundColor: dayOfTheWeek
                    ? colorDark :
                    isHome ? 'transparent' : colors.white
            }}
        >
            <div className={isHome ? classNames(styles.header__top, styles.header__topRight) : styles.header__top}>
                <SwitcherLang/>
            </div>
            <h1 className={isHome ? styles.header__title : classNames(styles.header__title, styles.header__titleSingle)}>
                <NavLink to={'/'}>ДХАРМА ДЖЙОТИШ СИДДХАНТА ЙОГИ АШРАМ</NavLink>
            </h1>
            <span className={styles.header__line}/>
            <h1 className={isHome ? styles.header__title : classNames(styles.header__title, styles.header__titleSingle)}>
                <NavLink to={'/'}>DHARMA JYOTISH SIDDHANTA YOGI ASHRAM</NavLink>
            </h1>
            {isHome ? <p className={styles.header__subtitle}>{t('home.subtitle')}</p> : null}
        </header>
    );
};
