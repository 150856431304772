import {addDoc, collection, doc, getDoc, getDocs, updateDoc} from 'firebase/firestore';
import {IConsultation} from '../../../models/Consultation';
import {firestore} from '../../../firebase';
import {defaultConsultation} from '../types';

export const getConsultation = async () => {
    try {
        const result: IConsultation[] = [];
        const docRef = collection(firestore, 'consultations');

        const docs = await getDocs(docRef);

        docs.forEach((doc) => {
            const data = doc.data() as IConsultation;
            const uid = doc.id as string;
            result.push({...data, uid});
        });

        return result.length > 0 ? result[0] as IConsultation : defaultConsultation;
    } catch (e) {}
};

export const createConsultation = async ({consultation}: { consultation: IConsultation }) => {
    try {
        let result: IConsultation | null = null;
        const itemRef = await addDoc(collection(firestore, 'consultations'), consultation);
        const docSnap = await getDoc(itemRef);
        if (docSnap.exists()) {
            result = {...docSnap.data(), uid: itemRef.id as string} as IConsultation;
        }
        return result as IConsultation;
    } catch (e) {
    }
};

export const updateConsultation = async ({consultation}: { consultation: IConsultation }) => {
    try {
        const washingtonRef = await doc(firestore, `consultations/${consultation.uid}`);
        await updateDoc(washingtonRef, {
            ...consultation
        });
        const res =  await getConsultation();
        return res;
    } catch (e) {  }
};
