import React from 'react';
import {useTranslation} from 'react-i18next';
import {Main} from '../../layouts';
import {useDayOfWeek} from '../../hooks';
import styles from './DayOfTheWeek.module.scss';

export const DayOfTheWeek = () => {
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru';
    const {image, imageEn} = useDayOfWeek();
    return (
        <Main>
            <div className={styles.dayOfTheWeek} style={{
                // backgroundImage: `url(${isRu ? image : imageEn})`
            }}>
                <div className={styles.dayOfTheWeek}>
                    <img src={isRu ? image : imageEn} className={styles.dayOfTheWeek__image} alt={'background'}/>
                </div>
            </div>
        </Main>
    );
};

