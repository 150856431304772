import {useTranslation} from 'react-i18next';
import {locale} from '../models/Course';

export const useTranslateServerData = () => {
    const {i18n} = useTranslation();

    const translate = (obj:locale) => {
        return obj[i18n.language as keyof typeof obj];
    };

    return {translate};
};
