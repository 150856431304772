import {collection, doc, getDoc, getDocs, query} from 'firebase/firestore';
import { firestore} from '../../../firebase';
import {defaultCourse, ICourse} from '../../../models/Course';
import { IStage} from '../../../models/Stage';
import {IUser} from '../../../models/User';

export const getMainCourse = async (user:IUser) => {
    const course: ICourse = defaultCourse;
    const stages: IStage[] = [];
    // const q = query(collection(firestore, 'courses'), where('isMain', '==', true));
    // const querySnapshot = await getDocs(q);
    // querySnapshot.forEach((doc) => {
    //     course = doc.data() as ICourse;
    // });
    for (let i = 0; i < user.mainStages.length; i++) {
        const washingtonRef = doc(firestore, `stages/${user.mainStages[i]}`);
        const docSnap = await getDoc(washingtonRef);
        if (docSnap.exists()) {
            stages.push({...docSnap.data(), uid: docSnap.id} as IStage);
        }
    }
    const sortStages = stages.sort((a,b) => a.serialNumber - b.serialNumber);

    return {course, stages : sortStages};
};

export const getFreeStages = async () => {
    try {
        let freeStages: any = [];
        const q = query(collection(firestore, 'freeStages'));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            freeStages = doc.data() as any[];
        });

        const sortBySerialNumber= (a:any,b: any) => {
            return  a.serialNumber - b.serialNumber;
        };

        return {titleStages : freeStages.titleStages.sort(sortBySerialNumber)};
    } catch (e) {

    }
};
