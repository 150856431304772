import {collection, doc, getDoc, getDocs, query, where} from 'firebase/firestore';
import {firestore} from '../../../firebase';
import {ICourse, TemporaryNames, typesCourse} from '../../../models/Course';
import {ILessonsIn} from '../../../models/lesson';
import {sortByTimestamp} from '../../../helpers/sortByTimestamp';
import {IStage} from '../../../models/Stage';
import {sortBySerialNumber} from '../../../helpers/sortBySerialNumber';


export const getTemporaryCourse = async () => {
    let temporaryCourse: ICourse | TemporaryNames | null = null;
    const temporaryCoursesNotBannersArr: ICourse[] | TemporaryNames[] = [];
    const freeStages: string[] = [];
    const stages: IStage[] = [];
    const freeCourses: ICourse[] = [];
    const freeLessons: ILessonsIn[] = [];

    const temporaryCourseRef = collection(firestore, 'courses');
    const temporaryQuery = query(temporaryCourseRef, where('type', '==', typesCourse.temporary));
    const freeQuery = query(temporaryCourseRef, where('type', '==', typesCourse.free));

    const [querySnapshot, querySnapshotFree] = await Promise.all([
        getDocs(temporaryQuery),
        getDocs(freeQuery),
    ]);

    querySnapshot.forEach((doc) => {
        const course = {...doc.data(), uid: doc.id} as TemporaryNames;
        if (course.bannerCourse) {
            temporaryCourse = course;
        }
        
        //@ts-ignore
        temporaryCoursesNotBannersArr.push(course);
    });

    querySnapshotFree.forEach((doc) => {
        const course = doc.data() as ICourse;
        freeCourses.push({ ...course, uid: doc.id });
        freeStages.push(...course.stages);
    });

    for (const freeStage of freeStages) {
        const freeLessonsRef = collection(firestore, 'lessons');
        const washingtonRef = doc(firestore, `stages/${freeStage}`);
        const docSnap = await getDoc(washingtonRef);
        if (docSnap.exists()) {
            stages.push({ ...docSnap.data(), uid: docSnap.id } as IStage);
        }

        const lessonsQ = query(freeLessonsRef, where('type', '==', typesCourse.free));
        const querySnapshotLessons = await getDocs(lessonsQ);
        querySnapshotLessons.forEach((doc) => {
            const lesson = { ...doc.data(), uid: doc.id } as ILessonsIn;
            freeLessons.push(lesson);
        });
    }

    const sorting = freeLessons.sort(sortByTimestamp);
    const temporaryCoursesNotBanners = temporaryCoursesNotBannersArr.sort((a, b) => {
        const A = a.create_as as number;
        const B = b.create_as as number;
        return B - A;
    });

    return {
        temporaryCourse, 
        freeLessons: sorting, 
        freeCourses, stages : stages.sort(sortBySerialNumber), 
        temporaryCoursesNotBanners
    };
};