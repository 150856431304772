import {createSlice} from '@reduxjs/toolkit';
import {defaultShastrCategory, IShastrCategoryState} from './types';

const initialState: IShastrCategoryState = {
    currentShastrCategory: defaultShastrCategory,
    shastrCategory: []
};

const ShastrCategorySlice = createSlice({
    name: 'post',
    initialState,
    reducers: {
        setShastrCategorys: (state, {payload}) => {
            state.shastrCategory = payload;
        },
        setShastrCategory: (state, {payload}) => {
            state.currentShastrCategory = payload;
        },
        clearCurrentShastrCategory: (state) => {
            state.currentShastrCategory = defaultShastrCategory;
        }
    }
});

export const {
    setShastrCategory,
    setShastrCategorys,
    clearCurrentShastrCategory
} = ShastrCategorySlice.actions;

export default ShastrCategorySlice.reducer;
