import {locale} from '../models/Course';

export const enum actionTypes {
    add = 'add',
    remove = 'remove'
}

export interface IFreeTitle {
    name: locale,
    uid: string
}
