import React, {useEffect, useState} from 'react';
import {SingleValue} from 'react-select';
import {getDownloadURL, ref} from 'firebase/storage';
import styles from '../Course.module.scss';
import {CheckboxField, CustomButton, CustomSelect, Input} from '../../../fields';
import {RichText} from '../../../components/RichText';
import {value} from '../../../fields/Input';
import {IOption} from '../../../fields/CustomSelect';
import {ICourse, typesCourse, typeValuesCourse} from '../../../models/Course';
import {useAppDispatch, useAppSelector} from '../../../hooks';
import {createCourse, updateCourse} from '../api';
import {setCourses} from '../Course.slice';
import {ButtonVariables} from '../../../fields/CustomButton';
import {Reader} from '../../../components/Reader';
import {storage} from '../../../firebase';

const options = [
    {value: typesCourse.free, label: typeValuesCourse[typesCourse.free]},
    {value: typesCourse.constant, label: typeValuesCourse[typesCourse.constant]},
    {value: typesCourse.temporary, label: typeValuesCourse[typesCourse.temporary]}
];

interface IFormProps {
    handleBack: () => void;
    setLoader: () => void;
}

export const Form = ({handleBack, setLoader}: IFormProps) => {
    const dispatch = useAppDispatch();
    const {current} = useAppSelector(state => state.admin.course);
    const [nameRU, setNameRU] = useState<value>(current.name.ru);
    const [nameEN, setNameEN] = useState<value>(current.name.en);

    const [price, setPrice] = useState<number>(current.price);

    const [descriptionRU, setDescriptionRU] = useState<string>(current.description.ru);
    const [descriptionEN, setDescriptionEN] = useState<string>(current.description.en);

    const [isChecked, setChecked] = useState<boolean>(current.isMain ?? false);
    const [isPurchased, setPurchased] = useState<boolean>(current.isPurchase ?? false);

    const [courseType, setCourseType] = useState<SingleValue<IOption> | null>(null);
    const [base64, setBase64] = useState<string>('');
    const [base64EN, setBase64EN] = useState<string>('');
    const [duration, setDuration] = useState<number>(current.duration);

    const [bannerReader, showBannerReader] = useState<boolean>(current.bannerCourse);

    useEffect(() => {
        if (current.uid) {
            const type = options.find(i => i.value === current.type) as SingleValue<IOption>;
            setCourseType(type);
            getUrl(current.type, current.uid);
        }
    }, [current]);

    const getUrl = async (type:string, uid:string) => {
      if(type === typesCourse.temporary) {
          const urlImageRu = await getDownloadURL(ref(storage, uid)).then(url => url.toString()).catch(e => {
              return '';
          });
          setBase64(urlImageRu);
          const urlImageEn = await getDownloadURL(ref(storage, `en-${uid}`)).then(url => url.toString()).catch(e => {
              return '';
          });
          setBase64EN(urlImageEn);
      }
    };

    const handleSelectType = (option: SingleValue<IOption>) => {
        setCourseType(option);
        if(option?.value !== typesCourse.temporary) {
            setBase64('');
        }
    };


    const submit = async () => {
        setLoader();
        try {
            const course: ICourse = {
                name: {
                    ru: nameRU,
                    en: nameEN
                },
                description: {
                    ru: descriptionRU,
                    en: descriptionEN
                },
                isMain: isChecked,
                isPurchase: isPurchased,
                stages: current.stages,
                // @ts-ignore
                type: courseType?.value ?? typesCourse.free,
                bannerCourse: base64 !== '',
                duration,
                price,
                create_as: current.create_as
            };

            const courses = current?.uid !== null
                ? await updateCourse(course, current.uid as string, base64, base64EN)
                : await createCourse(course, base64, base64EN);

            dispatch(setCourses(courses as ICourse[]));
            handleBack();
        } catch (e) {
        }
    };

    return (
        <>
            <div className={styles.course__row}>
                <div className={styles.course__colM}>
                    <div className={styles.course__box}>
                        <Input
                            label={'Название ru'}
                            defaultValue={nameRU}
                            handleChangeValue={setNameRU}
                            type={'text'}/>
                    </div>
                    <div className={styles.course__box}>
                        <Input
                            label={'Название en'}
                            defaultValue={nameEN}
                            handleChangeValue={setNameEN}
                            type={'text'}/>
                    </div>
                    {courseType?.value === typesCourse.temporary
                        ? <div className={styles.course__box}>
                        <Input
                            label={'Длительность в полных днях (на считая дня выдачи доступа)'}
                            defaultValue={duration ?? 0}
                            handleChangeValue={(value) => setDuration(Number(value))}
                            type={'number'}/>
                    </div> : null}
                    {courseType?.value === typesCourse.temporary
                        ? (<>
                            <CheckboxField isChecked={bannerReader} handleChecked={() => showBannerReader(!bannerReader)}
                                           label={'загрузить баннер'}/>
                            {
                                bannerReader ? (
                                    <>
                                        <div className={styles.course__box}>
                                            <Reader handleReader={setBase64} buttonText={'Загрузите фото RU'} defaultBase64={base64}/>
                                        </div>
                                        <div className={styles.course__box}>
                                            <Reader handleReader={setBase64EN} buttonText={'Загрузите фото EN'} defaultBase64={base64EN}/>
                                        </div>
                                    </>
                                ) : null
                            }

                        </> )
                        : null}
                </div>

                <div className={styles.course__colM}>
                    <div className={styles.course__box}>
                        <CheckboxField isChecked={isChecked} handleChecked={() => setChecked(!isChecked)}
                                       label={'главный курс'}/>
                    </div>
                    <div className={styles.course__box}>
                        <CustomSelect options={options} handler={handleSelectType} className={''}
                                      defaultValue={courseType?.value as string}/>
                    </div>
                </div>
            </div>

            <div className={styles.course__box}>
                <label className={styles.course__label}>Описание ru</label>
                <RichText handleChange={setDescriptionRU} data={descriptionRU.toString()} height={150}/>
            </div>
            <div className={styles.course__box}>
                <label className={styles.course__label}>Описание en</label>
                <RichText handleChange={setDescriptionEN} data={descriptionEN.toString()} height={150}/>
            </div>
            <div className={styles.course__box}>
                <Input
                    label={'Цена, RUB'}
                    defaultValue={price}
                    handleChangeValue={(value) => setPrice(Number(value))}
                    type={'number'}/>
            </div>
            <div className={styles.course__box}>
                <CheckboxField isChecked={isPurchased} handleChecked={() => setPurchased(!isPurchased)} label={'доступен для оплаты'}/>
            </div>
            <CustomButton
                variant={ButtonVariables.contained}
                handleClick={submit}>
                {current.uid !== null
                    ? 'Сохранить изменения'
                    : 'Сохранить'}
            </CustomButton>
        </>
    );
};
