export const quarter = (num: number) => {
    const width = num / 4;
    const height = (num / 4) / 16 * 9;

    return {width, height};
};

export const colors = {
    black: '#000000',
    white: '#ffffff',
    grey: '#606060',
    mainText: '#ffbd28',
    dark: '#040b33',
    darkOpacity: 'rgba(4,11,51,0.83)',
    light: '#0f174e',
    lightS: '#a7bbe7',
};

export const dayOfTheWeekColors = [
    '#7f2107',
    '#ffd966',
    '#5c112f',
    '#6fa8dc',
    '#93c47d',
    '#76a5af',
    '#0e1219',

];

export const dayOfTheWeekColorsLight = [
    'rgba(127,33,7,1)',
    'rgba(255,217,102,0.81)',
    'rgba(92,17,47,0.78)',
    'rgba(111,168,220,0.84)',
    'rgba(147,196,125,0.8)',
    'rgba(118,165,175,0.81)',
    'rgba(14,18,25,0.79)',

];
export const dayOfTheWeekColorsDark = [
    '#580e02',
    '#7f640f',
    '#1c0303',
    '#133f67',
    '#317115',
    '#1d6373',
    '#03050c',


];
