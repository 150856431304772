import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button} from '@mui/material';
import {PanelLayout} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {ILessonsIn} from '../../models/lesson';
import {AppLoader} from '../../components/AppLoader';
import {ConfirmationModal} from '../../components/ConfirmationModal';
import styles from './Lesson.module.scss';
import {clearCurrentLesson, setCurrentLesson, setLessons} from './Lesson.slice';
import {deleteLesson, getLessons} from './api';
import {Form} from './components';
import {List} from './components/List';


export const Lesson = ( ) => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const {all} = useAppSelector(state => state.admin.lessons);
    const {all:AllStages} = useAppSelector(state => state.admin.stage);
    const {all:AllCourses} = useAppSelector(state => state.admin.course);

    const [showForm, setShowForm] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [currentItem, setCurrentItem] = useState<{ uid: string, belonging: string }>({uid: '', belonging: ''});


    async  function sortStages(lessons: ILessonsIn[]) {
        let LESSONS:ILessonsIn[] = [];

        let uidCourses: string[] = [];
        AllCourses.forEach(i => {
            const uids = AllStages
                .filter(item => item.belonging === i.uid)
                .sort((a,b) => a.serialNumber - b.serialNumber)
                .map(s => s.uid) as string[];
            uidCourses = [...uidCourses, ...uids];
        });

        for await (const uid of uidCourses) {
            const arr = lessons.filter(item => item.belonging === uid);
            LESSONS = [...LESSONS, ...arr.sort((a, b) => a.serialNumber - b.serialNumber)];
        }

        return LESSONS;
    }
    useEffect(() => {
        getLessons().then(lessons => {
            sortStages(lessons).then(lessons => {
                dispatch(setLessons(lessons));
            });
        });
    }, [dispatch, showForm]);

    const handleBack = () => {
        setLoader(true);
        setShowForm(false);
        dispatch(clearCurrentLesson());
        setLoader(false);
    };

    const handleCreated = useCallback(async () => {
        setShowForm(false);
        dispatch(clearCurrentLesson());
        setLoader(false);
    }, [dispatch]);

    const handleUpdate = useCallback(async (uid: string) => {
        setLoader(true);
        const current = all.find(i => i.uid === uid) as ILessonsIn;
        dispatch(setCurrentLesson(current));
        setShowForm(true);
        setLoader(false);
    }, [dispatch, all]);

    const handleDelete = useCallback(async (uid: string, belonging: string) => {
        setLoader(true);
        const lessons = await deleteLesson(uid, belonging);
        dispatch(setLessons(lessons));
        setLoader(false);
    }, [dispatch]);

    return (
        <PanelLayout>
            <>
                <AppLoader loading={loader} />
                <div className={styles.lesson}>
                    <div className={styles.lesson__header}>
                        <h1 className={styles.lesson__title}>{t('lesson.title')}</h1>
                        {!showForm && <Button
                            variant={'contained'}
                            className={styles.course__createBtn}
                            onClick={() => setShowForm(true)}
                        >Создать урок</Button>}
                    </div>
                    {showForm
                        ? (<div>
                            <button onClick={handleBack}>Назад</button>
                            <Form handleBack={handleCreated} setLoader={() => setLoader(true)}/>
                        </div>)
                        : (
                            <List
                                items={all}
                                handleUpdate={handleUpdate}
                                handleDelete={(uid, belonging) => {
                                    setOpenConfirmation(true);
                                    setCurrentItem({
                                        uid, belonging
                                    });
                                }}/>
                        )}
                </div>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    handleDelete={() => handleDelete(currentItem.uid, currentItem.belonging)}
                    text={''} />
            </>
        </PanelLayout>
    );
};

