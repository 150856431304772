import {createSlice} from '@reduxjs/toolkit';
import {ILessonsIn, lessonIn} from '../../models/lesson';
import {ILessonState} from './types';

const initialState:ILessonState = {
    all: [],
    current: lessonIn
};

const LessonSlice = createSlice({
    name: 'lesson',
    initialState,
    reducers:{
        setLessons: (state, {payload}:{payload: ILessonsIn[]}) => {
            state.all = payload;
        },
        setCurrentLesson: (state, {payload}:{payload:ILessonsIn}) => {
            state.current = payload;
        },
        clearCurrentLesson: (state) => {
            state.current = lessonIn;
        }
    }
});

export const {setLessons, setCurrentLesson, clearCurrentLesson} = LessonSlice.actions;

export default LessonSlice.reducer;
