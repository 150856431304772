import React, {useEffect, useState} from 'react';
import ReactFileReader from 'react-file-reader';
import {Button} from '@mui/material';
import {ITemplateNaneProps} from './types';
import styles from './Reader.module.scss';


export const Reader = ({handleReader, defaultBase64, buttonText}:ITemplateNaneProps) => {
    const [base64, setBase64] = useState<string | undefined>(defaultBase64);
    const handleFiles = (file: any) => {
        setBase64(file.base64);
        handleReader(file.base64);
    };

    useEffect(() => {
        setBase64(defaultBase64);
    }, [defaultBase64]);

    return (
        <div className={styles.reader}>
            <div className={styles.reader__box}>
                <ReactFileReader base64={true} handleFiles={handleFiles}>
                    <Button variant={'contained'}>Загрузить</Button>
                </ReactFileReader>
            </div>
            <div className={styles.reader__box}>
                {base64
                    ? <div className={styles.reader__preview} >
                        <img src={defaultBase64 ?? base64} alt={'cover'} />
                    </div>
                    : <p className={styles.reader__text}>{buttonText}</p>}
            </div>
        </div>
    );
};

