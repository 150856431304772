import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';
import {Main} from '../../layouts';
import {useAppDispatch, useAppSelector} from '../../hooks';
import {AppLoaderSmall} from '../../components/AppLoader';
import {setPostsCategory} from '../../admin/PostCategory/PostCategory.slice';
import {getPostsCategory} from '../../admin/PostCategory/api';
import {loadingPost, setCurrentPost} from './PostPage.slice';
import styles from './PostPage.module.scss';

export const PublicPostCategory = () => {
    const {i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {category} = useAppSelector(state => state.admin.postsCategory);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getPostsCategory().then(data => {
            dispatch(setPostsCategory(data));
            setLoading(false);
        });
    }, []);

    const handleJump = (uid: string) => {
        const post = category.find(i => i.uid === uid);
        dispatch(loadingPost(true));
        dispatch(setCurrentPost(post));
        navigate(`/posts/${uid}`);
    };

    return (
        <Main>
            <div className={styles.postPage}>
                <ul className={styles.list}>
                    {category.length > 0 ? category.map(post => (
                        <li key={post.uid} className={styles.list__item}>
                            <div className={styles.card}>
                                <div className={styles.card__img}>
                                    { post.urlImage !== '' ? <img src={post.urlImage} alt={post.name.en as string}/> : null}
                                </div>
                                <div className={classNames(styles.card__body, styles.card__bodyNoBg)}>
                                    <h3 className={classNames(styles.card__title, styles.card__titleCat)} onClick={() => handleJump(post.uid as string)}>{
                                        i18n.language === 'ru' ? post.name.ru : post.name.en
                                    }</h3>
                                    {/*<span onClick={() => handleJump(post.uid as string)} className={styles.card__link}>*/}
                                    {/*    {t('postsCategory.more')}*/}
                                    {/*</span>*/}
                                </div>
                            </div>
                        </li>
                    )) : <AppLoaderSmall loading={loading}/>}
                </ul>
            </div>

        </Main>
    );
};
