import React from 'react';
import {Button, colors} from '@mui/material';
import styles from '../Stage.module.scss';
import {useAppSelector, useTranslateServerData} from '../../../hooks';
import {IStage} from '../../../models/Stage';

interface IListItemProps extends IStage{
    handleUpdate: (uid: string ) => void;
    handleDelete:(uid: string, belonging:string) => void;
}

export const ListItem = ({name, uid, belonging, serialNumber, handleUpdate, handleDelete}:IListItemProps) => {
    const {translate} = useTranslateServerData();
    const {all} = useAppSelector(state => state.admin.course);

    const belongingCourse = all.filter(i => i.uid === belonging).map(i => i.name.ru).pop();

    return (
        <li className={styles.list__item}>
            <p className={styles.list__text}>{translate(name)}</p>
            <p className={styles.list__text}>{serialNumber}</p>
            <p className={styles.list__text}>{belongingCourse}</p>
            <div className={styles.list__controls}>
                <Button
                    variant="outlined"
                    onClick={() => uid ? handleUpdate(uid) : null}>редактировать</Button>
                <Button
                    sx={{marginLeft: '8px', color: colors.red[700]}}
                    variant={'text'}
                    onClick={() => uid ? handleDelete(uid, belonging) : null}>удалить</Button>
            </div>
        </li>
    );
};
