import React from 'react';
import styles from '../Lesson.module.scss';
import {ILessonsIn} from '../../../models/lesson';
import {ListItem} from './ListItem';

interface IListName {
    items: ILessonsIn[];
    handleUpdate: (uid:string) => void;
    handleDelete: (uid:string, belonging:string) => void;
}

export const List = ({items, handleUpdate, handleDelete}: IListName) => {

    return (
        <ul className={styles.list}>
            {items.map(i =>
                <ListItem
                    key={i.uid}
                    uid={i.uid}
                    name={i.name}
                    serialNumber={i.serialNumber}
                    belonging={i.belonging}
                    description={i.description}
                    source={i.source}
                    homework={i.homework}
                    handleDelete={handleDelete}
                    create_as={i.create_as}
                    type={i.type}
                    bannerCourse={i.bannerCourse}
                    handleUpdate={handleUpdate}
                />)}
        </ul>
    );
};
