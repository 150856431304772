import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {NavLink, useNavigate} from 'react-router-dom';
import {Main} from '../../layouts';
import {useAppDispatch, useAppSelector, useAuth} from '../../hooks';
import {AppLoaderSmall} from '../../components/AppLoader';
import {getUser} from '../../admin/Panel/api';
import {setUser} from '../../store/app.slice';
import {Title} from '../../components/Title';
import {Tooltip} from '../../components/tooltip';
import styles from './CoursePage.module.scss';
import {getFreeStages, getMainCourse} from './api';
import {setCourseMain, setNameStates} from './CoursePage.slice';

export const CoursePage = () => {
    const {i18n} = useTranslation();
    const isRu = i18n.language === 'ru';
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {isAuth} = useAuth();
    const {uid} = useAuth();
    const {course,  titleStages} = useAppSelector(state => state.front.courseMain);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        getFreeStages().then(titleStages => {
            dispatch(setNameStates(titleStages));
            setLoading(false);
        });

        getUser(uid).then(user => {

            if (user) {
                dispatch(setUser(user));

                getMainCourse(user)
                    .then(({course, stages}) => {

                        dispatch(setCourseMain({course, stages}));
                    });
            }
            setLoading(false);
        });

    }, []);

    const handleLogin = (stageId: string) => {
        if(!isAuth) {
            navigate('/login');
        }
    };

    return (
        <Main>
            <div className={styles.coursePage}>
                <Title title={isRu ? course.name.ru : course.name.en}/>

                {loading ? <div style={{marginTop: '30px'}}><AppLoaderSmall loading={loading}/></div> :
                    (
                        <div className={styles.stagesList}>
                            {titleStages.map((i) =>
                                isAuth
                                    ? (
                                        <NavLink
                                            key={i.uid}
                                            to={`stages/${i.uid}`}
                                            className={styles.stagesList__item}>
                                            {i18n.language === 'ru' ? i.name.ru : i.name.en}
                                        </NavLink>
                                    ) : (
                                        <span className={styles.stagesList__item}>
                                            <Tooltip
                                                key={i.uid}
                                                uid={i.uid as string}
                                                textEn={i.name.en}
                                                textRu={i.name.ru}
                                                onClick={() => handleLogin(i.uid)}
                                            />
                                        </span>
                                    )
                            )}
                        </div>
                    )
                }
            </div>
        </Main>
    );
};

