import {createSlice} from '@reduxjs/toolkit';
import {defaultCourse, ICourse} from '../../models/Course';
import {ICourseState} from './types';

const initialState:ICourseState = {
    all: [],
    current : defaultCourse
};

const CourseSlice = createSlice({
    name: 'course',
    initialState,
    reducers:{
        setCourses: (state, {payload}:{payload: ICourse[]}) => {
            state.all = payload;
        },
        setCurrentCourse: (state, {payload}:{payload:ICourse}) => {
            state.current = payload;
        },
        clearCurrentCourse: (state) => {
            state.current = defaultCourse;
        }
    }
});

export const {setCourses, setCurrentCourse, clearCurrentCourse} = CourseSlice.actions;

export default CourseSlice.reducer;
